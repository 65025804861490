import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getItem = createAsyncThunk(
  "getItem",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get('dashboardToken')}`,
        },
      };
      const { data } = await axios.get("api/controlPane/Products", config);
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    copyData: [],
    loading: true,
    isSuccess: false,
    message: "",
  },
  reducers: {
    filteredItem: (state, action) => {
      state.copyData = state.data.filter(
        (item) =>
          item.subCategory.english.toLowerCase().includes(action.payload) ||
          item.subCategory.arabic.toLowerCase().includes(action.payload) ||
          item.subCategory.kurdish.toLowerCase().includes(action.payload) ||
          item.name.english.toLowerCase().includes(action.payload) ||
          item.name.arabic.toLowerCase().includes(action.payload) ||
          item.name.kurdish.toLowerCase().includes(action.payload) ||
          item.brand.english.toLowerCase().includes(action.payload) ||
          item.brand.arabic.toLowerCase().includes(action.payload) ||
          item.brand.kurdish.toLowerCase().includes(action.payload) ||
          item.tags.toLowerCase().includes(action.payload) ||
          item.price === action.payload
        // item.qty.toLowerCase().includes(action.payload)
      );
    },
    selectFilter: (state, action) => {
      state.copyData = state.data.filter((item) =>
        action.payload.includes(item.subCategoryId)
      );
    },
    selectStateInStock: (state) => {
      state.copyData = state.data.filter((item) => item.qty > 1);
    },
    selectStateOutStock: (state) => {
      state.copyData = state.data.filter((item) => item.qty < 1);
    },
    removeFilter: (state) => {
      state.copyData = state.data;
    },
  },
  extraReducers: {
    [getItem.pending]: (state, action) => {
      state.loading = true;
    },
    [getItem.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getItem.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});
export const {
  filteredItem,
  selectFilter,
  removeFilter,
  selectStateInStock,
  selectStateOutStock,
} = loremSlice.actions;
export default loremSlice;
