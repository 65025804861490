import { createSlice, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import Cookies from "js-cookie";
const initialState = {
  value: "",
};

export const SidebarSlice = createSlice({
  name: "Sidebar",
  initialState,
  reducers: {
    Connect: (state) => {
      const connection = new HubConnectionBuilder()
        .withUrl(`https://varypharmacy.com/api/chatHub`, {
          accessTokenFactory: () => Cookies.get("webAuth"),
          UseDefaultCredentials: true,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      connection.logging = true;
      connection.start();
      state.value = connection;
    },
    // getSidebar: (state) => {
    //   return state.value;
    // },
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Action creators are generated for each case reducer function
export const { Connect } = SidebarSlice.actions;

export default SidebarSlice;
