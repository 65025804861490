import React, { useState, useEffect, useRef, Fragment } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/reports.svg";
import addIcon from "../../icons/add.svg";
import InfoCard from "../../components/Cards/Carts";
import Spiner from "../../components/Loader/Spiner";
import Cookies from "js-cookie";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

// import Add from "./Add";
// import Edit from "./Edit";
// import View from "./View";
import axios from "axios";
// Icons

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  getMainReport,
  filteredItem,
  removeFilter,
  filterDate,
} from "../../../features/Global/MostSellingByProductsList";
// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { CalculatorIcon, ChartPieIcon } from "@heroicons/react/24/outline";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Index = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.MostSellingByProductList?.copyData);
  // const loader = useSelector((state) => state.MostSellingByProductList?.loading);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  // hook to fetch items
  useEffect(() => {
    // dispatch(getMainReport(from, to));
  }, [dispatch, from, to]);
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const search = useRef();
  // ------------------------------------ Filters ------------------------------------ //
  const handleSearch = (e) => {
    search.current.value = e;
    const now = new Date();

    // dispatch(filteredItem(search.current.value));
    filterItem(search.current.value);
  };
  const handleRemoveFilters = () => {
    dispatch(removeFilter());
    search.current.value = "";
    setFrom(null);
    setTo(null);
    setCopyData(data);
  };
  const [data, setData] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const getMainReport = async (from, to) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
        },
      };

      const requestBody = {};
      if (from) requestBody.from = from;
      if (to) requestBody.to = to;

      console.log("Request body:", requestBody);

      const { data } = await axios.post(
        "api/Report/GetMostSoldItemsByCategory",
        requestBody,
        config
      );
      setData(data);
      setCopyData(data);
      setIsSuccess(true);
      setLoading(false);
    } catch (error) {
      console.error("Failed to load data:", error);
      setMessage("Failed to load data");
      setLoading(false);
      setIsSuccess(false);
    }
  };

  const filterData = (from, to) => {
    if (!from || !to) return;
    const filtered = data.filter(
      (item) =>
        new Date(item.orderDate) > new Date(from) &&
        new Date(item.orderDate) < new Date(to)
    );
    setCopyData(filtered);
  };

  const filterItem = (query) => {
    if (!query) {
      setCopyData(data);
      return;
    }

    const filtered = data.filter((item) => {
      return (
        (item.categoryName &&
          item.categoryName.toLowerCase().includes(query.toLowerCase())) ||
        item.totalPrice == query || // Using '==' to allow comparison between number and string
        item.quantitySold == query || // Using '==' to allow comparison between number and string
        item.remainInStore == query
      );
    });

    setCopyData(filtered);
  };

  useEffect(() => {
    getMainReport(from, to);
  }, [from, to]);

  useEffect(() => {
    if (from && to) {
      filterData(from, to);
    }
  }, [from, to]);
  const columns = [
    { field: "ctegoryId", headerName: "Id", width: 70 },
    {
      field: "categoryName",
      headerName: "Category Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "quantitySold",
      headerName: "Sold Quantity",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <p className="float-right text-right">
          {Intl.NumberFormat().format(params.value)}
        </p>
      ),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <p className="float-right text-right">
          {Intl.NumberFormat().format(params.value)} IQD
        </p>
      ),
    },
  ];
  // const handleDateFilter =(from, to) => {
  //   data?.filter((item) => {
  //     item?.orderDateString > from && item?.orderDateString < to;
  //   });
  // };

  const now = new Date();

  const handleSelectedFilter = (f, t) => {
    setFrom(f);
    setTo(t);
  };
  return (
    <main className="relative h-screen overflow-y-auto bg-gray-100 dark:bg-gray-800 ltr">
      <div className="flex items-start justify-between h-screen overflow-y-auto">
        <Sidebar />
        <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
          <header className="z-10 flex items-center justify-between w-full h-16">
            <Header />
          </header>
          <div className="h-screen px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
            <div className="">
              <BreadCrumb
                page={"Most selling Categories"}
                icon={<ChartPieIcon className="inline w-6 mx-2" />}
              />
            </div>
            {/* <InfoCard /> */}
            <div className="my-4  h-[44rem] rounded-md bg-gray-50 dark:bg-gray-800">
              <div className="my-10 ">
                <div className="flex w-full">
                  <div className="w-full p-6 bg-white border border-gray-200 shadow-lg rounded-xl dark:bg-gray-800 dark:border-gray-600">
                    <div className="">
                      <div className="relative flex items-center justify-between w-full mb-5 rounded-md">
                        <svg
                          className="absolute block w-5 h-5 text-gray-400 left-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="11" cy="11" r="8" className=""></circle>
                          <line
                            x1="21"
                            y1="21"
                            x2="16.65"
                            y2="16.65"
                            className=""
                          ></line>
                        </svg>
                        <input
                          type="name"
                          name="search"
                          ref={search}
                          onChange={(e) => handleSearch(e.target.value)}
                          className="w-full h-12 py-4 pl-12 pr-40 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-text focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:bg-gray-700 dark:text-gray-700 dark:border-gray-600"
                          placeholder="Search by Name, Brand, Category, Subcategory, Price, etc"
                        />
                      </div>

                      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        <div className="flex flex-col">
                          <label
                            htmlFor="date"
                            className="text-sm font-medium text-stone-600 dark:text-stone-300"
                          >
                            From
                          </label>
                          <input
                            type="date"
                            id="date"
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                            className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                          />
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="date"
                            className="text-sm font-medium text-stone-600 dark:text-stone-300"
                          >
                            To
                          </label>
                          <input
                            type="date"
                            id="date"
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                            className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                          />
                        </div>

                        {/* <div className="flex flex-col">
                          <label
                            htmlFor="status"
                            className="text-sm font-medium text-stone-600"
                          >
                            Status
                          </label>

                          <select
                            id="status"
                            className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          >
                            <option>Dispached Out</option>
                            <option>In Warehouse</option>
                            <option>Being Brought In</option>
                          </select>
                        </div> */}
                        <div className="right-0 grid float-right w-full grid-cols-2 mt-6 space-x-4 md:flex">
                          {/* <button className="px-8 py-2 font-medium text-white bg-blue-600 rounded-lg outline-none hover:opacity-80 focus:ring">
                          Search
                        </button> */}
                          <Menu
                            as="div"
                            className="relative inline-block h-full py-1 my-auto text-left align-middle "
                          >
                            <div>
                              <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus-ring-offset-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">
                                Filters
                                <ChevronDownIcon
                                  className="w-5 h-5 ml-2 -mr-1"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 dark:text-gray-300">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={() =>
                                          handleSelectedFilter(
                                            new Date(
                                              now.getFullYear(),
                                              now.getMonth(),
                                              now.getDate() - 7
                                            )
                                              .toISOString()
                                              .split("T")[0],
                                            new Date(
                                              now.getFullYear(),
                                              now.getMonth(),
                                              now.getDate()
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          )
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                            : "text-gray-700 dark:text-gray-300",
                                          "block px-4 py-2 text-sm w-full text-left"
                                        )}
                                      >
                                        Last week{" "}
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={() =>
                                          handleSelectedFilter(
                                            new Date(
                                              now.getFullYear(),
                                              now.getMonth() - 1,
                                              now.getDate()
                                            )
                                              .toISOString()
                                              .split("T")[0],
                                            new Date(
                                              now.getFullYear(),
                                              now.getMonth(),
                                              now.getDate()
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          )
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                            : "text-gray-700 dark:text-gray-300",
                                          "block px-4 py-2 text-sm w-full text-left"
                                        )}
                                      >
                                        Last month{" "}
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={() =>
                                          handleSelectedFilter(
                                            new Date(
                                              now.getFullYear(),
                                              now.getMonth() - 3,
                                              now.getDate()
                                            )
                                              .toISOString()
                                              .split("T")[0],
                                            new Date(
                                              now.getFullYear(),
                                              now.getMonth(),
                                              now.getDate()
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          )
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                            : "text-gray-700 dark:text-gray-300",
                                          "block px-4 py-2 text-sm w-full text-left"
                                        )}
                                      >
                                        Last three month
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        onClick={() =>
                                          handleSelectedFilter(
                                            new Date(
                                              now.getFullYear() - 1,
                                              now.getMonth(),
                                              now.getDate()
                                            )
                                              .toISOString()
                                              .split("T")[0],
                                            new Date(
                                              now.getFullYear(),
                                              now.getMonth(),
                                              now.getDate()
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          )
                                        }
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                            : "text-gray-700 dark:text-gray-300",
                                          "block px-4 py-2 text-sm w-full text-left"
                                        )}
                                      >
                                        Last three month
                                      </button>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                          <button
                            className="px-8 py-2 font-medium text-gray-600 bg-gray-200 rounded-lg outline-none active:scale-95 focus:ring hover:opacity-90 dark:bg-gray-700 dark:text-gray-300"
                            onClick={() => handleRemoveFilters()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {loading ? (
                <>
                  <Spiner />
                </>
              ) : (
                <div className="flex items-center w-auto h-full mx-2 mt-2 dark:bg-gray-800">
                  <DataGrid
                    rows={copyData}
                    columns={columns}
                    getRowId={(row) => row.ctegoryId}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    className="min-h-full dark:text-gray-200 "
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Index;
