import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  KeyIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { set, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { useDispatch } from "react-redux";
import { getUsers } from "../../../features/Global/Users";
export default function SlideOver({ Title, open, setOpen, chilren }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [checkpasswordType, setCheckPasswordType] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );
  useEffect(() => {
    // console.log(password);
    if (password === confirmPassword && password && confirmPassword !== "") {
      if (strongRegex.test(password)) {
        // this.setState({ backgroundColor: "#0F9D58" });
        setCheckPasswordType(1);
      } else if (mediumRegex.test(password)) {
        // this.setState({ backgroundColor: "#F4B400" });
        setCheckPasswordType(2);
      } else {
        // this.setState({ backgroundColor: "#DB4437" });
        setCheckPasswordType(3);
      }
    }
  }, [password, confirmPassword]);
  const onSubmit = (data) => {
    if (checkpasswordType <= 2) {
      setDisable(true);
      axios
        .post("api/DashboardUsersAuth/SignUp", data, config)
        .then((r) => {
          setDisable(false);
          if (r.status === 200) {
            setToast(true);
            dispatch(getUsers());
          }
        })
        .catch((e) => {
          setDisable(false);
          // console.log(e.response.status);
          if (
            e.response.status === 400 &&
            e.response.data.message === "email or phone number is already exist"
          ) {
            console.log(e.response.data);
            setMessage(e.response.data);
            setError(true);
          }
        });
    }
  };

  useEffect(() => {
    if (
      (password === "" || password === null) &&
      (confirmPassword === "" || checkPassword === null)
    ) {
      setCheckPassword(false);
    } else {
      if (password === confirmPassword) {
        setCheckPassword(true);
        // console.log(checkPassword);
      } else {
        setCheckPassword(false);
        // console.log(checkPassword);
      }
    }
  }, [password, confirmPassword]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XCircleIcon
                          className="w-6 h-6 text-red-500"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-teal-600 rounded-sm sm:px-6 dark:bg-teal-700">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PlusCircleIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    <hr className="mx-1 text-gray-300 dark:text-gray-700" />
                    <div className="relative flex-1 px-4 mt-6 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="mb-5">
                                <label className="mb-2 block text-base font-medium text-[#07074D] dark:text-gray-300">
                                  User Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter User Name"
                                  {...register("userName", {
                                    requirose: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.userName && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="mb-2 block text-base font-medium text-[#07074D] dark:text-gray-300">
                                  Email:
                                </label>
                                <input
                                  type="email"
                                  placeholder="Enter Email"
                                  {...register("emailAddress", {
                                    requirose: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.emailAddress && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="mb-2 block text-base font-medium text-[#07074D] dark:text-gray-300">
                                  Password:
                                  <span className="float-right mr-2">
                                    {password.length > 0 &&
                                      checkPassword.length > 0 && (
                                        <>
                                          <>
                                            {" "}
                                            {checkpasswordType === 1 ? (
                                              <KeyIcon className="inline w-6 text-blue-600" />
                                            ) : (
                                              ""
                                            )}{" "}
                                          </>
                                          <>
                                            {" "}
                                            {checkpasswordType === 2 ? (
                                              <KeyIcon className="inline w-6 text-teal-400" />
                                            ) : (
                                              ""
                                            )}{" "}
                                          </>
                                          <>
                                            {" "}
                                            {checkpasswordType === 3 ? (
                                              <KeyIcon className="inline w-6 text-rose-500" />
                                            ) : (
                                              ""
                                            )}{" "}
                                          </>
                                        </>
                                      )}
                                  </span>
                                </label>
                                <input
                                  type="password"
                                  placeholder="Enter Password"
                                  {...register("password", {
                                    requirose: true,
                                  })}
                                  onChange={(e) => setPassword(e.target.value)}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.password && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="mb-2 block text-base font-medium text-[#07074D] dark:text-gray-300">
                                  Confirm Password:
                                  <span className="float-right mr-2">
                                    {password.length > 0 &&
                                      checkPassword.length > 0 && (
                                        <>
                                          <>
                                            {" "}
                                            {checkpasswordType === 1 ? (
                                              <KeyIcon className="inline w-6 text-blue-600" />
                                            ) : (
                                              ""
                                            )}{" "}
                                          </>
                                          <>
                                            {" "}
                                            {checkpasswordType === 2 ? (
                                              <KeyIcon className="inline w-6 text-teal-400" />
                                            ) : (
                                              ""
                                            )}{" "}
                                          </>
                                          <>
                                            {" "}
                                            {checkpasswordType === 3 ? (
                                              <KeyIcon className="inline w-6 text-rose-500" />
                                            ) : (
                                              ""
                                            )}{" "}
                                          </>
                                        </>
                                      )}
                                  </span>
                                </label>
                                <input
                                  type="password"
                                  placeholder="ReEnter Password"
                                  {...register("conPassword", {
                                    requirose: true,
                                  })}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.conPassword && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>

                              <div className="my-3 ">
                                {checkPassword === true &&
                                checkPassword.length > 0 &&
                                password.length > 0 ? (
                                  <div className="my-2 text-sm text-teal-500">
                                    <CheckCircleIcon className="inline w-6 h-6" />{" "}
                                    Passwords Matched
                                  </div>
                                ) : (
                                  <>
                                    {checkPassword.length > 0 &&
                                      password.length > 0 && (
                                        <>
                                          <div className="text-sm text-rose-500">
                                            <XCircleIcon className="inline w-6 h-6" />{" "}
                                            Passwords Not Match
                                          </div>
                                        </>
                                      )}
                                  </>
                                )}{" "}
                                {checkpasswordType > 2 && (
                                  <div
                                    className={` text-rose-600 mt-1 text-sm`}
                                  >
                                    <>
                                      {" "}
                                      <InformationCircleIcon className="inline-flex w-5 mx-1 text-rose-600" />{" "}
                                      Password is too weak can not register
                                    </>
                                  </div>
                                )}
                              </div>

                              <div>
                                <button
                                  type="submit"
                                  disabled={
                                    checkpasswordType > 2 || disable
                                      ? true
                                      : false
                                  }
                                  className={`bg-teal-50 mx-1 px-6 py-2 rounded-md text-teal-500 hover:bg-teal-200 border border-transparent focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent dark:text-teal-100 dark:bg-teal-600 ${
                                    checkpasswordType > 2
                                      ? "disabled text-gray-500 hover:bg-gray-200"
                                      : ""
                                  }`}
                                >
                                  <PlusCircleIcon
                                    className={`inline w-6 h-6 mx-2 ${
                                      checkpasswordType > 2
                                        ? "disabled text-gray-500 hover:bg-gray-200"
                                        : ""
                                    }`}
                                  />
                                  Add
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-red-50 mx-1 px-4 py-2 rounded-md text-red-500 hover:bg-red-200 border border-transparent focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent dark:text-red-100 dark:bg-red-600"
                                  }
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                </button>
                              </div>
                            </form>
                            {toast && (
                              <Toast
                                Text={"User Created Successfully"}
                                Type={"teal"}
                                setOpen={setToast}
                              />
                            )}
                            {error && (
                              <Toast
                                Text={
                                  "email or phone number is allready taken!"
                                }
                                Type={"red"}
                                setOpen={setError}
                              />
                            )}
                          </div>
                        </div>

                        {/* <div className=F"h-full border-2 border-gray-200 border-dashed" aria-hidden="true" /> */}
                      </div>
                      {/* {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
