import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/tag.svg";
import addIcon from "../../icons/add.svg";
import InfoCard from "../../components/Cards/Carts";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { getItem } from "../../../features/Global/Items";
import Spiner from "../../components/Loader/Spiner";
import DiscountModal from "./DicountModal copy";
import BuyOneGet from "./ButOneGetModal";
import Update from "./UpdateBuyOneGetOne";
import { getDiscounts } from "../../../features/Global/Discount";
import { getOffersBuyOneGet } from "../../../features/Global/OffersBuyOneGet";
import { getOffersPercentage } from "../../../features/Global/OffersPercentage";
import { getDis } from "../../../features/Global/Dis";
import { useForm } from "react-hook-form";
import { Tab } from "@headlessui/react";
import AddDiscountForSubcategoriesModal from "./AddBulkDiscountOfferForSubCategories";
import RemoveDiscountForSubcategoriesModal from "./RemoveBulkDIscountOfferForSubcategories";
import AddDiscountForBrandsModal from "./AddBulkDiscountOfferForBrands";
import RemoveDiscountForBrandsModal from "./RemoveBulkDIscountOfferForBrands";
// Icons
import {
  EyeIcon,
  PlusCircleIcon,
  ReceiptPercentIcon,
  RectangleStackIcon,
  SwatchIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

// redux mapping
import { getStats } from "../../../features/Global/Stats";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
  const dispatch = useDispatch();
  const [isHeadlessOpen, setIsHeadlessOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isHeadlessOpenBuy, setIsHeadlessOpenBuy] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [addBulkDiscountForSubCateogries, setAddBulkDiscountForSubCateogries] =
    useState(false);
  const [
    removeBulkDiscountForSubCateogries,
    setRemoveBulkDiscountForSubCateogries,
  ] = useState(false);

  const [addBulkDiscountForBrand, setAddBulkDiscountForBrand] = useState(false);
  const [removeBulkDiscountForBrand, setRemoveBulkDiscountForBrand] =
    useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  // const data = useSelector((state) => state.Dis.data);
  // console.log("disss", data);
  const percentageRows = useSelector((state) => state.OffersPercentage?.data);
  // console.log(percentageRows);
  const percentageLoading = useSelector(
    (state) => state.OffersPercentage.loading
  );
  const buyOneGetRows = useSelector((state) => state.OffersBuyOneGet?.data);
  const BuyOneGetLoading = useSelector(
    (state) => state.OffersBuyOneGet.loading
  );
  useEffect(() => {
    dispatch(getDiscounts());
    dispatch(getOffersBuyOneGet());
    dispatch(getOffersPercentage());
  }, []);
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const percentageColumns = [
    { field: "productId", headerName: "ID", width: 70, hide: true },
    {
      field: "imageUrl",
      headerName: "Image",
      width: 70,
      renderCell: (cellvalues) => {
        return (
          <img className="w-10 h-10 rounded-full" src={cellvalues?.value} />
        );
      },
    },
    {
      field: "englishProductName",
      headerName: "Product name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "percentageOf",
      headerName: "Discount",
      minWidth: 150,
      flex: 1,
      renderCell: (cellvalues) => {
        return <p className="w-10 h-10 rounded-full">%{cellvalues.value} </p>;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleAddDiscount(cellvalues.id)}
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-700 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-700 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <PencilIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          Add
        </button>
      ),
    },

    // {
    //   field: "Edit",
    //   headerName: "Edit",
    //   width: 130,
    //   renderCell: () => (
    //     <button
    //       onClick={() => setEdit(true)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-700 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-600 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <PencilIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Edit
    //     </button>
    //   ),
    // },
    // {
    //   field: "View",
    //   headerName: "View",
    //   width: 130,
    //   renderCell: () => (
    //     <button
    //       onClick={() => setView(true)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full px-3 py-2 text-center text-white transition duration-200 ease-in bg-orange-500 rounded-lg shadow-md hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-rose-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <EyeIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       View
    //     </button>
    //   ),
    // },
  ];
  const buyOneGetColumns = [
    { field: "productId", headerName: "ID", width: 70, hide: true },
    {
      field: "imageUrl",
      headerName: "Image",
      width: 70,
      renderCell: (cellvalues) => {
        return (
          <img className="w-10 h-10 rounded-full" src={cellvalues?.value} />
        );
      },
    },
    {
      field: "mainItemHowMany",
      headerName: "Main quantity",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "englishProductName",
      headerName: "Product name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleAddBuy(cellvalues.id)}
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-teal-600 rounded-lg shadow-md hover:bg-teal-700 focus:ring-teal-700 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <PlusCircleIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          Add
        </button>
      ),
    },
    {
      field: "Update",
      headerName: "Update",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={() =>
            handleUpdateBuy(cellvalues.id, cellvalues.row.mainItemHowMany)
          }
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-700 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-700 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <PencilIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          Update
        </button>
      ),
    },
    // {
    //   field: "percentageOf",
    //   headerName: "Discount",
    //   minWidth: 150,
    //   flex: 1,
    // },
  ];
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const [id, setId] = useState("");
  const [quantity, setQuantity] = useState("");
  const handleAddDiscount = (id) => {
    setId(id);
    setIsHeadlessOpen(true);
  };
  const [buyId, setBuyId] = useState("");

  const handleAddBuy = (id) => {
    setBuyId(id);
    setIsHeadlessOpenBuy(true);
  };
  const handleUpdateBuy = (id, quantity) => {
    setBuyId(id);
    setQuantity(quantity);
    setUpdate(true);
  };

  const handleOpenAddBuldModalForSubcateogries = () => {
    setAddBulkDiscountForSubCateogries(true);
  };
  const handleClosenAddBuldModalForSubcateogries = () => {
    setRemoveBulkDiscountForSubCateogries(true);
  };
  const handleOpenAddBuldModalForBrands = () => {
    setAddBulkDiscountForBrand(true);
  };
  const handleOpenRemoveBulkModalForBrands = () => {
    setRemoveBulkDiscountForBrand(true);
  };

  return (
    <div className="ltr">
      <main className="relative h-screen bg-gray-100 dark:bg-gray-800">
        <div className="flex items-start justify-between h-screen overflow-y-auto">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              <Header />
            </header>
            <div className="px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
              <div className="">
                <BreadCrumb
                  page={"Offers"}
                  icon={<ReceiptPercentIcon className="inline w-6 mx-2" />}
                />
              </div>
              <InfoCard />
              <div className="p-4 rounded-md bg-gray-50 dark:bg-gray-800">
                <div className="flex justify-around my-auto align-middle">
                  <button
                    onClick={() => {
                      handleOpenAddBuldModalForSubcateogries();
                    }}
                    class="box-border relative z-10 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-thin text-white transition-all duration-300 bg-indigo-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none"
                  >
                    <span class="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                    <span class="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                    <span class="relative z-20 flex items-center text-sm">
                      <RectangleStackIcon className="inline w-5 h-5 mx-1" />
                      Add/Update Offer for subcategory
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      handleClosenAddBuldModalForSubcateogries();
                    }}
                    class="box-border relative z-10 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-thin text-white transition-all duration-300 bg-rose-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-rose-300 ring-offset-rose-200 hover:ring-offset-rose-500 ease focus:outline-none"
                  >
                    <span class="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                    <span class="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                    <span class="relative z-20 flex items-center text-sm">
                      <XCircleIcon className="inline w-5 h-5 mx-1" />
                      Remove Offer for subcategory
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      handleOpenAddBuldModalForBrands();
                    }}
                    class="box-border relative z-10 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-thin text-white transition-all duration-300 bg-teal-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-teal-300 ring-offset-teal-200 hover:ring-offset-teal-500 ease focus:outline-none"
                  >
                    <span class="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                    <span class="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                    <span class="relative z-20 flex items-center text-sm">
                      <SwatchIcon className="inline w-5 h-5 mx-1" />
                      Add/Update Offer for brand
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      handleOpenRemoveBulkModalForBrands();
                    }}
                    class="box-border relative z-10 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-thin text-white transition-all duration-300 bg-red-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-red-300 ring-offset-red-200 hover:ring-offset-red-500 ease focus:outline-none"
                  >
                    <span class="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                    <span class="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                    <span class="relative z-20 flex items-center text-sm">
                      <XCircleIcon className="inline w-5 h-5 mx-1" />
                      Remove Offer for brand
                    </span>
                  </button>
                </div>
                {isHeadlessOpen && (
                  <DiscountModal
                    id={id}
                    open={isHeadlessOpen}
                    setOpen={setIsHeadlessOpen}
                  />
                )}
                {isHeadlessOpenBuy && (
                  <BuyOneGet
                    id={buyId}
                    open={isHeadlessOpenBuy}
                    setOpen={setIsHeadlessOpenBuy}
                  />
                )}
                {update && (
                  <Update
                    id={buyId}
                    open={update}
                    setOpen={setUpdate}
                    quantity={quantity}
                  />
                )}

                {addBulkDiscountForSubCateogries && (
                  <AddDiscountForSubcategoriesModal
                    id={id}
                    open={addBulkDiscountForSubCateogries}
                    setOpen={setAddBulkDiscountForSubCateogries}
                  />
                )}
                {removeBulkDiscountForSubCateogries && (
                  <RemoveDiscountForSubcategoriesModal
                    id={id}
                    open={removeBulkDiscountForSubCateogries}
                    setOpen={setRemoveBulkDiscountForSubCateogries}
                  />
                )}
                {addBulkDiscountForBrand && (
                  <AddDiscountForBrandsModal
                    id={id}
                    open={addBulkDiscountForBrand}
                    setOpen={setAddBulkDiscountForBrand}
                  />
                )}
                {removeBulkDiscountForBrand && (
                  <RemoveDiscountForBrandsModal
                    id={id}
                    open={removeBulkDiscountForBrand}
                    setOpen={setRemoveBulkDiscountForBrand}
                  />
                )}
                <br />
                <br />
                <Tab.Group>
                  <Tab.List className="flex p-1 space-x-1 rounded-xl bg-gray-400/20">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-teal-500",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-teal-400 focus:outline-none focus:ring-2 ",
                          selected
                            ? "bg-white shadow"
                            : "text-gray-700 dark:text-gray-300 hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      Discount
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-teal-500",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-teal-400 focus:outline-none focus:ring-2",
                          selected
                            ? "bg-white shadow"
                            : "text-gray-700 dark:text-gray-300 hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      Buy One Get (One-Discount)
                    </Tab>
                  </Tab.List>
                  <Tab.Panels>
                    {/* Images Secion */}
                    <Tab.Panel>
                      <div className="min-w-full antialiased text-gray- ">
                        <div className="flex flex-col justify-center my-2">
                          {/* <!-- Table --> */}
                          {/* <div>
                          <button
                            onClick={() => setIsHeadlessOpenBuy(true)}
                            type="button"
                            className="right-0 flex items-center justify-center float-right w-full px-4 py-2 text-center text-white transition duration-200 ease-in bg-teal-500 rounded-lg shadow-md absloute w-fit hover:bg-teal-600 focus:ring-teal-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            <PlusCircleIcon
                              src={addIcon}
                              alt="add icon"
                              className="w-6 h-6 mr-2 text-white "
                            />
                            Add
                          </button>{" "}
                        </div> */}
                          <div className="w-full h-[42rem] my-2 bg-white border border-gray-200 rounded-sm shadow-lg">
                            <div className="flex items-center w-auto min-h-full mt-2 h-80 dark:bg-gray-800">
                              <DataGrid
                                rows={percentageRows}
                                getRowId={(row) => row?.productId}
                                columns={percentageColumns}
                                className="dark:bg-gray-800 dark:text-gray-200"
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) =>
                                  setPageSize(newPageSize)
                                }
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                components={{
                                  Toolbar: CustomToolbar,
                                }}
                                // checkboxSelection
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    {/* Upload Section */}
                    <Tab.Panel>
                      {" "}
                      <div className="flex flex-col justify-center my-2">
                        {/* <!-- Table --> */}
                        {/* <div>
                          <button
                            onClick={() => setIsHeadlessOpenBuy(true)}
                            type="button"
                            className="right-0 flex items-center justify-center float-right w-full px-4 py-2 text-center text-white transition duration-200 ease-in bg-teal-500 rounded-lg shadow-md absloute w-fit hover:bg-teal-600 focus:ring-teal-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            <PlusCircleIcon
                              src={addIcon}
                              alt="add icon"
                              className="w-6 h-6 mr-2 text-white "
                            />
                            Add
                          </button>{" "}
                        </div> */}

                        <div className="w-full h-[42rem] my-2 bg-white border border-gray-200 rounded-sm shadow-lg">
                          <div className="flex items-center w-auto min-h-full mt-2 h-80 dark:bg-gray-800">
                            <DataGrid
                              rows={buyOneGetRows}
                              columns={buyOneGetColumns}
                              getRowId={(row) => row?.productId}
                              pageSize={pageSize}
                              onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                              }
                              rowsPerPageOptions={[10, 20, 50, 100]}
                              className="min-h-full dark:text-gray-200 "
                              components={{
                                Toolbar: CustomToolbar,
                              }}
                              // checkboxSelection
                            />
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Index;
