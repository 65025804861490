import { Fragment } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { setAuth } from "../../../features/web/WebAuthentication";
import { useDispatch } from "react-redux";
import {
  KeyIcon,
  MapIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  CloudArrowUpIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";

import { useEffect, useState } from "react";
import axios from "axios";
import login from "../../assets/icons/login.svg";
import { useTranslation } from "react-i18next";
import location from "../../assets/icons/location.svg";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderAnim from "../../assets/icons/SubmitOrder.json";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, id }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get('webAuth')}`,
    },
  };
  const onSubmit = (data) => {
////    console.log(data);

    axios.post("/api/Orders/SubmitOrder", data, config).then((r) => {
      // // console.log(r);
      if (r.status === 200) {
        // dispatch(setAuth(true));
        setSuccess(true);
        setTimeout(() => {
          setOpen(false);
        }, 4000);
      }
    });
  };
  const l = localStorage.getItem("i18nextLng");
  return (
    <Transition appear show={open} as={Fragment} className="duration-500">
      <Dialog as="div" className="relative z-50 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-40 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center min-w-lg">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="z-50 w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {/* <Dialog.Title
                  as="h3"
                  className="my-2 text-lg font-medium leading-6 text-gray-900"
                >
                  {t("login")}
                </Dialog.Title> */}

                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                {/* <div className="container mx-auto my-auto mb-5"> */}
                <div className="flex flex-col max-w-md px-4 py-8 mx-auto bg-white rounded-lg shadow w- dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                  {success ? (
                    <>
                      {" "}
                      <Player
                        src={loaderAnim}
                        autoplay
                        loop
                        className="player"
                      />
                      <div className="inline w-full mx-auto font-medium text-center text-gray-500">
                        {t("orderSuccess")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white ${l}`}
                      >
                        {t("checkOut")}{" "}
                      </div>
                      <img src={location} className="w-32 mx-auto " />
                      <span
                        className={`text-gray-500 font-medium ${l} mx-auto mt-2 `}
                      >
                        {t("plaseWriteYourLocation")}
                      </span>
                      <div className="mt-8">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          {/* <div className="flex flex-col mb-2">
              <div className="relative flex ">
                <span
                  className={`${
                    l === "en-Us" ? "rounded-l-md" : "rounded-r-md"
                  } inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm`}
                >
                  <PhoneIcon className="w-5 h-5 " />
                </span>
                <input
                  type="number"
                  className={` ${
                    l === "en-US" ? "rounded-r-lg" : "rounded-l-lg"
                  } flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent ${
                    errors.phoneNumber && "border-rose-500"
                  }`}
                  placeholder={t("phoneNumber")}
                  {...register("phoneNumber", { required: true })}
                />
              </div>
            </div> */}
                          <div className="flex flex-col mb-6">
                            <div className="relative flex ">
                              {/* <span
                                className={`${
                                  l === "en-Us"
                                    ? "rounded-r-md"
                                    : "rounded-l-md"
                                } inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm`}
                              >

                                <MapPinIcon className="w-5 h-5 " />
                              </span> */}
                              <input
                                type="address"
                                className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                                  errors.phoneNumber &&
                                  "focus:ring-rose-100 focus:border-rose-300"
                                } ${l}`}
                                placeholder={t("address")}
                                {...register("address", { required: true })}
                              />
                            </div>
                          </div>

                          <div className="flex w-full">
                            <button
                              type="submit"
                              className={`w-full px-4 py-3 text-base font-medium text-center text-white transition duration-200 ease-in bg-teal-600 rounded-lg shadow-md hover:bg-teal-700 focus:ring-teal-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2  ${l}`}
                            >
                              {t("submit")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                  {/* <div className="flex items-center justify-center mt-6">
          <Link
            to="/register"
            className="inline-flex items-center text-xs font-thin text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white"
          >
            <span className={`ml-2 ${l}`}>{t("dontHaveAccount")}</span>
          </Link>
        </div> */}
                </div>
                {/* </div> */}
                {/*     </form> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
