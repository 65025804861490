import React, { useState, useEffect, Fragment } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/user.svg";
import addIcon from "../../icons/add.svg";
import InfoCard from "../../components/Cards/Carts";
import Image from "./Imgae";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import Cookies from "js-cookie";
import Spiner from "../../components/Loader/Spiner";
// Icons
import {
  CheckCircleIcon,
  ChevronDownIcon,
  EyeIcon,
  PhoneIcon,
  PhoneXMarkIcon,
  PlusCircleIcon,
  TrashIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  getClients,
  filterDate,
  removeFilter,
} from "../../../features/Global/Client";
// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Menu, Transition } from "@headlessui/react";

const Index = () => {
  const [isHeadlessOpen, setIsHeadlessOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [view, setView] = useState(false);
  const [upload, setUpload] = useState(false);
  const [uploadId, setUploadId] = useState();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Client.copyData);
  const loader = useSelector((state) => state.Client.loading);
  const isSuccess = useSelector((state) => state.Client.isSuccess);
  const [file, setFile] = useState();
  const now = new Date();
  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handleSelectedFilter = (f, t) => {
    setFrom(f);
    setTo(t);
  };
  // Toast
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  // hook to fetch items
  useEffect(() => {
    dispatch(getClients());
  }, []);
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const handleRemoveFilters = () => {
    dispatch(removeFilter());
    setFrom("");
    setTo("");
  };
  useEffect(() => {
    if (from !== "" && to !== "") {
      dispatch(filterDate([from, to]));
    }
  }, [from, to]);
  const handleCheckOfferTypes = (value) => {
    // if (value === 0) {
    //   return "No Offer";
    // }
    if (value === 0) {
      return "Male";
    }
    if (value === 1) {
      return "Female";
    }
    //  else {
    //   return "Buy one get some percenteage of";
    // }
  };
  function getFullName(params) {
    return `${params.row.firstName || ""} ${params.row.secondName || ""}  ${
      params.row.thirdName || ""
    } `;
  }

  const columns = [
    { field: "id", headerName: "id", width: 70 },
    // {
    //   field: "userImage",
    //   headerName: "Image",
    //   width: 70,
    //   renderCell: (cellvalues) => {
    //     return (
    //       <img
    //         // onClick={() => {
    //         //   handleUload(cellvalues.row.id);
    //         // }}
    //         className="w-10 h-10 rounded-full"
    //         src={cellvalues.value}
    //       />
    //     );
    //   },
    // },
    {
      field: "firstName",
      headerName: "Full name",
      minWidth: 170,
      flex: 1,
      valueGetter: getFullName,
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      minWidth: 150,
      flex: 1,
      renderCell: (cellvalues) => (
        <p className="float-right w-full text-right">{cellvalues.value}</p>
      ),
    },
    {
      field: "birthDate",
      headerName: "Birth Day",
      width: 150,
      flex: 1,
      renderCell: (cellvalues) => (
        <p className="float-right w-full text-right">
          {cellvalues.value.split("T")[0]}
        </p>
      ),
    },
    {
      field: "gender",
      headerName: "Gender",
      minWidth: 160,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          className={`mx-auto text-center inline-flex items-center justify-center text-sm font-medium shadow-md duration-300 rounded-lg py-2 px-4 text-white ${
            cellvalues.row.gender === 0
              ? " bg-blue-600  hover:bg-blue-700  focus:ring-blue-500 focus:ring-offset-blue-200 "
              : " bg-pink-500  hover:bg-pink-600  focus:ring-pink-400 focus:ring-offset-pink-200 "
          } `}
        >
          {handleCheckOfferTypes(cellvalues.row.gender)}
        </button>
      ),
    },
    {
      field: "isPhoneNumberConfirmed",
      headerName: "Confirmation",
      minWidth: 200,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleUserConfirmation(cellvalues?.id)}
          className={`text-center inline-flex items-center justify-center px-3 py-1 mx-auto text-sm font-medium shadow-md ${
            cellvalues.row.isPhoneNumberConfirmed === true
              ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400-600 focus:ring-emerald-500 focus:ring-offset-emerald-200 f "
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {cellvalues.row.isPhoneNumberConfirmed === true ? (
            <>
              <PhoneIcon className="inline w-5 mx-1" /> Confirmed
            </>
          ) : (
            <>
              <PhoneXMarkIcon className="inline w-5 mx-1" /> Not Confirmed
            </>
          )}
          {/* {handleCheckOfferTypes(cellvalues.row.gender)} */}
        </button>
      ),
    },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 160,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleDelete(cellvalues?.id)}
          className={`text-center inline-flex items-center justify-center px-3 py-1 mx-auto text-sm font-medium shadow-md ${
            cellvalues.row.isActive === true
              ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400-600 focus:ring-emerald-500 focus:ring-offset-emerald-200 f "
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {cellvalues.row.isActive === true ? (
            <>
              <CheckCircleIcon className="inline w-5 mx-1" /> Active
            </>
          ) : (
            <>
              <XCircleIcon className="inline w-5 mx-1" /> In Active
            </>
          )}
          {/* {handleCheckOfferTypes(cellvalues.row.gender)} */}
        </button>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      flex: 1,
      renderCell: (cellvalues) => (
        <p className="float-right w-full text-right">
          {/* {Date(cellvalues.value)?.toDateString()} */}
          {new Date(cellvalues?.value).toLocaleString()}
        </p>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 150,
      flex: 1,
      renderCell: (cellvalues) => (
        <p className="float-right w-full text-right">
          {/* {cellvalues.value.split("T")[0]} */}
          {new Date(cellvalues?.value).toLocaleString()}
        </p>
      ),
    },
    // {
    //   field: "Edit",
    //   headerName: "Edit",
    //   width: 130,
    //   renderCell: (cellvalues) => (
    //     <button
    //       onClick={() => handleEdit(cellvalues.id)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-700 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-700 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <PencilIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Edit
    //     </button>
    //   ),
    // },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 130,
    //   renderCell: (cellvalues) => (
    //     <button
    //       onClick={(e) => handleDelete(cellvalues.id)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-rose-500 hover:bg-rose-600 focus:ring-rose-700 focus:ring-offset-rose-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <TrashIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Delete
    //     </button>
    //   ),
    // },
    // {
    //   field: "View",
    //   headerName: "View",
    //   width: 130,
    //   renderCell: () => (
    //     <button
    //       onClick={() => setView(true)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full px-3 py-2 text-center text-white transition duration-200 ease-in bg-orange-500 rounded-lg shadow-md hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <EyeIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       View
    //     </button>
    //   ),
    // },
  ];
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const handleUload = (id) => {
    setUploadId(id);
    setUpload(true);
    dispatch(getClients());
  };
  const handleEdit = (id) => {
    setEdit(true);
    setEditId(id);
  };

  const handleDelete = (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*",
        Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
      },
    };
    console.log(id);
    console.log(config);
    axios
      .delete(`api/Users/${id}`, config)
      .then((r) => {
        console.log(r);
        if (r.status === 200 || r.status === 204) {
          setMessage("User status updated successfully.");
          setToast(true);
          dispatch(getClients());
        }
      })
      .catch((e) => {
        console.log(e);
        setMessage(e.response.data);
        setError(true);
      });
  };
  const handleUserConfirmation = (id) => {
    axios
      .put(`api/Users/VerifyCustomer/${id}`, 0, config)
      .then((r) => {
        console.log(r);
        if (r.status === 200 || r.status === 204) {
          setMessage("User confirmation updated successfully.");
          setToast(true);
          dispatch(getClients());
        }
      })
      .catch((e) => {
        console.log(e);
        setMessage(e.response.data);
        setError(true);
      });
  };
  return (
    <div className="ltr">
      <main className="relative h-screen bg-gray-100 dark:bg-gray-800">
        <div className="flex items-start justify-between h-screen overflow-y-auto">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              {/* <div className="block ml-6 lg:hidden">
                <button className="flex items-center p-2 text-gray-500 bg-white rounded-full shadow text-md">
                  <svg
                    width="20"
                    height="20"
                    className="text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                  </svg>
                </button>
              </div> */}
              {/* Header */}
              <Header />
            </header>
            <div className="h-screen px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
              <div className="">
                <BreadCrumb
                  page={"Customers"}
                  icon={<UserCircleIcon className="inline w-6 mx-2" />}
                />
              </div>
              <InfoCard />
              <div className="my-4  h-[44rem] rounded-md bg-gray-50 dark:bg-gray-800">
                <div className="">
                  <div className="flex w-full">
                    <div className="w-full p-6 bg-white border border-gray-200 shadow-lg rounded-xl dark:bg-gray-800 dark:border-gray-600">
                      <div className="">
                        {/* <div className="relative flex items-center justify-between w-full mb-5 rounded-md">
                        <svg
                          className="absolute block w-5 h-5 text-gray-400 left-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <circle cx="11" cy="11" r="8" className=""></circle>
                          <line
                            x1="21"
                            y1="21"
                            x2="16.65"
                            y2="16.65"
                            className=""
                          ></line>
                        </svg>
                        <input
                          type="name"
                          name="search"
                          ref={search}
                          onChange={(e) => handleSearch(e.target.value)}
                          className="w-full h-12 py-4 pl-12 pr-40 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-text focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:bg-gray-700 dark:text-gray-700 dark:border-gray-600"
                          placeholder="Search by Name, Brand, Category, Subcategory, Price, etc"
                        />
                      </div> */}

                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                          <div className="flex flex-col">
                            <label
                              htmlFor="date"
                              className="text-sm font-medium text-stone-600 dark:text-stone-300"
                            >
                              From
                            </label>
                            <input
                              type="date"
                              id="date"
                              value={from}
                              onChange={(e) => setFrom(e.target.value)}
                              className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label
                              htmlFor="date"
                              className="text-sm font-medium text-stone-600 dark:text-stone-300"
                            >
                              To
                            </label>
                            <input
                              type="date"
                              id="date"
                              value={to}
                              onChange={(e) => setTo(e.target.value)}
                              className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                            />
                          </div>

                          {/* <div className="flex flex-col">
                          <label
                            htmlFor="status"
                            className="text-sm font-medium text-stone-600"
                          >
                            Status
                          </label>

                          <select
                            id="status"
                            className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          >
                            <option>Dispached Out</option>
                            <option>In Warehouse</option>
                            <option>Being Brought In</option>
                          </select>
                        </div> */}
                          <div className="right-0 grid float-right w-full grid-cols-2 mt-6 space-x-4 md:flex">
                            {/* <button className="px-8 py-2 font-medium text-white bg-blue-600 rounded-lg outline-none hover:opacity-80 focus:ring">
                          Search
                        </button> */}
                            <Menu
                              as="div"
                              className="relative inline-block h-full py-1 my-auto text-left align-middle "
                            >
                              <div>
                                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus-ring-offset-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">
                                  Filters
                                  <ChevronDownIcon
                                    className="w-5 h-5 ml-2 -mr-1"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 dark:text-gray-300">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          onClick={() =>
                                            handleSelectedFilter(
                                              new Date(
                                                now.getFullYear(),
                                                now.getMonth(),
                                                now.getDate() - 7
                                              )
                                                .toISOString()
                                                .split("T")[0],
                                              new Date(
                                                now.getFullYear(),
                                                now.getMonth(),
                                                now.getDate()
                                              )
                                                .toISOString()
                                                .split("T")[0]
                                            )
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                              : "text-gray-700 dark:text-gray-300",
                                            "block px-4 py-2 text-sm w-full text-left"
                                          )}
                                        >
                                          Last week{" "}
                                        </button>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          onClick={() =>
                                            handleSelectedFilter(
                                              new Date(
                                                now.getFullYear(),
                                                now.getMonth() - 1,
                                                now.getDate()
                                              )
                                                .toISOString()
                                                .split("T")[0],
                                              new Date(
                                                now.getFullYear(),
                                                now.getMonth(),
                                                now.getDate()
                                              )
                                                .toISOString()
                                                .split("T")[0]
                                            )
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                              : "text-gray-700 dark:text-gray-300",
                                            "block px-4 py-2 text-sm w-full text-left"
                                          )}
                                        >
                                          Last month{" "}
                                        </button>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          onClick={() =>
                                            handleSelectedFilter(
                                              new Date(
                                                now.getFullYear(),
                                                now.getMonth() - 3,
                                                now.getDate()
                                              )
                                                .toISOString()
                                                .split("T")[0],
                                              new Date(
                                                now.getFullYear(),
                                                now.getMonth(),
                                                now.getDate()
                                              )
                                                .toISOString()
                                                .split("T")[0]
                                            )
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                              : "text-gray-700 dark:text-gray-300",
                                            "block px-4 py-2 text-sm w-full text-left"
                                          )}
                                        >
                                          Last three month
                                        </button>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          onClick={() =>
                                            handleSelectedFilter(
                                              new Date(
                                                now.getFullYear() - 1,
                                                now.getMonth(),
                                                now.getDate()
                                              )
                                                .toISOString()
                                                .split("T")[0],
                                              new Date(
                                                now.getFullYear(),
                                                now.getMonth(),
                                                now.getDate()
                                              )
                                                .toISOString()
                                                .split("T")[0]
                                            )
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                              : "text-gray-700 dark:text-gray-300",
                                            "block px-4 py-2 text-sm w-full text-left"
                                          )}
                                        >
                                          Last three month
                                        </button>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                            <button
                              className="px-8 py-2 font-medium text-gray-600 bg-gray-200 rounded-lg outline-none active:scale-95 focus:ring hover:opacity-90 dark:bg-gray-700 dark:text-gray-300"
                              onClick={() => handleRemoveFilters()}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button
                  onClick={() => setIsHeadlessOpen(true)}
                  type="button"
                  className="flex items-center justify-center float-right w-full px-4 py-2 text-center text-white transition duration-200 ease-in bg-teal-500 rounded-lg shadow-md w-fit hover:bg-teal-600 focus:ring-teal-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                >
                  <PlusCircleIcon
                    src={addIcon}
                    alt="add icon"
                    className="w-6 h-6 mr-2 text-white "
                  />
                  Add
                </button>{" "} */}
                {isHeadlessOpen && (
                  <Add
                    Title={"Add Product"}
                    open={isHeadlessOpen}
                    setOpen={setIsHeadlessOpen}
                  />
                )}
                {edit && (
                  <Edit
                    Title={"Edit Product"}
                    open={edit}
                    id={editId}
                    setOpen={setEdit}
                  />
                )}{" "}
                {upload && (
                  <Image
                    id={uploadId}
                    Title={"Upload Image"}
                    open={upload}
                    // id={editID}
                    setOpen={setUpload}
                  />
                )}{" "}
                {view && (
                  <View Title={"View Product"} open={view} setOpen={setView} />
                )}
                <br />
                <br />
                {/* {loader && <Spiner />} */}
                {data?.length === 0 && loader && !isSuccess ? (
                  <>
                    <Spiner />
                  </>
                ) : (
                  <div className="flex items-center w-auto h-full mt-2 dark:bg-gray-800">
                    <DataGrid
                      rows={data}
                      columns={columns}
                      className="dark:bg-gray-800 dark:text-gray-200"
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {toast && <Toast Text={message} Type={"teal"} setOpen={setToast} />}
        {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}
      </main>
    </div>
  );
};

export default Index;
