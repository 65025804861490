import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../../features/Global/News";
import {
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import Parser from "html-react-parser";
const NewsSection = () => {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const data = useSelector((state) => state.News.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNews());
  }, []);
  return (
    <div className="pb-6 bg-white" data-aos="fade-up">
      {/* <section className="container flex flex-row flex-wrap py-10 mx-auto">
        <div className="container p-4 bg-white">
          <div className="flex flex-wrap justify-center -mx-4">
            <div className="w-full px-4">
              <div className="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">
                <span
                  className={`block mb-2 text-lg font-semibold text-primary ${l}`}
                >
                  {t("varyinformation")}
                </span>
                <h2
                  className={`
                       font-bold
                       text-3xl
                       sm:text-4xl
                       md:text-[40px]
                       text-dark
                       mb-4
                       ${l} `}
                >
                  {t("ourRecentNews")}
                </h2>
                <p className={`text-base text-body-color ${l} `}>
                  There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>
        </div>
        {data
          ?.filter((item) => item.isActive)
          .slice(0, 6)
          .map((item, index) => (
            <Link
              to={`information/${item.id}`}
              key={index}
              className="container flex px-4 py-4 transition-all duration-150 md:w-1/2 lg:w-1/3"
            >
              <div className="flex flex-col items-stretch min-h-full pb-4 mb-6 transition-all duration-150 bg-white rounded-lg shadow-lg hover:shadow-2xl">
                <div className="md:flex-shrink-0">
                  <img
                    src={item.fileName}
                    alt="Blog Cover"
                    className="object-fill w-full rounded-lg rounded-b-none md:h-56"
                  />
                </div>
                <div className="flex items-center justify-between px-4 py-2 overflow-hidden">
                  <span
                    className={`text-xs font-medium text-teal-600 uppercase ${l}`}
                  >
                    {t("varyInformation")}
                  </span>
                </div>
                <hr className="border-gray-300" />
                <div className="flex flex-wrap items-center flex-1 px-4 py-1 mx-auto text-center">
                  <a href="#" className="hover:underline">
                    <h2
                      className={`text-lg font-bold tracking-normal text-gray-800 ${l}`}
                    >
                      {l === "en-US" && item.englishTitle}{" "}
                      {l === "ar" && item.arabicTitle}{" "}
                      {l === "ku" && item.kurdishTitle}{" "}
                    </h2>
                  </a>
                </div>
                <hr className="border-gray-300" />
                <p
                  className={`line-clamp-5  ${l} h-22 flex flex-row flex-wrap w-full px-4 py-2 overflow-hidden text-sm text-justify text-gray-700`}
                >
                  {l === "en-US" && item.englishBody}{" "}
                  {l === "ar" && item.arabicBody}{" "}
                  {l === "ku" && item.kurdishBody}{" "}
                </p>
                <hr className="border-gray-300" />
                <section className="px-4 py-2 mt-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center flex-1">
                      <img
                        className="object-cover h-10 rounded-full"
                        src={item?.userImage}
                        alt="Avatar"
                      />
                      <div className="flex flex-col mx-2">
                        <a
                          href
                          className={`font-semibold text-gray-700 text-sm hover:underline ${l}`}
                        >
                          {item?.user}
                        </a>
                        <span className="mx-1 text-xs text-gray-600">
                          {item?.insertedAt}
                        </span>
                      </div>
                    </div>
                    <Link
                      to={`information/${item.id}`}
                      className={`mt-1 text-xs bg-clip-text text-transparent bg-gradient-to-br from-gray-600 to-gray-800 hover:scale-105 duration-500 group: ${l}`}
                    >
                      {t("readMore")}
                    </Link>
                  </div>
                </section>
              </div>
            </Link>
          ))}

      </section> */}
      <section className="py-6 ">
        {/* <h1 className={`mb-12 pt-4 text-center text-5xl font-bold ${l}`}>
          {t("varyinformation")}
        </h1> */}
        <div className="max-w-screen-sm mx-auto my-6 mb-8 lg:mb-16">
          <p
            className={`mb-4 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-teal-400 to-teal-600 text-center py-2 ${l}`}
          >
            {t("varyinformation")}
          </p>
          <p
            className={`font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center ${l}`}
          >
            {t("ourRecentNews")}
          </p>
        </div>
        <div className="grid max-w-screen-lg grid-cols-1 gap-4 py-5 mx-auto sm:grid-cols-2 md:grid-cols-3 lg:gap-3">
          {data
            ?.filter((item) => item.isActive)
            .slice(0, 6)
            .map((item, index) => (
              <Link
                to={`information/${item.id}`}
                key={index}
                className="h-full mx-3 overflow-hidden border-2 border-gray-200 rounded-lg shadow-lg md:mx-0 group border-opacity-60"
              >
                <img
                  className="object-cover object-center w-full transition duration-500 ease-in-out transform group-hover:scale-105 md:h-36 lg:h-48"
                  src={item.fileName}
                  alt="blog"
                />
                <h2
                  className={`title-font inline-block cursor-pointer px-6 pt-4 pb-1 text-xs font-semibold uppercase tracking-widest text-blue-600 hover:font-bold ${l}`}
                >
                  {t("varyinformation")}
                </h2>
                <div className="px-6 py-1">
                  <h1
                    className={`title-font mb-3 inline-block cursor-pointer text-xl capitali font-extrabold tracking-wide text-gray-800 ${l}`}
                  >
                    {l === "en-US" && item.englishTitle}{" "}
                    {l === "ar" && item.arabicTitle}{" "}
                    {l === "ku" && item.kurdishTitle}{" "}
                  </h1>
                  <p
                    className={`line-clamp-6 mb-3 h-18 cursor-pointer overflow-hidden leading-relaxed text-gray-500 ${l}`}
                  >
                    {l === "en-US" && Parser(item.englishBody)}
                    {l === "ar" && Parser(item.arabicBody)}
                    {l === "ku" && Parser(item.kurdishBody)}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between px-6 pt-1 pb-4">
                  <div className="flex flex-wrap text-sm text-gray-500">
                    <span className="mr-1"> {item?.updatedAt}</span>
                    {/* <span className="">· 9 min read</span> */}
                  </div>
                  <div className="mt-1">
                    <span className="inline-flex items-center py-1 pr-3 ml-auto mr-3 text-sm leading-none text-gray-400 md:ml-0 lg:ml-auto">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        ></path>
                      </svg> */}
                      {/* <img
                        className="object-cover h-8 rounded-full"
                        src={item?.userImage}
                        alt="Avatar"
                      /> */}
                      {item?.user}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </section>

      <div className="flex flex-row-reverse flex-wrap justify-center gap-4 pb-3 mx-auto md:gap-6 md:justify-end">
        <Link
          to="/information"
          type="button"
          title="Start buying"
          className="w-1/2 px-10 py-3 mx-auto text-center transition duration-500 rounded-lg shadow-xl md:w-1/4 lg:w-1/6 bg-gradient-to-br from-teal-400 to-teal-600 hover:bg-teal-600 active:bg-gray-700 focus:bg-teal-600 hover:scale-x-110 hover:scale-y-105"
        >
          <span className={`block font-semibold text-white ${l}`}>
            {t("viewMore")}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default NewsSection;
