import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, Navigate, useNavigate } from "react-router-dom";
// Assets
import USA from "../assets/icons/usa.svg";
import vary from "../assets/images/vary.jpeg";
import image from "../assets/images/medicine.jpeg";
import image3 from "../assets/images/medicine3.jpeg";
import Locale from "./shop/Locale";
// Components
import ShopSideBarComponent from "./shop/ShoppingSidebar";
import Chatpopup from "../componets/Chat/ChatPopupTailwind";
// Locale
import { useTranslation } from "react-i18next";
// import ScrollTop from "./Scroll/ScrollTop.jsx";
// dispatch
import { useDispatch } from "react-redux";
import { setFillter } from "../../features/web/Filter";
import { useSelector } from "react-redux";
import { getAuth } from "../../features/web/WebAuthentication";
import { getCategory } from "../../features/Global/Category";
import { getSubCategory } from "../../features/Global/SubCategory";
import { getItem } from "../../features/Global/Items";
import Account from "./shop/Account";
import { getBasket } from "../../features/web/ShopingCart";
import { getMessages } from "../../features/Global/Messages";
import { getContact } from "../../features/Global/ContactUs";
import { getConfiguration } from "../../features/Global/Configuration";
import { useMediaQuery } from "react-responsive";

// Inside your component
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import ToTopScroll from "./ToTopScroll";
import Cookies from "js-cookie";
import MobileLocale from "./shop/MobileLocale";
import krlogo from "../assets/images/flag/kurdistan.png";
import irlogo from "../assets/images/flag/iraq.png";
import Banner from "./Banner";

const navigation = {
  categories: [
    {
      id: "categories",
      name: "categories",
      featured: [
        {
          name: "New Arrivals",
          to: "#",
          imageSrc: image,
          imageAlt:
            "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Basic Tees",
          to: "#",
          imageSrc: image3,
          imageAlt:
            "Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.",
        },
      ],
      sections: [
        {
          id: "Categories",
          name: "Categories",
          items: [
            { name: "face", to: "#" },
            { name: "Dresses", to: "#" },
            { name: "eye", to: "#" },
            { name: "Denim", to: "#" },
            { name: "teeth", to: "#" },
            { name: "creams", to: "#" },
            { name: "multi vitamins", to: "#" },
          ],
        },
        {
          id: "Beauty",
          name: "Beauty",
          items: [
            { name: "creams", to: "#" },
            { name: "skin care", to: "#" },
            { name: "paste", to: "#" },
          ],
        },
        {
          id: "brands",
          name: "Brands",
          items: [
            { name: "Full Nelson", to: "#" },
            { name: "My Way", to: "#" },
            { name: "Re-Arranged", to: "#" },
            { name: "Counterfeit", to: "#" },
            { name: "Significant Other", to: "#" },
          ],
        },
      ],
    },
  ],
  pages: [
    { name: "shop", to: "/shop" },
    { name: "offers", to: "/offers" },
    // { name: "brands", to: "/brands" },
    { name: "information", to: "/information" },
    { name: "varyTeam", to: "/profile" },
    { name: "doctors", to: "/doctors" },
    { name: "askVary", to: "/askvary" },
  ],
};
const languages = [
  // {
  //   key: 1,
  //   code: "en-US",
  //   name: "English",
  //   country_code: "en-US",
  //   dir: "ltr",
  //   image: usalogo,
  // },
  {
    key: 2,
    code: "ku",
    name: "کوردی (سۆرانی)",
    dir: "rtl",
    country_code: "ku",
    image: krlogo,
  },

  {
    key: 3,
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
    image: irlogo,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [header, setHeader] = useState(true);
  const [ShopSideBar, setShopSideBar] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const basket = useSelector((state) => state.ShopingCart.count);
  const item = useSelector((state) => state.item.data);
  const loading = useSelector((state) => state.item.loading);
  const isSuccess = useSelector((state) => state.item.isSuccess);
  const contact = useSelector((state) => state.Contact.data);
  const Configuration = useSelector((state) => state.Configuration.data);
  //console.log(Configuration);
  const dispatch = useDispatch();
  const filtter = (params) => {
    // console.log(params);
    navigate(`/shop/${params}`);
    // navigate(`/shop/${params}`);
    // dispatch(setFillter(params));
    dispatch(getAuth());
  };
  const data = useSelector((state) => state.category.data);
  const dataLoading = useSelector((state) => state.category.loading);
  const dataIsSuccess = useSelector((state) => state.category.isSuccess);
  const subCategories = useSelector((state) => state.SubCategory?.data);
  useEffect(() => {
    if (Cookies.get("webAuth")) {
      dispatch(getBasket());
      dispatch(getMessages());
    }
    dispatch(getSubCategory());
    dispatch(getCategory());
    dispatch(getItem());
    dispatch(getContact());
    if (isLogedIn) {
      const joinRoom = async () => {
        try {
          const connection = new HubConnectionBuilder()
            .withUrl(`http://stacksolvers-001-site26.htempurl.com/chatHub`, {
              accessTokenFactory: () => Cookies.get("webAuth"),
              // UseDefaultCredentials: true,
              skipNegotiation: true,
              transport: HttpTransportType.WebSockets,
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

          connection.logging = true;
          await connection.start().then(async (result) => {
            // console.log("join room");
            //Join Room on Start
            await connection.invoke("SendMessage", {
              userId: 30,
              message: "tessttttt",
            });

            //Events: Receive Message
            connection.on("ReceiveMessage", (user, message) => {
              console.log("message recived", message);
            });
            //Events: On Close
            connection.onclose((e) => {
              console.log("close connection", e);
            });
          });
        } catch (error) {
          console.log("catch error", error);
        }
      };
    }
  }, []);
  const isMediumScreen = useMediaQuery({ maxWidth: 768 });
  const isSmallScreen = useMediaQuery({ maxWidth: 480 });
  const locale =
    localStorage.getItem("i18nextLng") ?? localStorage.getItem("i18nextLng");

  // const isLogedIn = useSelector((state) => state.Auth.value);
  const isLogedIn = Cookies.get("webAuth") ? true : false;
  // console.log("isloged in", isLogedIn);
  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-20 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom={`${
                locale === "en-US" ? "-translate-x-full" : "translate-x-full"
              }`}
              enterTo={`${
                locale === "en-US" ? " translate-x-0" : "translate-x-0"
              }`}
              leave="transition ease-in-out duration-300 transform"
              leaveFrom={` ${
                locale === "en-US" ? "translate-x-0" : "-translate-x-0"
              }`}
              leaveTo={`${
                locale === "en-US" ? "-translate-x-full" : "translate-x-full"
              }`}
            >
              <Dialog.Panel className="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="flex px-4 -mb-px space-x-8">
                      {navigation.categories.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "text-teal-600 border-teal-600 "
                                : "text-gray-900 border-transparent",
                              `flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium ${locale}`
                            )
                          }
                        >
                          {t(category.name)}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="px-4 pt-10 pb-8 space-y-10"
                      >
                        <div className="z-20 grid grid-cols-2 gap-x-4">
                          {!loading &&
                            isSuccess &&
                            item
                              ?.slice(item.length - 2, item.legth)
                              ?.map((item) => (
                                <div
                                  key={item.name}
                                  className="relative group text-md"
                                >
                                  <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-1 aspect-h-1 group-hover:opacity-75">
                                    <img
                                      src={item.imageUrl}
                                      alt={item.imageUrl}
                                      className="object-contain object-center"
                                    />
                                  </div>
                                  <Link
                                    to={`/shop/product/${item?.id}`}
                                    className={`block mt-6 font-medium text-gray-900 ${locale} `}
                                  >
                                    <span
                                      className={`absolute inset-0 z-10 text-sm ${locale}`}
                                      aria-hidden="true"
                                    />
                                    {/* {item.name} */}
                                    {locale === "en-US" && item?.name?.english}
                                    {locale === "ar" && item?.name?.arabic}
                                    {locale === "ku" &&
                                      item?.name?.kurdish}{" "}
                                  </Link>
                                  <Link
                                    aria-hidden="true"
                                    to={`/shop/product/${item?.id}`}
                                    className={`mt-1 text-black ${locale}`}
                                  >
                                    {t("shopNow")}
                                  </Link>
                                </div>
                              ))}
                        </div>
                        {!dataLoading &&
                          dataIsSuccess &&
                          data?.map((section, index) => (
                            <div key={index}>
                              <p
                                onClick={() =>
                                  filtter(
                                    locale === "en-US"
                                      ? section.category.english +
                                          "." +
                                          section.id
                                      : locale === "ku"
                                      ? section.category.kurdish +
                                        "." +
                                        section.id
                                      : locale === "ar"
                                      ? section.category.arabic +
                                        "." +
                                        section.id
                                      : null
                                  )
                                }
                                id={`${category.id}-${section.id}-heading-mobile`}
                                className={`font-small text-gray-900 hover:text-white hover:bg-gradient-to-br  from-teal-500 to-teal-600 max-h-fit rounded-md hover:p-1 ${locale}`}
                              >
                                <img
                                  className="inline w-12 h-12 mx-2 rounded-full"
                                  alt="category"
                                  src={section.imageUrl}
                                />
                                {locale === "en-US" && section.category.english}
                                {locale === "ar" && section.category.arabic}
                                {locale === "ku" &&
                                  section.category.kurdish}{" "}
                              </p>
                              {/* <ul
                              role="list"
                              aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                              className="flex flex-col space-y-6"
                            >
                              {data?.subCategories?.map((item) => (
                                <li key={item.id} className="flow-root">
                                  <Link
                                    to={item?.id}
                                    className={`block p-2 -m-2 text-gray-500 ${locale}`}
                                  >
                                    {locale === "en-US" &&
                                      section.category.english}
                                    {locale === "ar" && section.category.arabic}
                                    {locale === "ku" &&
                                      section.category.kurdish}{" "}
                                  </Link>
                                </li>
                              ))}
                            </ul> */}
                            </div>
                          ))}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="px-4 py-6 space-y-6 border-t border-gray-200">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <Link
                        to={page.to}
                        className={`block p-2 -m-2 font-medium text-gray-900 ${locale}`}
                      >
                        {/* {page.name} */}
                        {t(page.name)}
                      </Link>
                    </div>
                  ))}
                </div>
                <div>
                  <Locale
                    className={`float-right ${
                      locale === "en-Us" ? "mr-0" : "ml-0"
                    }`}
                  />
                </div>
                <div className="px-4 py-6 space-y-6 border-t border-gray-200">
                  <div className="flow-root">
                    <Link
                      to="/login"
                      className={`block p-2 -m-2 font-medium text-gray-900 ${locale}`}
                    >
                      {t("signIn")}
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link
                      to="/register"
                      className={`block p-2 -m-2 font-medium text-gray-900 ${locale}`}
                    >
                      {t("createAccount")}
                    </Link>
                  </div>
                </div>

                <div className="px-4 py-6 border-t border-gray-200">
                  <Link to="/" className="flex items-center p-2 -m-2">
                    <img
                      src={Configuration[0]?.imageUrl}
                      alt=""
                      className="flex-shrink-0 block w-10 h-auto"
                    />
                    <span
                      className={`block p-2 -m-2 text-base font-medium text-gray-900 ${locale}`}
                    >
                      {/* {t("cad")} */}
                    </span>
                    {/* <span className="sr-only">, change currency</span> */}
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative z-20 bg-white">
        {/* Header */}
        {contact[0]?.bannerStatus && (
          <p
            className={`flex items-center justify-center h-10 px-4 font-medium text-white bg-teal-500 text-md sm:px-6 lg:px-8 ${locale}`}
          >
            {locale === "en-US" && contact[0]?.englsihBanner}{" "}
            {locale === "ar" && contact[0]?.arabicBanner}{" "}
            {locale === "ku" && contact[0]?.kurdishBanner}{" "}
          </p>
        )}
        {/* End header */}
        {/* Banner */}
        <Banner />
        {/* End banner */}

        {/* Web Nav bar */}
        <nav
          aria-label="Top"
          className="flex items-center max-w-7xl sm:px-6 lg:min-w-full"
        >
          <div className="items-center flex-1 border-b border-gray-200">
            <div className="flex items-center h-16 ">
              <button
                type="button"
                className="p-2 text-gray-400 bg-white rounded-md lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>{" "}
                <Bars3Icon className="w-6 h-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="flex ml-4 lg:ml-0">
                <Link to="/">
                  <span className="sr-only">Vary</span>
                  <img
                    className="w-auto h-16"
                    src={Configuration[0]?.imageUrl}
                    alt=""
                  />
                </Link>
              </div>

              {/* Flyout menus */}
              {/* Web */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {navigation?.categories
                    ?.slice(navigation.length - 3, navigation.length)
                    ?.map((category) => (
                      <Popover key={category.name} className="flex mx-1">
                        {({ open }) => (
                          <>
                            <div className="relative flex">
                              <Popover.Button
                                className={classNames(
                                  open
                                    ? "border-teal-600 text-teal-600 mx-2  hover:text-teal-600 duration-500"
                                    : "border-transparent text-gray-700  mx-2 hover:text-teal-600 duration-500",
                                  `relative z-10 -mb-px flex items-center border-b-2 pt-px text-md font-medium transition-colors ease-out hover:text-teal-600 duration-500 ${locale}`
                                )}
                              >
                                {t("categories")}
                                {/* {t(category.name)} */}
                              </Popover.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Popover.Panel className="absolute inset-x-0 text-gray-500 top-full text-md">
                                {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                <div
                                  className="absolute inset-0 bg-white shadow top-1/2"
                                  aria-hidden="true"
                                />

                                <div className="relative bg-white">
                                  <div className="px-8 mx-auto max-w-8xl">
                                    <div className="grid grid-cols-2 py-16 gap-y-10 gap-x-8">
                                      <div className="grid grid-cols-3 col-start-2 gap-x-8">
                                        {item?.slice(item, 3)?.map((item) => (
                                          <Link
                                            to={"/shop"}
                                            key={item?.id}
                                            className="relative text-base group sm:text-md"
                                          >
                                            <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-1 aspect-h-1 group-hover:opacity-75">
                                              <img
                                                src={item?.imageUrl}
                                                alt={item?.imageAlt}
                                                className="object-contain object-center"
                                              />
                                            </div>
                                            <Link
                                              to={`/shop/product/${item?.id}`}
                                              className="block mt-6 font-medium text-gray-900"
                                            >
                                              <span
                                                className={`absolute inset-0 z-10 ${locale}`}
                                                aria-hidden="true"
                                              />
                                              <span
                                                className={`hover:text-teal-600 duration-500 ${locale}`}
                                              >
                                                {locale === "en-US" &&
                                                  item.name.english}
                                                {locale === "ar" &&
                                                  item.name.arabic}
                                                {locale === "ku" &&
                                                  item.name.kurdish}{" "}
                                              </span>
                                            </Link>
                                            <Link
                                              to={`/shop/product/${item?.id}`}
                                              aria-hidden="true"
                                              className={`mt-1 ${locale}`}
                                            >
                                              {t("shopNow")}
                                            </Link>
                                          </Link>
                                        ))}
                                      </div>
                                      <div className="grid grid-cols-4 row-start-1 font-medium text-gray-900 gap-x-1 gap-y-0 max-w-fit">
                                        {!dataLoading &&
                                          dataIsSuccess &&
                                          data?.slice(0, 8).map((section) => (
                                            <span
                                              key={section?.id}
                                              className="inline m-1 max-w-fit group "
                                            >
                                              <button
                                                onClick={() =>
                                                  filtter(
                                                    locale === "en-US"
                                                      ? section.category
                                                          .english +
                                                          "." +
                                                          section.id
                                                      : locale === "ku"
                                                      ? section.category
                                                          .kurdish +
                                                        "." +
                                                        section.id
                                                      : locale === "ar"
                                                      ? section.category
                                                          .arabic +
                                                        "." +
                                                        section.id
                                                      : null
                                                  )
                                                }
                                                id={`${section.id}-heading`}
                                                className={` text-gray-900 cursor-pointer   duration-300 inline  hover:text-white  hover:bg-gradient-to-br from-teal-500 to-teal-600 max-h-fit rounded-md hover:p-1  ${locale}`}
                                              >
                                                <span>
                                                  <img
                                                    className="object-contain w-full mx-auto rounded-xl"
                                                    alt="category"
                                                    src={section.imageUrl}
                                                  />
                                                </span>
                                                {locale === "en-US" &&
                                                  section.category.english}
                                                {locale === "ar" &&
                                                  section.category.arabic}
                                                {locale === "ku" &&
                                                  section.category.kurdish}
                                              </button>
                                            </span>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    ))}

                  {navigation.pages.map((page) => (
                    <Link
                      key={page.name}
                      to={page.to}
                      className={`flex items-center font-medium text-gray-700 text-md  hover:text-teal-600 duration-500 ${locale}`}
                    >
                      {t(page.name)}
                    </Link>
                  ))}
                </div>
              </Popover.Group>

              <div
                className={`flex items-center  ${
                  locale === "en-US" ? "ml-auto " : "mr-auto"
                }`}
              >
                <div
                  className={`hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6 ${locale} ${
                    locale === "en-US" ? "ml-3" : "mr"
                  }`}
                >
                  {/* <Link
                    to="/login"
                    className="font-medium text-gray-700 text-md hover:text-gray-800"
                  >
                    {t("Sign in")}
                  </Link> */}
                  <span className="w-px h-6 bg-gray-200" aria-hidden="true" />
                  <Link
                    to="/register"
                    className={`font-medium text-gray-700 text-md  hover:text-teal-600 duration-500 ${locale}`}
                  >
                    {t("createAccount")}
                  </Link>
                </div>

                <div className="hidden lg:ml-8 lg:flex">
                  {/* <Link
                    to="#"
                    className="flex items-center text-gray-700 hover:text-gray-800"
                  >
                    <img
                      src={USA}
                      alt=""
                      className="flex-shrink-0 block w-5 h-auto"
                    />
                    <span className="block ml-3 font-medium text-gray-800 text-md">
                      En
                    </span>
                    <span className="sr-only">, change currency</span>
                  </Link> */}
                  {isMediumScreen || (isSmallScreen && <Locale />)}
                </div>

                {/* Search */}
                <div className={`flex lg:ml-6`}>
                  {/* {locale === 'en' && <img  src={languages[0]?.image} className="inline w-6 mx-1 rounded-md" />} */}
                  {/* {locale === "ar" && (
                    <img
                      src={languages[1]?.image}
                      className="inline w-6 mx-1 rounded-md md:hidden"
                    />
                  )}
                  {locale === "ku" && (
                    <img
                      src={languages[0]?.image}
                      className="inline w-6 mx-1 rounded-md md:hidden"
                    />
                  )} */}
                  <Locale />

                  <Account
                    className={`p-2 text-gray-400 hover:text-teal-600 duration-500 sm:hidden lg:visible ${locale}`}
                  />

                  {/* <MobileLocale /> */}
                  {/* <Link
                    to="/shop"
                    className={`p-2 text-gray-400 hover:text-gray-500 ${locale}`}
                  >
                    <span className="sr-only">{t("search")}</span>
                    <MagnifyingGlassIcon
                      className="w-6 h-6"
                      aria-hidden="true"
                    />
                  </Link> */}
                  {/* <Link
                    to={isLogedIn ? "/user" : "/login"}
                    className={`p-2 text-gray-400 hover:text-gray-500 ${locale}`}
                  >
                    <span className="sr-only">{t("search")}</span>
                    <UserCircleIcon className="w-6 h-6" aria-hidden="true" />
                  </Link> */}
                </div>

                {/* Cart */}
                {isLogedIn && (
                  <div
                    className={`flow-root ml-4 lg:ml-6 ${
                      isLogedIn === true ? "" : "hidden"
                    } `}
                  >
                    <button
                      onClick={() => setShopSideBar(true)}
                      className={`flex items-center p-2 -m-2 group hover:text-teal-600 duration-500 group ${
                        isLogedIn === true ? "" : "hidden"
                      } `}
                    >
                      {/* <ShoppingBagIcon
                        className="flex-shrink-0 w-6 h-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      /> */}
                      <span className="relative inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 text-gray-500 duration-500 group-hover:text-teal-600"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                          />
                        </svg>

                        <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-teal-500 rounded-full">
                          {basket > 0 ? basket : 0}
                        </span>
                      </span>

                      {/* <span className="inline-flex items-center justify-center w-6 h-6 -ml-1 text-xs font-semibold text-teal-800 bg-teal-200 rounded-full -z-10">
                        2
                      </span> */}

                      {/* <span className="px-2 py-1 ml-2 font-medium text-white bg-teal-500 rounded-full text-md group-hover:text-gray-800">
                        3
                      </span> */}

                      <span className={`sr-only ${locale}`}>
                        {t("itemsincart,viewbag")}
                      </span>
                    </button>
                  </div>
                )}
                {isLogedIn && (
                  <Chatpopup className="duration-500 " data-aos="fade-up" />
                )}
              </div>
              {ShopSideBar && (
                <ShopSideBarComponent
                  Title={"Cart Item"}
                  open={ShopSideBar}
                  setOpen={setShopSideBar}
                />
              )}
            </div>
          </div>
        </nav>
        <ToTopScroll />
      </header>
    </div>
  );
}
