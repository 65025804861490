import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getChatUsers = createAsyncThunk(
  "getChatUsers",
  async (object, { getState, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get('dashboardToken')}`,
        },
      };
      const { data } = await axios.get(
        "api/Messages/GetUsersWithMessage",
        config
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    copyData: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    searchFilter: (state, action) => {
      state.copyData = state.data.filter((item) =>
        item.fullName.toLowerCase().includes(action.payload)
      );
    },
    filterStatus: (state, action) => {
      if (action.payload === "All") {
        state.copyData = state.data.filter((item) =>
          item.seenMessages === true || item.seenMessages === false
        );
      } else {
        state.copyData = state.data.filter(
          (item) => item.seenMessages === action.payload
        );
      }
    },
    filterDate: (state, action) => {
      const startDate = new Date(action.payload[0]);
      const endDate = new Date(action.payload[1]);

      state.copyData = state.data.filter((item) => {
        const itemDate = new Date(item?.lastDate);
        return itemDate >= startDate && itemDate <= endDate;
      });
    },

    resetFilter: (state) => {
      state.copyData = state.data;
    },
  },
  extraReducers: {
    [getChatUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getChatUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getChatUsers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});

export const {
  searchFilter,
  resetFilter,
  filterStatus,
  filterDate,
} = loremSlice.actions;
export default loremSlice;
