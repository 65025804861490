import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  MapPinIcon,
  PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
// import Toast from "../../components/Cards/Toast;
import { getNews } from "../../../features/Global/News";
import { useDispatch } from "react-redux";
import { TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAddresses } from "../../../features/Global/Address";
import verify from "../../assets/icons/Verify.svg";
import NoOrder from "../../assets/icons/NoOrder.svg";
import Cookies from "js-cookie";

export default function SlideOver({
  deleteAccount,
  open,
  setOpen,
  chilren,
  user,
}) {
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   setValue,
  //   formState: { errors },
  // } = useForm();
  // useEffect(() => {
  //   setValue("addressTitle", id.addressTitle);
  //   setValue("city", id.city);
  //   setValue("streetName", id.streetName);
  //   setValue("phoneNumber", id.phoneNumber);
  //   setValue("buildingName", id.buildingName);
  //   setValue("buildingFloor", id.buildingFloor);
  //   setValue("appartmentNumber", id.appartmentNumber);
  //   setValue("detailedAdderess", id.detailedAdderess);
  // }, []);
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${Cookies.get('webAuth')}`,
  //   },
  // };
  // const onSubmit = (data) => {
  //   data.oldId = id?.id;

  //   axios
  //     .put("api/OrderAddresses", data, config)
  //     .then((r) => {
  //       // console.log(r);
  //       if (r.status === 200 || r.status === 204) {
  //         setToast(true);
  //         // console.log(r);
  //         dispatch(getAddresses());
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.response.status);
  //       console.log(e.response.data);
  //       setMessage(e.response.data);
  //       setError(true);
  //     });
  //   setToast(false);
  // };
  var sum = 0;

  const handleSum = (data) => {
    sum = data?.reduce((a, v) => (a = a + v.currentPrice), 0);
    return sum;
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="py-10 text-center text-white bg-gradient-to-br from-teal-600 to-teal-500">
                  <p className={`${l} text-2xl font-semibold`}>
                    {/* <MapPinIcon className="inline mx-2 w-7 " />{" "} */}
                    {t("deleteAccount")}
                  </p>
                </div>
                <div className="flex flex-col items-stretch justify-center w-full space-y-4 md:flex-row md:space-y-0 md:space-x-6 xl:space-x-8">
                  {user?.isPhoneConfirmed === true ? (
                    <>
                      {deleteAccount?.length > 0 ? (
                        <>
                          {" "}
                          <div className="flex flex-col w-full px-4 py-6 space-y-6 md:p-6 xl:p-8 bg-gray-50 dark:bg-gray-800">
                            {/* <h3
                              className={`${l} text-xl dark:text-white font-semibold leading-5 text-gray-800`}
                            >
                              {t("deleteAccount")}
                            </h3> */}
                            <div className="flex flex-col items-center justify-center w-full pb-4 space-y-4 border-b border-gray-200">
                              {deleteAccount?.map((item) => (
                                <div className="flex justify-between w-full">
                                  <p
                                    className={`text-base dark:text-white leading-4 text-gray-800 ${l}`}
                                  >
                                    {l === "en-US" &&
                                      item?.productName?.english}
                                    {l === "ku" && item?.productName?.kurdish}
                                    {l === "ar" && item?.productName?.arabic}
                                  </p>
                                  <span className="items-center inline text-sm text-center">
                                    X{item?.qty}
                                  </span>
                                  <p
                                    className={`text-base dark:text-gray-300 leading-4 text-gray-600 ${l}`}
                                  >
                                    {Intl.NumberFormat()?.format(
                                      item?.currentPrice
                                    )}
                                    {t("iqd")}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="flex items-center justify-between w-full">
                              <p
                                className={`text-base dark:text-white font-semibold leading-4 text-gray-800 ${l}`}
                              >
                                {t("total")}
                              </p>
                              <p
                                className={`text-base dark:text-gray-300 font-semibold leading-4 text-gray-600 ${l}`}
                              >
                                {Intl.NumberFormat()?.format(
                                  handleSum(deleteAccount)
                                )}{" "}
                                {t("iqd")}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex flex-col w-full px-4 py-6 space-y-6 md:p-6 xl:p-8 bg-gray-50 dark:bg-gray-800">
                            <img src={NoOrder} className="w-4/5 mx-auto" />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className={`flex mx-auto ${
                          user?.isPhoneConfirmed === true ? "" : "hidden"
                        }`}
                      >
                        <h3
                          className={`${l} text-xl dark:text-white font-semibold leading-5 text-gray-800`}
                        >
                          {t("noOrdersFound")}
                        </h3>
                        <img src={verify} className="inline mx-auto " />
                      </div>{" "}
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
