import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Reciept from "./Reciept";
import {
  ChevronDoubleRightIcon,
  ChevronRightIcon,
  EyeIcon,
  MapPinIcon,
  PhoneArrowUpRightIcon,
  PrinterIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import axios from "axios";
// redux mapping
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, id }) {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  useEffect(() => {
    axios
      .get(`api/Orders/GetOrderItemsDashboard/${id}`, config)
      .then((r) => {
        setData(r.data);
      })
      .catch((e) => console.log(e));
  }, [id]);

  const handleCheckOfferTypes = (value) => {
    if (value === 0) {
      return "No Offer";
    }
    if (value === 1) {
      return "Buy one get one";
    }
    if (value === 2) {
      return "Percentage discount";
    } else {
      return "Buy one get some percentage of";
    }
  };
  const handleModal = () => {
    setModal(true);
  };
  const handleOrderTypes = (type) => {
    if (type === 0) {
      return "Pending";
    } else if (type === 1) {
      return "Accepted";
    } else if (type === 2) {
      return "Canceled";
    } else if (type === 3) {
      return "Waiting for FIB payment";
    } else if (type === 4) {
      return "FIB payment declined";
    }else{
      return "Pending";
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-lg pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-orange-600 rounded-sm sm:px-6 dark:bg-orange-600">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <EyeIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    <div className="relative flex-1">
                      {/* Replace with your content */}
                      <div className="absolute inset-0">
                        {/* <div className="h-full border-2 border-gray-200 border-dashed" aria-hidden="true" > */}
                        {/* <img
                          src={data?.imageUrl}
                          className="h-56 mx-auto w-fit"
                        /> */}
                        <div className="p-3 m-3">
                          {/* {data?.map((item, index) => (
                            <> */}
                          <div className="flex flex-row py-1">
                            <div className="flex-1">
                              <p className="font-semibold text-gray-600">
                                <UserCircleIcon className="inline w-5 text-teal-500" />{" "}
                                {data?.user?.firstName +
                                  " " +
                                  data?.user?.secondName +
                                  " " +
                                  data?.user?.thirdName}
                                <span
                                  className="inline float-right ml-auto duration-500 hover:scale-x-110 hover:scale-105"
                                  onClick={() => setModal(true)}
                                >
                                  <button
                                    className="px-3 py-1 bg-teal-500 rounded-lg "
                                    onClick={() => setModal(true)}
                                  >
                                    <PrinterIcon
                                      className="inline w-6 text-white"
                                      onClick={() => setModal(true)}
                                    />
                                  </button>
                                </span>
                              </p>
                              <p className="my-2 font-semibold text-gray-600 ">
                                <PhoneArrowUpRightIcon className="inline w-5 text-teal-500" />{" "}
                                0{data?.user?.phoneNumber}
                              </p>
                              <p className="font-medium text-gray-600 ">
                                <MapPinIcon className="inline w-5 text-teal-500 " />{" "}
                                Address
                                <ChevronDoubleRightIcon className="inline w-4 font-bold text-gray-400" />{" "}
                                {data?.orderItems?.length > 0 &&
                                  data?.orderItems[0]?.address}{" "}
                              </p>
                            </div>
                            <div className="mt-auto">
                              {/* <span>
                                {" "}
                                {data?.offerType === 2 ? (
                                  <>{data?.priceAfterDiscount}</>
                                ) : (
                                  <>{data[0]?.price}</>
                                )}{" "}
                                IQD{" "}
                              </span> */}
                            </div>
                          </div>
                          {/* </>
                          ))} */}
                          {/* <div className="flex flex-row my-3">
                            <button className="border border-indigo-600 bg-indigo-600 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-indigo-700 focus:outline-none focus:shadow-outline w-1/2">
                              Details
                            </button>
                            <button className="border border-gray-300 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline w-1/2">
                              Details
                            </button>
                          </div> */}
                          <div className="border-b-[1.3px] py-3 border-gray-200">
                            <div className="flex flex-row justify-between my-2">
                              <p className="font-semibold text-gray-400 ">
                                Order Status{" "}
                              </p>
                              <p className="items-center px-3 py-1 text-gray-900 bg-blue-100 border border-blue-600 rounded-md ">
                                <span className="items-center mx-auto font-medium text-blue-600">
                                  {data?.orderItems?.[0]?.status &&
                                    handleOrderTypes(data?.orderItems[0]?.status)}
                                </span>
                              </p>
                            </div>
                          </div>
                          {/* {data?.length > 0 && ( */}
                          <>
                            {data?.orderItems?.map((item) => (
                              <div className="border-b-[1.3px] py-3 border-gray-200 my-1">
                                <div className="flex flex-row justify-around my-3">
                                  <div className="flex flex-col my-1 font-medium text-gray-500">
                                    <img
                                      src={item?.imageUrl}
                                      className="inline w-24 rounded-full "
                                    />
                                  </div>
                                  {/* <div className="flex flex-col my-1 font-medium text-gray-500">
                                    Porducts
                                    <span className="mx-1 text-sm font-medium">
                                      Price
                                    </span>
                                    <span className="mx-1 text-sm font-medium">
                                      Quantity
                                    </span>
                                  </div> */}
                                  <div className="flex flex-col font-semibold text-gray-900">
                                    {item?.productName?.english}{" "}
                                    <span className="mx-1 my-2 text-sm">
                                      {Intl?.NumberFormat()?.format(
                                        item?.currentPrice
                                      )}{" "}
                                      IQD
                                    </span>
                                    <span className="mx-1 text-sm">
                                      X {item?.qty}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                          {/* )} */}
                          {modal && (
                            <Reciept
                              data={data}
                              open={modal}
                              onClose={setModal}
                            />
                          )}
                        </div>
                      </div>

                      {/* </div> */}
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
