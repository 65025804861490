import React from "react";
import BreadCrumb from "../componets/BreadCrumb";
import CopyWrite from "../componets/CopyWrite";
import { useParams } from "react-router-dom";
// Componetns
import Footer from "../componets/Footer";
import Header from "../componets/Header";
import Navbar from "../componets/Navbar";
import ShopSideBar from "../componets/shop/ShopSideBarFind";
import Products from "../componets/shop/Products";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";

const Shop = () => {
  const params = useParams();
  // console.log('in shop',params.id);
  return (
    <div>
      {/* <Header /> */}
      <Navbar />

      {/* <!-- shop wrapper --> */}
      <div className="grid items-start gap-0 pt-4 pb-16 md:gap-6">
        {/* <!-- breadcrumb --> */}
        {/* <!-- ./breadcrumb --> */}
        {/* <!-- sidebar --> */}
        <ShopSideBar id={params.id}/>
        {/* <!-- ./sidebar --> */}
        {/* <!-- products --> */}
        {/* <Products /> */}
        {/* <!-- ./products --> */}
      </div>
      {/* <!-- ./shop wrapper --> */}

      {/* <!-- footer --> */}
      <Footer />
      {/* <!-- ./footer --> */}

      {/* <!-- copyright --> */}
      <CopyWrite />
      {/* <!-- ./copyright --> */}
    </div>
  );
};

export default Shop;
