import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getSubCategory } from "../../../features/Global/SubCategory";
import { getBrand } from "../../../features/Global/Dashboard/Brands";
import { getCategory } from "../../../features/Global/Dashboard/Category";
import { getItem } from "../../../features/Global/Dashboard/Items";

import { Autocomplete, TextField, TextareaAutosize } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export default function SlideOver({ Title, open, setOpen, chilren, id }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [brandId, setBrandId] = useState("");
  const [englishVoice, setEnglishVoice] = useState("");
  const [arabicVoice, setArabicVoice] = useState("");
  const [KurdishVoice, setKurdishVoice] = useState("");
  const [isApplicableForOffers, setIsApplicableForOffers] = useState();
  const [isOfferActive, setIsOfferActive] = useState();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.categoryDashboard.data);
  const subCategory = useSelector((state) => state.SubCategory.copyData);
  const brand = useSelector((state) => state.BrandsDashboard.ordered);
  const [englishTags, setEnglishTags] = useState("");
  const [arabicTags, setArabicTags] = useState("");
  const [kurdishTags, setKurdishTags] = useState("");
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [englishDescription, setEnglishDescription] = useState("");
  const [arabicDescription, setArabicDescription] = useState("");
  const [kurdishDescription, setKurdishDescription] = useState("");
  const [englishUsage, setEnglishUsage] = useState("");
  const [arabicUsage, setArabicUsage] = useState("");
  const [kurdishUsage, setKurdishUsage] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [subcategoryId, setSubCategoryId] = useState(null);

  const handleFileSelect = (e) => {
    if (e.target.files[0].type.startsWith("image/")) {
      setFile(e.target.files[0]);
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(objectUrl);
    } else {
      setMessage("Please select an image!");
      setError(true);
    }
  };
  useEffect(() => {
    dispatch(getSubCategory());
    dispatch(getCategory());
    dispatch(getBrand());
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
      ContentType: "multipart/form-data",
    },
  };
  useEffect(() => {
    axios.get(`api/Products/${id}`).then((r) => {
      setCategoryId(r.data.categoryId);
      setBrandId(r.data.brandId);
      setValue("englishName", r.data.name.english);
      setValue("arabicName", r.data.name.arabic);
      setValue("kurdishName", r.data.name.kurdish);
      setValue("englishDescription", r.data.description.english);
      setEnglishDescription(r.data.description.english);
      setValue("arabicDescription", r.data.description.arabic);
      setArabicDescription(r.data.description.arabic);
      setValue("kurdishDescription", r.data.description.kurdish);
      setKurdishDescription(r.data.description.kurdish);
      setValue("englishUsage", r.data.usage.english);
      setEnglishUsage(r.data.usage?.english);
      setValue("arabicUsage", r.data.usage.arabic);
      setArabicUsage(r.data.usage?.arabic);
      setValue("kurdishUsage", r.data.usage.kurdish);
      setKurdishUsage(r.data.usage?.kurdish);
      setValue("SubCategoryId", r.data.subCategoryId);
      setSubCategoryId(r.data.subCategoryId);
      setValue("brandId", r.data.brandId);
      setValue("index", r.data.index);
      setValue("offerType", r.data.offerType);
      setValue("qty", r.data.qty);
      setValue("price", r.data.price);
      setValue("isOfferActive", r.data.isOfferActive);
      setIsOfferActive(r.data?.isOfferActive);
      setValue("isApplicableForOffers", r.data.isApplicableForOffers);
      setValue("barcode", r.data.barcode);
      // setValue("tags", r.data.tags);
      const myArray = r.data.tags.split("|");
      // console.log(myArray);
      setEnglishTags(myArray[0].trim());
      setArabicTags(myArray[1].trim());
      setKurdishTags(myArray[2].trim());
    });
  }, [id]);
  const onSubmit = (data) => {
    setDisable(true);
    var form = new FormData();
    form.append("EnglishName", data.englishName || "empty");
    form.append("KurdishName", data.kurdishName || "زیادنەکراوە");
    form.append("ArabicName", data.arabicName || "فارغة");
    form.append("EnglishDescription", englishDescription || "empty");
    form.append("KurdishDescription", kurdishDescription || "زیادنەکراوە");
    form.append("ArabicDescription", arabicDescription || "فارغة");
    form.append("EnglishUsage", englishUsage || "empty");
    form.append("KurdishUsage", kurdishUsage || "زیادنەکراوە");
    form.append("ArabicUsage", arabicUsage || "فارغة");
    // form.append("SubCategoryId", data.SubCategoryId);
    form.append("SubCategoryId", subcategoryId);

    form.append("BrandId", brandId);
    form.append("Index", data.index);
    form.append("Qty", data.qty);
    form.append("Price", data.price);
    form.append("tags", `${englishTags} | ${arabicTags} | ${kurdishTags}`);
    form.append("EnglishVoice", englishVoice);
    form.append("KurdishVoice", KurdishVoice);
    form.append("ArabicVoice", arabicVoice);
    form.append("id", id);
    form.append("OfferType", data.offerType);
    form.append("Barcode", data.barcode);
    form.append("isOfferActive", data.isOfferActive);
    form.append("isApplicableForOffers", data.isApplicableForOffers);
    axios
      .put("api/Products", form, config)
      .then((r) => {
        // console.log(r);
        setDisable(false);
        if (r.status === 200) {
          setToast(true);
          dispatch(getItem());
        }
      })
      .catch((e) => {
        console.log(e);
        setDisable(false);
        setMessage(e.response?.data);
        setError(true);
      });
  };

  const handleChange = (event) => {
    setBrandId(event.target.value);
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setCategoryId(categoryId);
    const subCategories = subCategory.filter(
      (item) => parseInt(item?.categoryId) === parseInt(categoryId)
    );
    if (subCategories.length > 0) {
      setSubCategoryId(subCategories[0].id);
    } else {
      setSubCategoryId(null);
    }
  };

  const handleSubCategoryChange = (e) => {
    setSubCategoryId(e.target.value);
  };
  const formats = {
    align: "",
    bold: "",
    italic: "",
    underline: "",
    strike: "",
    size: "",
    font: "",
    color: "",
    background: "",
    script: "",
    header: "",
    indent: "",
    list: "",
    direction: "",
    code: "",
    blockquote: "",
    link: "",
    image: "",
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      ["link"],
      ["clean"],
      [{ direction: "rtl" }],
    ],
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-2xl pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-2xl pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-blue-600 rounded-sm sm:px-6 dark:bg-blue-700">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PencilIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    {/* <hr className="mx-1 text-gray-300 dark:text-blue-700" /> */}
                    <div className="relative flex-1 px-4 mt-2 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name english"
                                  {...register("englishName", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name arabic"
                                  {...register("arabicName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  style={{ unicodeBidi: "plaintext" }}
                                  className={`bg-gray-50 rtl text-right border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name kurdish"
                                  {...register("kurdishName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  style={{ unicodeBidi: "plaintext" }}
                                  className={`bg-gray-50 rtl border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              {/* Descrition */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Description:
                                </label>

                                <ReactQuill
                                  theme="snow"
                                  value={englishDescription}
                                  onChange={setEnglishDescription}
                                  modules={modules}
                                  formats={formats}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Description:
                                </label>

                                <ReactQuill
                                  theme="snow"
                                  value={arabicDescription}
                                  onChange={setArabicDescription}
                                  modules={modules}
                                  formats={formats}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Description:
                                </label>

                                <ReactQuill
                                  theme="snow"
                                  value={kurdishDescription}
                                  onChange={setKurdishDescription}
                                  modules={modules}
                                  formats={formats}
                                />
                              </div>
                              {/* Description */}
                              {/* Usage */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Usage:
                                </label>

                                <ReactQuill
                                  theme="snow"
                                  value={englishUsage}
                                  onChange={setEnglishUsage}
                                  modules={modules}
                                  formats={formats}
                                  style={{ unicodeBidi: "plaintext" }}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Usage:
                                </label>

                                <ReactQuill
                                  theme="snow"
                                  value={arabicUsage}
                                  onChange={setArabicUsage}
                                  modules={modules}
                                  formats={formats}
                                  style={{ unicodeBidi: "plaintext" }}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Usage:
                                </label>

                                <ReactQuill
                                  theme="snow"
                                  value={kurdishUsage}
                                  onChange={setKurdishUsage}
                                  modules={modules}
                                  formats={formats}
                                  style={{ unicodeBidi: "plaintext" }}

                                />
                              </div>
                              {/* Tags */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Tags:
                                </label>
                                <input
                                  value={englishTags}
                                  type="text"
                                  placeholder="product tags in english"
                                  onChange={(e) =>
                                    setEnglishTags(e.target.value)
                                  }
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.tags && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Tags:
                                </label>
                                <input
                                  type="text"
                                  value={arabicTags}
                                  onChange={(e) =>
                                    setArabicTags(e.target.value)
                                  }
                                  placeholder="product tags arabic"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Tags:
                                </label>
                                <input
                                  value={kurdishTags}
                                  type="text"
                                  onChange={(e) =>
                                    setKurdishTags(e.target.value)
                                  }
                                  placeholder="product tags arabic"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              {/* End-Tags */}
                              {/* Numerics */}
                              {/* <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Category
                                </label>
                                <select
                                  value={categoryId}
                                  defaultChecked={categoryId}
                                  onChange={(e) =>
                                    setCategoryId(e.target.value)
                                  }
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.SubCategoryId && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {category?.map((item) => (
                                    <option
                                      // selected={categoryId}
                                      value={item.id}
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                        errors.SubCategoryId &&
                                        "border-rose-500"
                                      } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {item.category.english}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Sub Category
                                </label>
                                <select
                                  value={subcategoryId}
                                  defaultChecked={subcategoryId}
                                  onChange={(e) =>
                                    setSubCategoryId(e.target.value)
                                  }
                                  // {...register("SubCategoryId")}
                                  placeholder="Select subcategory"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.SubCategoryId && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {subCategory
                                    ?.filter(
                                      (item) =>
                                        categoryId === null ||
                                        parseInt(item?.categoryId) ===
                                          parseInt(categoryId)
                                    )
                                    ?.map((item) => (
                                      <option
                                        value={item.id}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                          errors.SubCategoryId &&
                                          "border-rose-500"
                                        } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                      >
                                        {item.englishSubCategory}
                                      </option>
                                    ))}
                                </select>
                              </div> */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Category
                                </label>
                                <select
                                  value={categoryId}
                                  onChange={handleCategoryChange}
                                  placeholder="Select category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {category?.map((item, index) => (
                                    <option
                                      // selected={categoryId}
                                      value={item.id}
                                      key={index}
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                        errors.SubCategoryId &&
                                        "border-rose-500"
                                      } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {item.category.english}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Sub Category
                                </label>
                                <select
                                  value={subcategoryId}
                                  required
                                  onChange={handleSubCategoryChange}
                                  placeholder="Select subcategory"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {subCategory
                                    ?.filter(
                                      (item) =>
                                        categoryId === null ||
                                        parseInt(item?.categoryId) ===
                                          parseInt(categoryId)
                                    )
                                    ?.map((item) => (
                                      <option
                                        key={item.id}
                                        value={item.id}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                      >
                                        {item.englishSubCategory}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Brand
                                </label>
                                <select
                                  {...register("brandId")}
                                  onChange={(e) => handleChange(e)}
                                  value={brandId} // Set the value prop to brandId
                                  defaultChecked={brandId}
                                  defaultValue={brandId}
                                  placeholder="Select Brand"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.brandId && "border-rose-500"
                                  } bg-white py-2 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {brand?.map((item) => (
                                    <option
                                      key={item.id}
                                      value={item.id}
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                        errors.brandId && "border-rose-500"
                                      } bg-white py-2 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {item.name.english}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* <Autocomplete
                                  id="brand-select"
                                  value={brand.find(
                                    (option) => option.id === brandId
                                  )}
                                  options={brand}
                                  getOptionLabel={(option) =>
                                    option.name.english
                                  }
                                  getOptionSelected={(option, value) =>
                                    option.name.english === value.name.english
                                  }
                                  onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                      handleChange({
                                        target: {
                                          value: newValue.id,
                                          name: "brandId",
                                        },
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Brand"
                                      variant="outlined"
                                      placeholder="Select Brand"
                                      error={errors.brandId}
                                      helperText={
                                        errors.brandId
                                          ? errors.brandId.message
                                          : null
                                      }
                                    />
                                  )}
                                /> */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Offer Type
                                </label>
                                <select
                                  {...register("offerType")}
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.offerType && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  <option
                                    value={0}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    No Offer
                                  </option>
                                  <option
                                    value={1}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Buy one get one
                                  </option>{" "}
                                  <option
                                    value={2}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Percentage
                                  </option>
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Offer Status
                                </label>
                                <select
                                  {...register("isOfferActive", {
                                    required: false,
                                  })}
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.isOfferActive && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  <option
                                    value={false}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.isOfferActive && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    In Active
                                  </option>
                                  <option
                                    value={true}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Active
                                  </option>{" "}
                                </select>
                              </div>
                              {/*  */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Item Applicable For Offers
                                </label>
                                <select
                                  {...register("isApplicableForOffers", {
                                    required: false,
                                  })}
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.isApplicableForOffers &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  <option
                                    value={"false"}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.isApplicableForOffers &&
                                      "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Not Applicable
                                  </option>
                                  <option
                                    value={"true"}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.isApplicableForOffers &&
                                      "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Applicable
                                  </option>{" "}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Price:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Price"
                                  {...register("price", {
                                    required: true,
                                    type: Number,
                                    pattern: /[0-9]/,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.price && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Barcode:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Barcode"
                                  {...register("barcode", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.barcode && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Quantity:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Quantity"
                                  {...register("qty", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Index:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Index"
                                  {...register("index", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.index && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Voice:
                                </label>
                                <input
                                  type="file"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    setEnglishVoice(e.target.files[0])
                                  }
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Voice:
                                </label>
                                <input
                                  type="file"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    setArabicVoice(e.target.files[0])
                                  }
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Voice:
                                </label>
                                <input
                                  type="file"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    setKurdishVoice(e.target.files[0])
                                  }
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="float-right pt-2 pb-4">
                                <button
                                  type="submit"
                                  disabled={disable}
                                  className={
                                    "bg-blue-100 mx-1 px-6 py-2 rounded-md text-blue-600 hover:bg-blue-200 hover:text-blue-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:text-blue-100 dark:bg-blue-600"
                                  }
                                  // {...props}
                                >
                                  <PencilIcon className="inline w-6 h-6 mx-2" />
                                  Update
                                  {/* {children} */}
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-rose-100 mx-1 px-4 py-2 rounded-md text-rose-600 hover:bg-rose-200 hover:text-rose-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                                  }
                                  // {...props}
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                  {/* {children} */}
                                </button>
                              </div>
                              <br />
                            </form>
                            {toast && (
                              <Toast
                                Text={"Product Update Successfully"}
                                Type={"blue"}
                                setOpen={setToast}
                              />
                            )}
                            {error && (
                              <Toast
                                Text={message}
                                Type={"rose"}
                                setOpen={setError}
                              />
                            )}
                          </div>
                        </div>

                        {/* <div className=F"h-full border-2 border-gray-200 border-dashed" aria-hidden="true" /> */}
                      </div>
                      {/* {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
