import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import Reciept from "./Reciept";
import {
  ChevronRightIcon,
  EyeIcon,
  MapPinIcon,
  PrinterIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import axios from "axios";
import Parser from "html-react-parser";
// redux mapping
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, id }) {
  // const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  // useEffect(() => {
  //   axios
  //     .get(
  //       `api/controlPane/SubCategoryProducts/getSubcategoryProducts/${id}`,
  //       config
  //     )
  //     .then((r) => {
  //       console.log(r.data);
  //       setData(r.data);
  //     })
  //     .catch((e) => console.log(e));
  // }, [id]);

  console.log(id);
  const handleModal = () => {
    setModal(true);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-2xl pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-2xl pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-orange-600 rounded-sm sm:px-6 dark:bg-orange-600">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <EyeIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    <div className="relative flex-1">
                      {/* Replace with your content */}
                      <div className="absolute inset-0">
                        {/* <div className="h-full border-2 border-gray-200 border-dashed" aria-hidden="true" > */}
                        {/* <img
                          src={data?.imageUrl}
                          className="h-56 mx-auto w-fit"
                        /> */}
                        {/* <div className="p-3 m-3">
                          <div className="flex flex-row py-1">
                            <div className="flex-1">
                              <p className="text-xl font-bold ">
                                {id?.qestion.length > 0 && <>{id?.qestion}</>}
                              </p>
                            </div>
                            <div className="mt-auto"></div>
                          </div>

                          <div className="border-b-[1.3px] py-3 border-gray-200 my-1">
                            <div className="flex flex-row justify-between my-3">
                              <div className="flex flex-col my-1 font-medium text-gray-500">
                                {id?.answer.length > 0 && (
                                  <>{Parser(id?.answer)}</>
                                )}{" "}
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="px-3 py-2 m-2 bg-gray-100 rounded-md">
                          <div className="flex items-start">
                            <div>
                              <span className="inline-flex items-center justify-center w-6 h-6 text-sm font-medium text-white bg-teal-500 rounded">
                                Q
                              </span>
                            </div>

                            <p className="ml-4 font-semibold md:ml-6">
                              {id?.qestion.length > 0 && <>{id?.qestion}</>}{" "}
                            </p>
                          </div>

                          <div className="flex items-end justify-end mt-4">

                            <p className="text-gray-800 md:ml-6 ku">
                              {id?.answer.length > 0 && (
                                <>{Parser(id?.answer)}</>
                              )}{" "}
                            </p>
                            <div className="ml-4">
                              <span className="inline-flex items-center justify-center w-6 h-6 text-sm font-medium text-gray-800 bg-gray-200 rounded">
                                A
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
