import React, { useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getMainReport } from '../../features/Global/MostSellingByCategorise';
import { useDispatch, useSelector } from 'react-redux';

export function AreaChart() {


    const dispatch = useDispatch();
    const database = useSelector((state) => state.MainReport.data)
    useEffect(() => {
        dispatch(getMainReport())
    }, [])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Most Selling products',
            },
        },
    };


    const labels = database?.map((item) => new Date(item?.orderDate).toLocaleString());
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Sales',
                data: database?.map((item) => item?.selledItem.number),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderWidth: 2,
                pointBackgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                fill: true,
                label: 'Total Ordered',
                data: database?.map((item) => item?.totalOrdered),
                borderColor: 'rgb(239, 71, 111)',
                backgroundColor: 'rgba(239, 71, 111, 0.5)',
                borderWidth: 2,
            },
            {
                fill: true,
                label: 'Quantity',
                data: database?.map((item) => item?.qty),
                borderColor: 'rgb(255, 103, 0)',
                backgroundColor: 'rgba(255, 103, 0, 0.5)',
                borderWidth: 2,
            },
            {
                fill: true,
                label: 'Total Price',
                data: database?.map((item) => item?.totalPrice),
                borderColor: 'rgb(142, 148, 242)',
                backgroundColor: 'rgba(142, 148, 242, 0.5)',
                borderWidth: 2,
            },
        ],
    };
    return <Line options={options} data={data} />;
}
