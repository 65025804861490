import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import usalogo from "../../../web/assets/icons/usa.svg";
import krlogo from "../../assets/images/flag/kurdistan.png";
import irlogo from "../../assets/images/flag/iraq.png";
import i18next, { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { LanguageIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

export default function Locale() {
  const rtlLanguages = ["ar", "ku"];
  const selected = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const locale = localStorage.getItem("i18nextLng");

  useEffect(() => {
    // document.body.dir = languages[selected].dir;
    setPageDirection(selected);
    if (locale === "ku") {
      Cookies.set("i18nextLng", "ku");
    }
    if (locale === "ar") {
      Cookies.set("i18nextLng", "ar");
    }
    if (locale === "en") {
      Cookies.set("i18nextLng", "en-US");
    }
  }, [selected]);
  function setPageDirection(selected) {
    const dir = rtlLanguages.includes(selected) ? "rtl" : "ltr";
    document.documentElement.dir = dir;
  }
  const languages = [
    {
      key: 1,
      code: "en-US",
      name: "English",
      country_code: "en-US",
      dir: "ltr",
      image: usalogo,
    },
    {
      key: 2,
      code: "ku",
      name: "کوردی (سۆرانی)",
      dir: "rtl",
      country_code: "ku",
      image: krlogo,
    },

    {
      key: 3,
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
      image: irlogo,
    },
  ];
  const changeLanguageCustom = (code) => {
    i18next.changeLanguage(code);
    // Cookies.set('i18nextLng', code);
    localStorage.setItem("i18nextLng", code);
  };
  return (
    <div className="flex items-center ml-auto">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-black bg-white rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75  hover:text-teal-600 duration-500 ${locale}`}
          >
            {locale === "en-US" && (
              <img
                src={languages[0]?.image}
                className="inline w-6 mx-1 rounded"
              />
            )}
            {locale === "ar" && (
              <img
                src={languages[2]?.image}
                className="inline w-6 mx-1 rounded"
              />
            )}
            {locale === "ku" && (
              <img
                src={languages[1]?.image}
                className="inline w-6 mx-1 rounded"
              />
            )}
            {t("languages")}
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1 text-gray-400 hover:text-teal-200"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="right-0 w-56 mt-2 text-black origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg lg:absolute ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {languages.map(({ code, name, country_code, image, index }) => (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        changeLanguageCustom(code);
                      }}
                      key={country_code}
                      className={`${
                        active ? "bg-teal-500 text-black" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm ${
                        code === "en-US" ? "" : locale
                      }`}
                    >
                      {active ? (
                        //   <EditActiveIcon
                        //     className="w-5 h-5 mr-2"
                        //     aria-hidden="true"
                        //   />
                        <img
                          src={image}
                          alt="flag image"
                          className="inline w-6 h-6 rounded-md"
                        />
                      ) : (
                        //   <EditInactiveIcon
                        //     className="w-5 h-5 mr-2"
                        //     aria-hidden="true"
                        //   />
                        <img
                          src={image}
                          alt="flag image"
                          className="inline w-6 h-6 rounded-md"
                        />
                      )}
                      {name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
