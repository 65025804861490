import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowDownOnSquareIcon,
  ArrowLongDownIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getCities } from "../../features/Global/Cities";
export default function MyModal() {
  let [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  function closeModal() {
    setIsOpen(false);
  }
  const locale = localStorage.getItem("i18nextLng");
  function openModal() {
    setIsOpen(true);
  }
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(Navigator.platform);
  };

  const isAndroid = () => {
    return /Android/.test(Navigator.platform);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 lg:hidden " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h2"
                    className="py-3 text-3xl font-extrabold leading-6 text-center text-transparent bg-gradient-to-r from-teal-500 to-teal-600 bg-clip-text"
                  >
                    <div className="my-2">
                      <span>
                        <ArrowDownOnSquareIcon className="w-10 p-1 mx-auto text-center text-teal-500 rounded-full bg-teal-50" />
                      </span>
                    </div>
                    <span className={`py-2 ${locale}`}>
                      
                    {t("downloadVaryApp")}
                    </span>
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className={`text-sm text-center text-gray-500 ${locale}`}>
                      {t("downloadedVaryAppDesc")}
                    </p>
                  </div>
                  <div className="mt-4">
                    <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                      {/* App Store */}
                      {isIOS && (
                        <>
                          <a
                            href="https://apps.apple.com/us/app/vary-pharmacy/id6448043911"
                            target="_blank"
                            className=" w-full sm:w-auto flex bg-gradient-to-br from-sky-500 to-blue-600 hover:scale-105 duration-500 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                          >
                            <svg
                              className={`${
                                locale === "en-US" ? "mr-3" : "ml-3"
                              } w-7 h-7`}
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fab"
                              data-icon="apple"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="currentColor"
                                d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                              ></path>
                            </svg>
                            <div
                              className={`${
                                locale === "en-US" ? "text-left" : "text-right"
                              }`}
                            >
                              <div className={`mb-1 text-xs ${locale}`}>
                                {t("varyOn")}
                              </div>
                              <div
                                className={`-mt-1 text-sm ${locale}`}
                              >
                                {t("appStore")}
                              </div>
                            </div>
                          </a>
                        </>
                      )}
                      {/* Play Store */}
                      {isAndroid && (
                        <>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.varypharmacy.vary_pharmacy"
                            target="_blank"
                            className="w-full sm:w-auto flex bg-gradient-to-br from-emerald-600 to-emerald-500 hover:scale-105 duration-500 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                          >
                            <svg
                              className={`${
                                locale === "en-US" ? "mr-3" : "ml-3"
                              } w-7 h-7`}
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fab"
                              data-icon="google-play"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                              ></path>
                            </svg>
                            <div
                              className={`${
                                locale === "en-US" ? "text-left" : "text-right"
                              }`}
                            >
                              <div className={`mb-1 text-xs ${locale}`}>
                                {t("varyOn")}
                              </div>
                              <div
                                className={`-mt-1  text-sm ${locale}`}
                              >
                                {t("playStore")}
                              </div>
                            </div>
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
