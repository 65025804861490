
import { createSlice,getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import Cookies from "js-cookie";
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})
const initialState = {
  value: "",
};

export const SidebarSlice = createSlice({
  name: "Sidebar",
  initialState,
  reducers: {
    Connect: (state) => {
      const connection = new HubConnectionBuilder()
        // .withUrl(`https://localhost:7247/chatHub`, {
        .withUrl(`https://varypharmacy.com/api/chatHub`, {
          accessTokenFactory: () => Cookies.get('dashboardToken'),
          UseDefaultCredentials: true,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      connection.logging = true;
      connection.start();
      state.value = connection;
    },
    // getSidebar: (state) => {
    //   return state.value;
    // },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { Connect, incrementByAmount } = SidebarSlice.actions;

export default SidebarSlice;
