import { Fragment } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";

import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { getUsers } from "../../../features/Global/Users";
import { t } from "i18next";
export default function SlideOver({ Title, open, setOpen, id }) {
  const [toast, setToast] = useState(false);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
      "Content-Type": "application/json",
    },
  };
  const handleDelete = (id) => {
    setDisable(true);
    axios
      .delete(`api/DashboardUsers/${id}`, config)
      .then((r) => {
        console.log(r);
        if (r.status === 200 || r.data.status === 204) {
          setToast(true);
          dispatch(getUsers());
          setMessage("User deleted successfully");
        }
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="py-10 text-center text-white bg-gradient-to-br from-rose-600 to-rose-500">
                  <p className={` text-2xl font-semibold`}>
                    <TrashIcon className="inline mx-2 w-7 " /> Delete User
                    Account
                  </p>
                </div>
                <form
                  className="px-8 py-8 space-y-2"
                  // onSubmit={handleDelete()}
                >
                  <div className="my-2 antialiased text-gray-600 ">
                    <div className="flex flex-col justify-center my-2">
                      <div className="w-full max-w-2xl mx-auto my-2 bg-white rounded-sm ">
                        <div className="w-full p-3">
                          <div className="overflow-x-auto">
                            <label className="block text-lg font-medium text-gray-700 ext-base text-2 dark:text-gray-300">
                              Are you sure to delete this account?
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleDelete(id)}
                      disabled={disable}
                      className="flex px-6 py-2 mx-1 mb-4 border border-transparent rounded-md bg-rose-100 text-rose-600 hover:bg-rose-200 hover:text-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                    >
                      <TrashIcon className={`inline w-6 mx-1`} /> Delete
                    </button>
                  </div>
                </form>
                {toast && (
                  <Toast
                    Text={"Order Status Updated Successfully"}
                    Type={"teal"}
                    setOpen={setToast}
                  />
                )}
                {error && (
                  <Toast
                    Text={"Something went wrong!"}
                    Type={"rose"}
                    setOpen={setError}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
