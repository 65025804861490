import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getNewArrival } from "../../../features/Global/NewArrival";
import { Link } from "react-router-dom";
import CustomDot from "../Basics/CustomDot";
import { motion } from "framer-motion";
import { GiftIcon, ReceiptPercentIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { getBasket } from "../../../features/web/ShopingCart";
import SnackBar from "./SnackBarDynamic";
import AddedSnackBar from "./AddedToBasketSnackBar";
import CheckAuth from "../../componets/CheckAuthModal/CheckAuth";
import Cookies from "js-cookie";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

// const CustomDot = ({ onClick, active, index, carouselState }) => {
//   const { currentSlide } = carouselState;
//   return (
//     <li style={{ background: active ? "grey" : "initial" }}>
//       <button
//         style={{ background: active ? "grey" : "initial" }}
//         onClick={() => onClick()}
//       />
//     </li>
//   );
// };

const NewArrival = () => {
  const isLogedIn = Cookies.get("webAuth")?.length > 0 ? true : false;
  const locale = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.NewArrival.data);
  const loading = useSelector((state) => state.NewArrival.loading);
  const isSuccess = useSelector((state) => state.NewArrival.isSuccess);
  const [checkLoginModal, setcheckLoginModal] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [AddedToBasket, setAddedToBasket] = useState(false);
  useEffect(() => {
    // dispatch(getNewArrival());
  }, []);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
    },
  };
  const handleSubmitOrder = (id) => {
    if (isLogedIn) {
      console.log(id);
      const data = {};
      data.productId = id;
      data.qty = 1;
      axios
        .post("api/BasketItems/AddAndRemoveFromBasket", data, config)
        .then((r) => {
          // console.log(r);
          setAddedToBasket(true);
          setTimeout(() => {
            setAddedToBasket(false);
          }, 4000);
          dispatch(getBasket());
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.status === 401) {
          }
          if (e.response.data === "not enough items in stock") {
            setSnackBar(true);

            setTimeout(() => {
              setSnackBar(false);
            }, 4000);
          }
        });
    } else {
      setcheckLoginModal(true);
    }
  };
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 4,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      rewind
      rewindWithAnimation={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={2}
      swipeable
      rtl={locale === "en-US" ? false : true}
    >
      {/* <div className="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 snap-x "> */}
      {isSuccess &&
        !loading &&
        data
          // .slice(0, 20)
          // ?.filter((item) => item.qty > 0)
          .map((item, index) => (
            <>
              <motion.div
                key={index}
                className="m-2 duration-500 bg-white shadow-md snap-center hover:scale-105 hover:shadow-xl rounded-xl"
              >
                {/* <Link to={`shop/product/${item.id}`}>
                <img
                  src={item.imageUrl}
                  alt="Product image"
                  className="object-cover w-full h-80 rounded-t-xl"
                />
              </Link> */}
                <Link
                  to={`/shop/product/${item.id}`}
                  replace={true}
                  relative={true}
                  className="relative flex mt-3 overflow-hidden h-60"
                >
                  {item.qty < 1 && (
                    <span
                      className={`absolute top-0 z-10 ${
                        locale === "en-US" ? "left-0" : "right-0"
                      } m-2 rounded-md bg-gradient-to-br from-rose-600 to-rose-500 px-2 text-center text-sm font-medium text-white ${locale}`}
                    >
                      {t("outOfStock")}
                    </span>
                  )}
                  <img
                    src={item?.imageUrl}
                    alt="Product image"
                    className="object-contain w-full duration-500 rounded-t-xl hover:rounded-t-xl hover:scale-110"
                  />
                </Link>
                <div className="w-auto px-4 py-3">
                  <span
                    className={`mr-3 text-xs text-gray-400 uppercase ${locale}`}
                  >
                    {locale === "en-US" && item.brand?.english}
                    {locale === "ar" && item.brand?.arabic}
                    {locale === "ku" && item.brand?.kurdish}
                  </span>
                  {item.isOfferActive && (
                    <span
                      className={` ${
                        locale === "en-US" ? "float-right" : "float-left"
                      }`}
                    >
                      <p>
                        {item.offerType === 1 && (
                          <GiftIcon className="inline w-5 mx-1 text-green-500" />
                        )}
                        {item.offerType === 2 && (
                          <>
                            <strong className="text-md">
                              {" "}
                              {item.percentageOf}%
                            </strong>
                            <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" />
                          </>
                        )}
                        {item.offerType === 3 && (
                          <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" />
                        )}
                      </p>
                    </span>
                  )}
                  <p
                    className={`block text-md font-bold text-black capitalize truncate overflow-hidden ${locale}`}
                  >
                    {locale === "en-US" && item.name?.english}
                    {locale === "ar" && item.name?.arabic}
                    {locale === "ku" && item.name?.kurdish}{" "}
                  </p>
                  <div className="flex items-center">
                    <p
                      className={`my-3 text-md font-semibold text-black cursor-auto max-w-fit ${locale}`}
                    >
                      {item?.isOfferActive === false ? (
                        <>
                          {Intl.NumberFormat().format(item.price)}{" "}
                          <small className={`${locale}`}>{t("iqd")} </small>
                        </>
                      ) : (
                        <>
                          {item?.offerType === 2 &&
                          item?.isApplicableForOffers &&
                          item?.isOfferActive ? (
                            <>
                              {Intl.NumberFormat().format(
                                item.priceAfterDiscount
                              )}{" "}
                              <small className={`${locale}`}>{t("iqd")} </small>
                              <span className="mx-2 text-sm font-light line-through">
                                {Intl.NumberFormat().format(item.price)}{" "}
                                <small className={`${locale} 	`}>
                                  {t("iqd")}{" "}
                                </small>
                              </span>
                            </>
                          ) : (
                            <>
                              {Intl.NumberFormat().format(item.price)}{" "}
                              <small className={`${locale}`}>{t("iqd")} </small>
                            </>
                          )}
                        </>
                      )}
                      {/*  {item.isOfferActive && (
                            <small>
                              {item.offerType === 1 && (
                                <GiftIcon className="inline w-5 mx-1 text-green-500" />
                              )}
                               {item.offerType === 2  && (
                                <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" />
                              )}
                              {item.offerType === 3  && (
                                <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" />
                              )}
                            </small> */}
                      {/* )} */}
                    </p>
                    {item.isOfferActive && (
                      <>
                        <del>
                          <p
                            className={` ${
                              locale === "en-US" ? "ml-2" : "mr-2"
                            } text-sm text-gray-600 cursor-auto`}
                          >
                            {item.offerType === 0 ? (
                              <> {item.percentageOf}%</>
                            ) : (
                              ""
                            )}
                          </p>
                        </del>
                      </>
                    )}
                    <div
                      className={` ${
                        locale === "en-US" ? "ml-auto" : "mr-auto"
                      } `}
                    >
                      <button
                        onClick={(e) => handleSubmitOrder(item.id)}
                        disabled={item.qty < 1}
                        hidden={item.qty < 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-bag-plus"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"
                          />
                          <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            </>
          ))}
      {checkLoginModal && (
        <CheckAuth open={checkLoginModal} setOpen={setcheckLoginModal} />
      )}
      {/* {snackBar && <SnackBar />} */}
      {/* {AddedToBasket && <AddedSnackBar />} */}
      {/* <div>
        <AddedSnackBar />
      </div> */}
      {/* </div> */}
    </Carousel>
  );
};

export default NewArrival;
