import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/contact.svg";
import addIcon from "../../icons/contact.svg";
import InfoCard from "../../components/Cards/Carts";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import Spiner from "../../components/Loader/Spiner";

// Icons
import {
  EyeIcon,
  EyeSlashIcon,
  PhoneIcon,
  PlusCircleIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";

// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../../../features/Global/ContactUs";
const Index = () => {
  const [isHeadlessOpen, setIsHeadlessOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [upload, setUpload] = useState(false);
  const [editID, setEditId] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Contact.data);
  const loader = useSelector((state) => state.Contact.loading);

  // Toast
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  // hook to fetch items
  useEffect(() => {
    dispatch(getContact());
  }, []);
  // Data Grid Compoenets
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "id", width: 70 },
    {
      field: "englishAddress",
      headerName: "English Address",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "arabicAddress",
      headerName: "Arabic Address",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "kurdishAddress",
      headerName: "Kurdish Address",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "primaryPhoneNumber",
      headerName: "Main Phone Number",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "secondaryPhoneNumber",
      headerName: "Secondary Phone Number",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "englsihBanner",
      headerName: "English Banner",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "arabicBanner",
      headerName: "Arabic Banner",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "kurdishBanner",
      headerName: "Kurdish Banner",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "bannerStatus",
      headerName: "Banner Status",
      minWidth: 220,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          className={`text-center inline-flex items-center justify-center px-3 py-1 mx-auto text-sm font-medium shadow-md ${
            cellvalues.row.bannerStatus === true
              ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400-600 focus:ring-emerald-500 focus:ring-offset-emerald-200 f "
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {cellvalues.row.bannerStatus === true ? (
            <>
              <p>
                {" "}
                <EyeIcon className="inline w-6 mx-2" /> Show
              </p>
            </>
          ) : (
            <>
              <p>
                {" "}
                <EyeSlashIcon className="inline w-6 mx-2" /> Hide
              </p>
            </>
          )}
        </button>
      ),
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={(e) => handleEdit(cellvalues.id)}
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-600 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <PencilIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white"
          />
          Edit
        </button>
      ),
    },

    // {
    //   field: "View",
    //   headerName: "View",
    //   width: 130,
    //   renderCell: () => (
    //     <button
    //       onClick={() => setView(true)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full px-3 py-2 text-center text-white transition duration-200 ease-in bg-orange-500 rounded-lg shadow-md hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <EyeIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       View
    //     </button>
    //   ),
    // },
  ];
  const handleEdit = (id) => {
    setEditId(id);
    setEdit(true);
    dispatch(getContact());
  };
  return (
    <div className="ltr">
      <main className="relative h-screen bg-gray-100 dark:bg-gray-800">
        <div className="flex items-start justify-between h-screen overflow-y-auto">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              <Header />
            </header>
            <div className="h-screen px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
              <div className="">
                <BreadCrumb
                  page={"Contact US"}
                  icon={<PhoneIcon className="inline w-6 mx-2" />}
                />
              </div>
              <InfoCard />
              <div className="p-4 h-[44rem] mb-7 rounded-md bg-gray-50 dark:bg-gray-800">
                {isHeadlessOpen && (
                  <Add
                    Title={"Add Contact us"}
                    open={isHeadlessOpen}
                    setOpen={setIsHeadlessOpen}
                  />
                )}
                {edit && (
                  <Edit
                    Title={"Edit Contact us"}
                    open={edit}
                    id={editID}
                    setOpen={setEdit}
                  />
                )}{" "}
                {view && (
                  <View
                    Title={"View Contact us"}
                    open={view}
                    setOpen={setView}
                  />
                )}
                <br />
                <br />
                {/* {loader && <Spiner />} */}
                {data?.length === 0 ? (
                  <>
                    <Spiner />
                  </>
                ) : (
                  <div className="flex items-center w-auto h-full mx-2 mt-2 dark:bg-gray-800">
                    <DataGrid
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      getRowId={(row) => row.id}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      className="min-h-full dark:text-gray-200 "
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      // checkboxSelection
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {toast && (
        <Toast
          className="float-right"
          Text={"Contact us Status Updated Succesfully."}
          Type={"teal"}
          setOpen={setToast}
        />
      )}
      {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}
    </div>
  );
};

export default Index;
