import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { TextareaAutosize } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import SnackBar from "./SnackBarDynamic";
import SnackBaError from "./SnackBarDynamicError";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Cookies from "js-cookie";

const AskVary = () => {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const animation = useAnimation();

  const onSubmit = (data) => {
    // console.log(data);
    data.answer = "";
    axios
      .post("api/Questions", data)
      .then((r) => {
        if (r.status === 200) {
          setToast(true);
          setValue("qestion", "");
          // console.log(r.status);
          // dispatch(getCategory());
        }
        if (r.status === 429) {
          setError(true);
          setMessage(t("Message Limit Exceeded"));
        }
      })
      .catch((e) => {
        console.log(e.response);
        console.log(e);
        // setMessage(e.response);
        setError(true);
      });
    setToast(false);
    setError(false);
  };

  const spring = {
    type: "spring",
    damping: 10,
    stiffness: 100,
  };
  return (
    <div>
      {/* <form
        className="flex flex-col items-center p-4 mt-6 shadow-sm rounded-xl bg-teal-50 dark:bg-teal-900 dark:text-teal-400 md:p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="inline-flex items-center">
          <h2
            className={`text-xl font-semibold text-teal-900 dark:text-teal-400 ${l}`}
          >
            {t("askvary")}
          </h2>
          <QuestionMarkCircleIcon className="w-6 h-6 mr-2 text-teal-900" />
        </div>
        <p
          className={`max-w-2xl mt-6 mb-6 text-center text-teal-800 dark:text-teal-400 ${l}`}
        >
          Start a new discussion for this topic with all the community members.
          Please be fair to others, for the full rules do refer to the{" "}
          <a className="text-teal" href="#">
            {" "}
            Discussion Rules{" "}
          </a>{" "}
          page.
        </p>
        <div className="container w-full mb-6">
          <label
            className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 ${l}`}
          >
            {t("question?")}
          </label>
          <TextareaAutosize
            
            rows={5}
            type="text"
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-600 ${l}
            ${errors.englishQestion && "border-rose-500"}`}
            placeholder="writetourQuestion..."
            required
            {...register("englishQestion", {
              required: true,
            })}
          />
        </div>
        <button
          // to={"/askvary"}
          type="submit"
          className={`px-4 py-3 font-bold text-white bg-teal-600 rounded-lg ${l}`}
        >
          {t("sendyourQuestion")}
        </button>
      </form> */}
      <section className=" dark:bg-gray-900" data-aos="fade-in">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div className="max-w-screen-md mx-auto sm:text-center">
            {/* <h2
              className={`mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl dark:text-white ${l}`}
            >
              {t("askVary")}
            </h2>
            <p
              className={`mx-auto mb-8 max-w-2xl font-light text-gray-500 md:mb-12 sm:text-xl dark:text-gray-400 ${l}`}
            >
              {t("askVaryDescription")}
            </p> */}

            <div className="max-w-screen-sm mx-auto my-6 mb-8 lg:mb-16">
              <p
                className={`mb-4 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-teal-400 to-teal-600 text-center py-2 ${l}`}
              >
                {t("askVary")}
              </p>
              <p
                className={`font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center ${l}`}
              >
                {t("askVaryDescription")}
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="items-center max-w-screen-sm mx-auto mb-3 space-y-4 sm:flex sm:space-y-0">
                <div className="relative w-full">
                  <label
                    htmlFor="email"
                    className="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {t("question")}
                  </label>
                  <div
                    className={`absolute inset-y-0 ${
                      l === "en-US" ? "right-0 pr-3" : "left-0 pl-3"
                    } flex items-center  pointer-events-none`}
                  >
                    {/* <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg> */}
                    <QuestionMarkCircleIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                  </div>
                  <input
                    {...register("qestion", {
                      required: true,
                    })}
                    className={`block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:rounded-none ${
                      l === "en-US" ? "sm:rounded-l-lg" : "sm:rounded-r-lg"
                    } focus:ring-teal-500 focus:border-teal-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-600 ${l}`}
                    placeholder={t("askYourQuestion")}
                    type="text"
                    id="email"
                    required=""
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className={`w-full px-5 py-3 text-sm font-medium text-center text-white bg-teal-500 bg-teal-700 border border-teal-600 rounded-lg cursor-pointer sm:rounded-none ${
                      l === "en-US" ? "sm:rounded-r-lg" : "sm:rounded-l-lg"
                    } hover:bg-teal-800 focus:ring-4 focus:ring-teal-300 dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800 ${l}`}
                  >
                    {/* {toast === false ? ( */}
                    {t("send")}
                    {/* ) : (
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 100, scale: 1.2 }}
                        transition={spring}
                      >
                        {" "}
                        <CheckCircleIcon className="inline w-6 h-6" />{" "}
                      </motion.span>
                    )} */}
                  </button>
                </div>
              </div>
              {/* {toast && (
                <span
                  className={`float-right px-4 py-1 my-2 bg-teal-100 
                        `}
                >
                  <span
                    className={`mx-auto max-w-screen-sm text-sm text-left text-teal-700 newsletter-form-footer dark:text-gray-300 ${l}`}
                  >
                    {t("question sent succesfully.")}
                  </span>
                </span>
              )} */}
              <div
                className={`mx-auto max-w-screen-sm text-sm ${
                  l === "en-US" ? " text-left" : "text-right"
                } text-gray-500 newsletter-form-footer dark:text-gray-300 ${l}`}
              >
                {t("WeCareAboutProtectionOfYourData")}
                {/* <a href="#" className="font-medium text-teal-600 dark:text-teal-500 hover:underline">Read our Privacy Policy</a>. */}
              </div>
            </form>
          </div>
        </div>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="inline"
        >
          <path
            fill="#1EB4AF"
            fill-opacity="1"
            d="M0,32L80,58.7C160,85,320,139,480,170.7C640,203,800,213,960,202.7C1120,192,1280,160,1360,144L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg> */}
      </section>
      {toast && <SnackBar />}
      {error && <SnackBaError />}
    </div>
  );
};

export default AskVary;
