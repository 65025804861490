import React from "react";
import BreadCrumb from "../componets/BreadCrumb";
import CopyWrite from "../componets/CopyWrite";
// Componetns
import Footer from "../componets/Footer";
import Header from "../componets/Header";
import Navbar from "../componets/Navbar";
import Products from "../componets/shop/Products";
import { ReceiptPercentIcon } from "@heroicons/react/24/outline";
import NewsDetail from "../componets/News/NewsDetail";
const src = "https://source.unsplash.com/random/200x200?sig=1";

const Shop = () => {
  return (
    <div>
      {/* <Header /> */}
      <Navbar />

      {/* <!-- shop wrapper --> */}
      <div className=" grid items-start gap-0 pt-4 pb-16 md:gap-6">
        {/* <!-- breadcrumb --> */}
        {/* <BreadCrumb Name={"Offers"} Iconn={ReceiptPercentIcon} /> */}
        {/* <!-- ./breadcrumb --> */}
        {/* <!-- sidebar --> */}
        <NewsDetail />
        {/* <!-- ./sidebar --> */}
        {/* <!-- products --> */}
        {/* <Products /> */}
        {/* <!-- ./products --> */}
      </div>
      {/* <!-- ./shop wrapper --> */}

      {/* <!-- footer --> */}
      <Footer />
      {/* <!-- ./footer --> */}

      {/* <!-- copyright --> */}
      <CopyWrite />
      {/* <!-- ./copyright --> */}
    </div>
  );
};

export default Shop;
