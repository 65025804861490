import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
const token = Cookies.get('dashboardToken');
const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};
export const getBrand = createAsyncThunk(
  "getBrand",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const { data } = await axios.get("api/Brands", config);
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    copyData: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    searchBrand: (state, action) => {
      state.copyData = state.data.filter(
        (item) =>
          item.name.english
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.name.arabic
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.name.kurdish
            .toLowerCase()
            .includes(action.payload?.toLowerCase())
      )
    },
    resetSearch: (state) => {
      state.copyData = state.data;
    }
  }, extraReducers: {
    [getBrand.pending]: (state, action) => {
      state.loading = true;
    },
    [getBrand.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getBrand.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});
export const { searchBrand, resetSearch } = loremSlice.actions;
export default loremSlice;
