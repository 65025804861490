import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getDashboardNotification = createAsyncThunk(
  "getDashboardNotification",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get('dashboardToken')}`,
        },
      };
      const { data } = await axios.get("api/DashboardNotifications",config);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getDashboardNotification.pending]: (state, action) => {
      state.loading = true;
    },
    [getDashboardNotification.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getDashboardNotification.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});

export default loremSlice;
