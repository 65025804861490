import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { getProfiles } from "../../../features/Global/Profiles";
import { useDispatch } from "react-redux";
import { TextareaAutosize } from "@mui/material";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, chilren }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStaus] = useState();

  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log("clicked");
    setDisable(true);
    const form = new FormData();
    form.append("EnglishName", data.EnglishName);
    form.append("ArabicName", data.ArabicName);
    form.append("KurdishName", data.KurdishName);
    form.append("EnglishDescription", data.EnglishDescription);
    form.append("ArabicDescription", data.ArabicDescription);
    form.append("KurdishDescription", data.KurdishDescription);
    form.append("EnglishClinicName", data.EnglishClinicName);
    form.append("ArabicClinicName", data.ArabicClinicName);
    form.append("KurdishClinicName", data.KurdishClinicName);
    form.append("EnglishAddress", data.EnglishAddress);
    form.append("ArabicAddress", data.ArabicAddress);
    form.append("KurdishAddress", data.KurdishAddress);
    form.append("ClinicPhoneNumber", data.ClinicPhoneNumber);
    form.append("OpensAt", data.OpensAt);
    form.append("ClosesAt", data.ClosesAt);
    form.append("File", file);
    form.append("FileName", "filename");
    form.append("Type", status);

    axios
      .post("api/Doctors", form, config)
      .then((r) => {
        setDisable(false);
        if (r.status === 200) {
          // console.log(r);
          setToast(true);
          dispatch(getProfiles());
        }
      })
      .catch((e) => {
        setDisable(false);
        // console.log(e.response.status);
        // console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
      });
  };
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const handleSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-teal-600 rounded-sm sm:px-6 dark:bg-teal-700">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PlusCircleIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    {/* <hr className="mx-1 text-gray-300 dark:text-teal-700" /> */}
                    <div className="relative flex-1 px-4 mt-2 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Upload Image
                                </label>

                                <div className="mb-8">
                                  <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="sr-only"
                                    onChange={(e) => handleSelect(e)}
                                  />
                                  <label
                                    htmlFor="file"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                    <div>
                                      {/*                       <span className="mb-2 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                        Drop files here
                                      </span> */}
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        {preview && (
                                          <img
                                            src={preview}
                                            alt="image"
                                            className="mx-auto rounded-lg "
                                          />
                                        )}
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="name..."
                                  {...register("EnglishName", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.EnglishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="name..."
                                  {...register("ArabicName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.ArabicName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Name:
                                </label>
                                <input
                                  type="text"
                                  {...register("KurdishName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  placeholder="name..."
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.KurdishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Clinic Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="clinic name..."
                                  {...register("EnglishClinicName", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.EnglishClinicName &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Clinic Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="clinic name..."
                                  {...register("ArabicClinicName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.ArabicClinicName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Clinic Name:
                                </label>
                                <input
                                  type="text"
                                  {...register("KurdishClinicName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  placeholder="clinic name..."
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.KurdishClinicName &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Description:
                                </label>
                                <TextareaAutosize
                                  maxRows={50}
                                  type="text"
                                  placeholder="Description..."
                                  {...register("EnglishDescription", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.EnglishDescription &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Description:
                                </label>
                                <TextareaAutosize
                                  maxRows={50}
                                  type="text"
                                  placeholder="Description..."
                                  {...register("ArabicDescription", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.ArabicDescription &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Description:
                                </label>
                                <TextareaAutosize
                                  maxRows={50}
                                  type="text"
                                  placeholder="Description..."
                                  {...register("KurdishDescription", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.KurdishDescription &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Start Time:
                                </label>
                                <input
                                  type="time"
                                  placeholder="Starting Time..."
                                  {...register("OpensAt", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.OpensAt && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  End Time:
                                </label>
                                <input
                                  type="time"
                                  placeholder="End Time..."
                                  {...register("ClosesAt", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.ClosesAt && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Address:
                                </label>
                                <input
                                  type="text"
                                  placeholder="clinic name..."
                                  {...register("EnglishAddress", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.EnglishAddress && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Address:
                                </label>
                                <input
                                  type="text"
                                  placeholder="clinic name..."
                                  {...register("ArabicAddress", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.ArabicAddress && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Address:
                                </label>
                                <input
                                  type="text"
                                  {...register("KurdishAddress", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  placeholder="clinic name..."
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.KurdishAddress && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Phone Number:
                                </label>
                                <input
                                  type="tel"
                                  {...register("ClinicPhoneNumber", {
                                    required: true,
                                  })}
                                  placeholder="Phone number..."
                                  className={`bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                                    errors.ClinicPhoneNumber &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-teal-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              {/* Descrition */}
                              <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                Team:
                              </label>
                              <div className="mb-6 flex flex-col gap-y-2 gap-x-4 lg:flex-row">
                                <div className="relative flex w-56 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 font-medium text-gray-700">
                                  <input
                                    className="peer hidden"
                                    type="radio"
                                    name="radio"
                                    id="radio1"
                                    onClick={() => setStaus(1)}
                                    checked={status === 1 ? true : false}
                                  />
                                  <label
                                    className="peer-checked:border-blue-600 peer-checked:bg-blue-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border"
                                    htmlFor="radio1"
                                  >
                                    {" "}
                                  </label>
                                  <div className="peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2 absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2"></div>
                                  <span className="pointer-events-none z-10">
                                    Doctor
                                  </span>
                                </div>
                                <div className="relative flex w-56 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 font-medium text-gray-700">
                                  <input
                                    className="peer hidden"
                                    type="radio"
                                    name="radio"
                                    id="radio3"
                                    onClick={() => setStaus(0)}
                                    checked={status === 0 ? true : false}
                                  />
                                  <label
                                    className="peer-checked:border-teal-600 peer-checked:bg-teal-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border"
                                    htmlFor="radio3"
                                  >
                                    {" "}
                                  </label>
                                  <div className="peer-checked:border-transparent peer-checked:bg-teal-600 peer-checked:ring-2 absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-teal-600 ring-offset-2"></div>
                                  <span className="pointer-events-none z-10">
                                    Vary Team
                                  </span>
                                </div>
                              </div>
                              <div className="float-right">
                                <button
                                  type="submit"
                                  disabled={disable}
                                  className={
                                    "bg-teal-100 mx-1 px-6 py-2 rounded-md text-teal-600 hover:bg-teal-200 hover:text-teal-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent dark:text-teal-100 dark:bg-teal-600"
                                  }
                                >
                                  <PlusCircleIcon className="inline w-6 h-6 mx-2" />
                                  Add
                                  {/* {children} */}
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-rose-100 mx-1 px-4 py-2 rounded-md text-rose-600 hover:bg-rose-200 hover:text-rose-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                                  }
                                  // {...props}
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                  {/* {children} */}
                                </button>
                              </div>
                            </form>
                            {toast && (
                              <Toast
                                Text={"Profile Created Successfully"}
                                Type={"teal"}
                                setOpen={setToast}
                              />
                            )}
                            {error && (
                              <Toast
                                Text={
                                  "email or phone number is allready taken!"
                                }
                                Type={"rose"}
                                setOpen={setError}
                              />
                            )}
                          </div>
                        </div>
                        {/* <div className=F"h-full border-2 border-gray-200 border-dashed" aria-hidden="true" /> */}
                      </div>
                      {/* {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
