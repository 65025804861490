import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import router from "./router/router.js";
import { RouterProvider } from "react-router-dom";
import i18n from "./i18nextConf";
import { AuthProvider } from "./context/AuthProvider";
import Cookies from "js-cookie";
import { HelmetProvider } from "react-helmet-async";

// import routerDashboard from './Dashboard/router/router'
const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      {/* <RouterProvider router={router} /> */}
      <AuthProvider>
        <HelmetProvider context={helmetContext}>
          <Provider store={store}>
            <App className={`${Cookies.get('18nextLng')} w-screen`} />
          </Provider>
        </HelmetProvider>
      </AuthProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
