import { Fragment, useState, useEffect, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  MinusIcon,
  PlusIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBasket } from "../../../features/web/ShopingCart";
import EmptyBag from "../../assets/icons/Group.svg";
import Checkout from "./Checkout";
import axios from "axios";
import { Link } from "react-router-dom";
import SnackBar from "./SnackBarDynamic";
import Cookies from "js-cookie";

export default function Example({ Title, open, setOpen }) {
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
    },
  };
  const [CheckOut, setCheckOut] = useState(false);

  const data = useSelector((state) => state.ShopingCart.data);
  const total = useSelector((state) => state.ShopingCart.total);
  let sum = 0;
  const [snackBar, setSnackBar] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const l = localStorage.getItem("i18nextLng");
  const [customer, setCustomer] = useState();
  // const customer = JSON?.parse(Cookies.get("Customer"));

  useEffect(() => {
    if (Cookies.get("Customer")) {
      setCustomer(JSON.parse(Cookies.get("Customer")));
    }
  }, []);

  useEffect(() => {
    if (Cookies.get("webAuth")) dispatch(getBasket());
    data?.forEach((element) => {
      // console.log("element", element.price);
      sum += element.price;
    });
  }, []);
  const handleChange = (change, id, qty) => {
    const data = {};
    data.productId = id;
    if (change === "del") {
      data.qty = 0;
    }
    if (change === "inc") {
      // console.log("object", qty);
      data.qty = ++qty;
    }
    if (change === "dec") {
      // console.log("object", qty);
      data.qty = --qty;
    }
    axios
      .post("api/BasketItems/AddAndRemoveFromBasket", data, config)
      .then((r) => {
        // if (r.data?.status === 200 || r.data?.status === 201) {
        dispatch(getBasket());
        // }
        if (r?.response?.data === "not enough items in stock") {
          setSnackBar(true);

          setTimeout(() => {
            setSnackBar(false);
          }, 4000);
        }
      })
      .catch((e) => {
        if (e?.response?.data === "not enough items in stock") {
          setSnackBar(true);

          setTimeout(() => {
            setSnackBar(false);
          }, 4000);
        }
        console.log(e);
      });
  };
  const handleCheckOut = () => {
    setCheckOut(true);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[400]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                  <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 px-4 py-6 overflow-y-auto sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title
                          className={`text-lg font-medium text-gray-900 ${l}`}
                        >
                          <ShoppingBagIcon className="inline w-6 h-6" />{" "}
                          {t("shopingCart")}
                        </Dialog.Title>
                        <div className="flex items-center ml-3 h-7">
                          <button
                            type="button"
                            className="p-2 -m-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="z-50 h-screen -my-6 divide-y divide-gray-200"
                          >
                            {data?.length === 0 ? (
                              <>
                                <div className="max-w-screen-lg mx-auto ">
                                  <img
                                    src={EmptyBag}
                                    className="content-center overflow-hidden mt-36"
                                  />
                                  <div
                                    className={`${l} text-gray-500 mx-auto my-6 font-medium`}
                                  >
                                    <div
                                      className={`${l} text-gray-500  mx-auto my-10  text-center`}
                                    >
                                      {/* <span className={`${l} mx-auto min-w-1/2 `}> */}
                                      {t("noItemFound")} {/* </span> */}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {" "}
                                {data?.map((product) => (
                                  <li key={product.id} className="flex py-6">
                                    <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md">
                                      <div className="relative z-30 shrink-0">
                                        <img
                                          className="object-cover w-24 h-24 max-w-full rounded-lg"
                                          src={product.imageUrl}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className={`flex flex-col flex-1 ${
                                        l === "en-US" ? " ml-4" : "mr-4"
                                      }`}
                                    >
                                      <div>
                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                          <h3>
                                            <a
                                              href={product.href}
                                              className={l}
                                            >
                                              {l === "en-US" &&
                                                product.procudtName.english}
                                              {l === "ar" &&
                                                product.procudtName.arabic}
                                              {l === "ku" &&
                                                product.procudtName.kurdish}
                                            </a>
                                          </h3>
                                          <p className={`ml-4 ${l}`}>
                                            {Intl.NumberFormat().format(
                                              product.price * product.qty
                                            )}{" "}
                                            {t("iqd")}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex items-end justify-between flex-1 text-sm">
                                        {product?.isFromBogo === false ? (
                                          <>
                                            <div className="flex flex-1">
                                              <span className="flex flex-1 mx-5">
                                                <div className="md:col-span-2">
                                                  <div className="flex items-stretch h-8 mx-auto text-gray-600">
                                                    <button
                                                      className={`flex items-center justify-center px-4 transition bg-gray-200 ${
                                                        l === "en"
                                                          ? "rounded-l-md"
                                                          : "rounded-r-md"
                                                      } hover:bg-rose-500 hover:text-white`}
                                                      onClick={() =>
                                                        handleChange(
                                                          "dec",
                                                          product.productId,
                                                          product.qty
                                                        )
                                                      }
                                                    >
                                                      -
                                                    </button>
                                                    <div className="flex items-center justify-center w-full px-4 text-xs uppercase transition bg-gray-100">
                                                      {product.qty}
                                                    </div>
                                                    <button
                                                      className={`flex items-center justify-center px-4 transition ${
                                                        l === "en"
                                                          ? "rounded-r-md"
                                                          : "rounded-l-md"
                                                      } bg-gray-200 hover:bg-pink-500 hover:text-white`}
                                                      onClick={() =>
                                                        handleChange(
                                                          "inc",
                                                          product.productId,
                                                          product.qty
                                                        )
                                                      }
                                                    >
                                                      +
                                                    </button>
                                                  </div>
                                                </div>
                                              </span>
                                              <button
                                                onClick={() =>
                                                  handleChange(
                                                    "del",
                                                    product.productId,
                                                    0
                                                  )
                                                }
                                                type="button"
                                                className={`font-medium text-rose-600 hover:text-rose-500 ${l}`}
                                              >
                                                {t("remove")}
                                              </button>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {data?.length > 0 && (
                      <>
                        {" "}
                        <div className="px-4 py-6 border-t border-gray-200 sm:px-6 ">
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <p className={l}>{t("total")}</p>
                            <p className={`${l}`}>
                              {t("iqd")} {Intl.NumberFormat().format(total)}
                            </p>
                          </div>
                          <p
                            className={`mt-0.5 text-sm text-gray-500 my-1 ${l}`}
                          >
                            {t("ShippingAndTaxesCalculatedAtCheckout")}.
                          </p>
                          {/* <div className="mt-6">
                            <span
                              onClick={() => handleCheckOut()}
                              className={`flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-teal-600 border border-transparent rounded-lg shadow-sm hover:bg-teal-700 ${l}`}
                            >
                              {t("checkout")}
                            </span>
                          </div> */}
                          {/* isPhoneConfirmed */}

                          {customer?.isPhoneConfirmed ? (
                            <>
                              <Link
                                to="/checkout"
                                className={`group inline-flex w-full items-center justify-center rounded-md bg-teal-500 px-6 py-4 text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-teal-600 ${l}`}
                              >
                                {t("checkout")}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6 h-6 ml-4 transition-all group-hover:ml-8"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                                  />
                                </svg>
                              </Link>
                              <div className="flex justify-center w-full mt-6 text-sm text-center text-gray-500 px-auto">
                                <p className={l}>
                                  {t("or")}
                                  <button
                                    type="button"
                                    className={`font-medium text-teal-600 hover:text-teal-500 ${l} w-full`}
                                    onClick={() => setOpen(false)}
                                  >
                                    {t("continueShopping")}
                                    {/* <span aria-hidden="true">
                                  {" "}
                                  {l === "en-Us" ? (
                                    <ArrowLeftIcon className="inline w-4 h-4 mt-1" />
                                    ) : (
                                      <ArrowRightIcon className="inline float-right w-4 h-4 mt-1" />
                                      )}
                                    </span> */}
                                  </button>
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <Link
                                to="/userProfile"
                                className={`group inline-flex w-full items-center justify-center rounded-md bg-blue-500 px-6 py-4 text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-blue-600 ${l}`}
                              >
                                {t("accountVerification")}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6 h-6 ml-4 transition-all group-hover:ml-8"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                        </div>
                        {CheckOut && (
                          <Checkout open={CheckOut} setOpen={setCheckOut} />
                        )}
                      </>
                    )}
                    {snackBar && <SnackBar />}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
