import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("dashboardToken");
export const getBrand = createAsyncThunk(
  "getBrand",
  async (object, { getState, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
        },
      };
      const response = await axios.get("api/controlPane/Brands", config);
      const data = response.data;
      // console.log("data", data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data); // Make sure to return rejectWithValue()
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    ordered: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrand.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBrand.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        state.isSuccess = true;

        // Check if payload is an array before sorting
        if (Array.isArray(payload)) {
          // Sorting the payload by the 'name.english' property in ascending order
          state.ordered = payload.slice().sort((a, b) =>
            a.name.english.localeCompare(b.name.english)
          );
        }
      })
      .addCase(getBrand.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.message = payload ? payload.message : "failed";
      });
  },
});

export default loremSlice;
