import React, { Component } from "react";
import { ReactComponent as ErrorIllustration } from "../../web/assets/icons/503.svg";
import {
  ArrowLeftIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    // Perform additional error handling or logging if needed
  }

  handleRefresh = () => {
    window.location.reload();
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <section className="bg-white dark:bg-gray-900">
          <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
            <div className="flex flex-col items-center max-w-sm mx-auto text-center">
              <p className="p-10 text-sm font-medium rounded-full text-rose-500 bg-rose-50 dark:bg-rose-800">
                <ExclamationTriangleIcon className="inline w-40 h-40 text-rose-500 animate-pulse" />
              </p>
              <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                Some thing went wrong!
              </h1>
              <p className="mt-4 text-gray-500 dark:text-gray-400">
                Sorry, the some thing went wrong. Please refresh the page or go
                back.
              </p>

              <div className="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
                <button
                  className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
                  onClick={this.handleGoBack}
                >
                  <ArrowLeftIcon className="w-5 h-5 rtl:rotate-180" />
                  <span>Go back</span>
                </button>

                <button
                  className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                  onClick={this.handleRefresh}
                >
                  <ArrowPathIcon className="inline w-5 h-5 rtl:rotate-180" />
                  Refresh
                </button>
              </div>
              {/* {error && (
                <div className="mt-6 text-red-500">
                  <pre>{error.stack}</pre>
                </div>
              )} */}
            </div>
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
