import { CheckCircleIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../features/Global/Dashboard";
import CountUp from "react-countup";

const Categories = () => {
  const data = useSelector((state) => state.Daashboard.data);
  const loader = useSelector((state) => state.Daashboard.loading);
  const error = useSelector((state) => state.Daashboard.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  return (
    <div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        <div className="md:col-span-2 lg:col-span-1">
          <div className="h-full px-6 py-8 space-y-6 duration-500 border border-gray-200 bg-gradient-to-br from-sky-50 to-sky-100 hover:scale-105 hover:bg-gradient-to-bl rounded-xl dark:bg-gray-800 dark:border-gray-700">
            <svg
              className="w-40 m-auto opacity-75"
              viewBox="0 0 146 146"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.1866 5.7129C81.999 5.7129 90.725 7.44863 98.8666 10.821C107.008 14.1933 114.406 19.1363 120.637 25.3675C126.868 31.5988 131.811 38.9964 135.184 47.138C138.556 55.2796 140.292 64.0057 140.292 72.818C140.292 81.6304 138.556 90.3565 135.184 98.4981C131.811 106.64 126.868 114.037 120.637 120.269C114.406 126.5 107.008 131.443 98.8666 134.815C90.725 138.187 81.999 139.923 73.1866 139.923C64.3742 139.923 55.6481 138.187 47.5066 134.815C39.365 131.443 31.9674 126.5 25.7361 120.269C19.5048 114.037 14.5619 106.64 11.1895 98.4981C7.81717 90.3565 6.08144 81.6304 6.08144 72.818C6.08144 64.0057 7.81717 55.2796 11.1895 47.138C14.5619 38.9964 19.5048 31.5988 25.7361 25.3675C31.9674 19.1363 39.365 14.1933 47.5066 10.821C55.6481 7.44863 64.3742 5.7129 73.1866 5.7129L73.1866 5.7129Z"
                stroke="#e4e4f2"
                strokeWidth="4.89873"
              ></path>
              <path
                d="M73.5 23.4494C79.9414 23.4494 86.3198 24.7181 92.2709 27.1831C98.222 29.6482 103.629 33.2612 108.184 37.816C112.739 42.3707 116.352 47.778 118.817 53.7291C121.282 59.6802 122.551 66.0586 122.551 72.5C122.551 78.9414 121.282 85.3198 118.817 91.2709C116.352 97.222 112.739 102.629 108.184 107.184C103.629 111.739 98.222 115.352 92.2709 117.817C86.3198 120.282 79.9414 121.551 73.5 121.551C67.0586 121.551 60.6802 120.282 54.7291 117.817C48.778 115.352 43.3707 111.739 38.816 107.184C34.2612 102.629 30.6481 97.222 28.1831 91.2709C25.7181 85.3198 24.4494 78.9414 24.4494 72.5C24.4494 66.0586 25.7181 59.6802 28.1831 53.7291C30.6481 47.778 34.2612 42.3707 38.816 37.816C43.3707 33.2612 48.778 29.6481 54.7291 27.1831C60.6802 24.7181 67.0586 23.4494 73.5 23.4494L73.5 23.4494Z"
                stroke="#e4e4f2"
                strokeWidth="4.89873"
              ></path>
              <path
                d="M73 24C84.3364 24 95.3221 27.9307 104.085 35.1225C112.848 42.3142 118.847 52.322 121.058 63.4406C123.27 74.5592 121.558 86.1006 116.214 96.0984C110.87 106.096 102.225 113.932 91.7515 118.27C81.278 122.608 69.6243 123.181 58.7761 119.89C47.9278 116.599 38.5562 109.649 32.258 100.223C25.9598 90.7971 23.1248 79.479 24.2359 68.1972C25.3471 56.9153 30.3357 46.3678 38.3518 38.3518"
                stroke="url(#paint0_linear_622:13617)"
                strokeWidth="10"
                strokeLinecap="round"
              ></path>
              <path
                d="M73 5.00001C84.365 5.00001 95.5488 7.84852 105.529 13.2852C115.509 18.7218 123.968 26.5732 130.131 36.1217C136.295 45.6702 139.967 56.6112 140.812 67.9448C141.657 79.2783 139.648 90.6429 134.968 101C130.288 111.357 123.087 120.375 114.023 127.232C104.96 134.088 94.3218 138.563 83.0824 140.248C71.8431 141.933 60.3606 140.775 49.6845 136.878C39.0085 132.981 29.4793 126.471 21.9681 117.942"
                stroke="url(#paint1_linear_622:13617)"
                strokeWidth="10"
                strokeLinecap="round"
              ></path>
              <path
                d="M9.60279 97.5926C6.37325 89.2671 4.81515 80.3871 5.01745 71.4595C5.21975 62.5319 7.1785 53.7316 10.7818 45.561C14.3852 37.3904 19.5626 30.0095 26.0184 23.8398C32.4742 17.6701 40.082 12.8323 48.4075 9.6028"
                stroke="url(#paint2_linear_622:13617)"
                strokeWidth="10"
                strokeLinecap="round"
              ></path>
              <path
                d="M73 5.00001C86.6504 5.00001 99.9849 9.10831 111.269 16.7904"
                stroke="url(#paint3_linear_622:13617)"
                strokeWidth="10"
                strokeLinecap="round"
              ></path>
              <circle
                cx="73.5"
                cy="72.5"
                r="29"
                fill="#e4e4f2"
                stroke="#e4e4f2"
              ></circle>
              <path
                d="M74 82.8332C68.0167 82.8332 63.1666 77.9831 63.1666 71.9998C63.1666 66.0166 68.0167 61.1665 74 61.1665C79.9832 61.1665 84.8333 66.0166 84.8333 71.9998C84.8333 77.9831 79.9832 82.8332 74 82.8332ZM74 80.6665C76.2985 80.6665 78.5029 79.7534 80.1282 78.1281C81.7535 76.5028 82.6666 74.2984 82.6666 71.9998C82.6666 69.7013 81.7535 67.4969 80.1282 65.8716C78.5029 64.2463 76.2985 63.3332 74 63.3332C71.7014 63.3332 69.497 64.2463 67.8717 65.8716C66.2464 67.4969 65.3333 69.7013 65.3333 71.9998C65.3333 74.2984 66.2464 76.5028 67.8717 78.1281C69.497 79.7534 71.7014 80.6665 74 80.6665ZM70.75 67.6665H77.25L79.9583 71.4582L74 77.4165L68.0416 71.4582L70.75 67.6665ZM71.8658 69.8332L70.8691 71.2307L74 74.3615L77.1308 71.2307L76.1341 69.8332H71.8658Z"
                fill="#6A6A9F"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_622:13617"
                  x1="45.9997"
                  y1="19"
                  x2="46.0897"
                  y2="124.308"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E323FF"></stop>
                  <stop offset="1" stopColor="#7517F8"></stop>
                </linearGradient>
                <linearGradient
                  id="paint1_linear_622:13617"
                  x1="1.74103e-06"
                  y1="8.70228e-06"
                  x2="6.34739e-08"
                  y2="140.677"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4DFFDF"></stop>
                  <stop offset="1" stopColor="#4DA1FF"></stop>
                </linearGradient>
                <linearGradient
                  id="paint2_linear_622:13617"
                  x1="36.4997"
                  y1="5.07257e-06"
                  x2="36.6213"
                  y2="142.36"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFD422"></stop>
                  <stop offset="1" stopColor="#FF7D05"></stop>
                </linearGradient>
                <linearGradient
                  id="paint3_linear_622:13617"
                  x1="1.74103e-06"
                  y1="8.70228e-06"
                  x2="6.34739e-08"
                  y2="140.677"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4DFFDF"></stop>
                  <stop offset="1" stopColor="#4DA1FF"></stop>
                </linearGradient>
              </defs>
            </svg>
            <div>
              <h5 className="text-xl text-center text-gray-600 ">
                Items
              </h5>
              <div className="flex justify-center gap-4 mt-2">
                <h3 className="text-3xl font-bold text-gray-700 ">
                  <CountUp end={data?.products?.length} />
                </h3>
                {/* <div className="flex items-end gap-1 text-green-500">
                  <svg
                    className="w-3"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.00001 0L12 8H-3.05176e-05L6.00001 0Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span>2%</span>
                </div> */}
              </div>
              <span className="block text-center text-gray-500 dark:text-gray-400">
                Nearly UnAvailable
              </span>
            </div>
            <table className="w-full text-gray-600">
              <tbody>
                {data?.products?.slice(0, 3)?.map((item) => (
                  <tr>
                    <td className="py-2 ">
                      {item?.name?.english}
                    </td>
                    <td className="mx-1 text-gray-500 ">
                      <CountUp end={item?.qty} />
                    </td>
                    <td>
                      <svg
                        className="w-16 ml-auto"
                        viewBox="0 0 68 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.4"
                          width="17"
                          height="21"
                          rx="1"
                          fill="#e4e4f2"
                        ></rect>
                        <rect
                          opacity="0.4"
                          x="19"
                          width="14"
                          height="21"
                          rx="1"
                          fill="#e4e4f2"
                        ></rect>
                        <rect
                          opacity="0.4"
                          x="35"
                          width="14"
                          height="21"
                          rx="1"
                          fill="#e4e4f2"
                        ></rect>
                        <rect
                          opacity="0.4"
                          x="51"
                          width="17"
                          height="21"
                          rx="1"
                          fill="#e4e4f2"
                        ></rect>
                        <path
                          d="M0 6C8.62687 6 6.85075 12.75 17 12.75C27.1493 12.75 25.3731 9 34 9C42.6269 9 42.262 13.875 49 13.875C55.5398 13.875 58.3731 6 67 6"
                          stroke="url(#paint0_linear_622:13649)"
                          strokeWidth="2"
                          strokeLinejoin="round"
                        ></path>
                        <defs>
                          <linearGradient
                            id="paint0_linear_622:13649"
                            x1="67"
                            y1="7.96873"
                            x2="1.67368"
                            y2="8.44377"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FFD422"></stop>
                            <stop offset="1" stopColor="#FF7D05"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                    </td>
                  </tr>
                ))}

                {/* <tr>
                  <td className="py-2">Customize</td>
                  <td className="text-gray-500">1200</td>
                  <td>
                    <svg
                      className="w-16 ml-auto"
                      viewBox="0 0 68 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.4"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="19"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="35"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="51"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <path
                        d="M0 12.929C8.69077 12.929 7.66833 9.47584 17.8928 9.47584C28.1172 9.47584 25.5611 15.9524 34.2519 15.9524C42.9426 15.9524 44.8455 10.929 51.6334 10.929C58.2217 10.929 59.3092 5 68 5"
                        stroke="url(#paint0_linear_622:13640)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_622:13640"
                          x1="34"
                          y1="5"
                          x2="34"
                          y2="15.9524"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#8AFF6C"></stop>
                          <stop offset="1" stopColor="#02C751"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td className="py-2">Other</td>
                  <td className="text-gray-500">12</td>
                  <td>
                    <svg
                      className="w-16 ml-auto"
                      viewBox="0 0 68 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.4"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="19"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="35"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="51"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <path
                        d="M0 6C8.62687 6 6.85075 12.75 17 12.75C27.1493 12.75 25.3731 9 34 9C42.6269 9 42.262 13.875 49 13.875C55.5398 13.875 58.3731 6 67 6"
                        stroke="url(#paint0_linear_622:13649)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_622:13649"
                          x1="67"
                          y1="7.96873"
                          x2="1.67368"
                          y2="8.44377"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FFD422"></stop>
                          <stop offset="1" stopColor="#FF7D05"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="h-full px-6 py-6 duration-500 border border-gray-200 bg-gradient-to-br from-fuchsia-50 to-fuchsia-100 hover:scale-105 hover:bg-gradient-to-bl rounded-xl dark:bg-gray-800 dark:border-gray-700">
            <h5 className="text-xl text-gray-700 ">
              <UserCircleIcon className="inline w-10 text-fuchsia-600 " /> Users
            </h5>
            <div className="my-8">
              <h1 className="text-4xl font-bold text-gray-800 ">
                %{" "}
                <CountUp
                  end={Math.round(
                    (data?.verifedUsers /
                      (data?.verifedUsers + data?.unVerifedUsers)) *
                      100
                  )}
                />
              </h1>
              <span className="text-gray-500 dark:text-gray-400">
                <CheckCircleIcon className="inline w-6 mx-1 my-auto text-fuchsia-600" />
                Vierifed Users
              </span>
            </div>
            <svg
              className="w-full"
              viewBox="0 0 218 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 67.5C27.8998 67.5 24.6002 15 52.5 15C80.3998 15 77.1002 29 105 29C132.9 29 128.6 52 156.5 52C184.4 52 189.127 63.8158 217.027 63.8158"
                stroke="url(#paint0_linear_622:13664)"
                strokeWidth="3"
                strokeLinecap="round"
              ></path>
              <path
                d="M0 67.5C27.2601 67.5 30.7399 31 58 31C85.2601 31 80.7399 2 108 2C135.26 2 134.74 43 162 43C189.26 43 190.74 63.665 218 63.665"
                stroke="url(#paint1_linear_622:13664)"
                strokeWidth="3"
                strokeLinecap="round"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear_622:13664"
                  x1="217.027"
                  y1="15"
                  x2="7.91244"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4DFFDF"></stop>
                  <stop offset="1" stopColor="#4DA1FF"></stop>
                </linearGradient>
                <linearGradient
                  id="paint1_linear_622:13664"
                  x1="218"
                  y1="18.3748"
                  x2="5.4362"
                  y2="18.9795"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E323FF"></stop>
                  <stop offset="1" stopColor="#7517F8"></stop>
                </linearGradient>
              </defs>
            </svg>
            <table className="w-full mt-6 -mb-2 text-gray-600">
              <tbody>
                <tr>
                  <td className="py-2 ">Verfied</td>
                  <td className="text-gray-500 ">
                    <CountUp end={data?.verifedUsers} />
                  </td>
                  <td>
                    <svg
                      className="w-16 ml-auto"
                      viewBox="0 0 68 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.4"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="19"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="35"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="51"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <path
                        d="M0 7C8.62687 7 7.61194 16 17.7612 16C27.9104 16 25.3731 9 34 9C42.6269 9 44.5157 5 51.2537 5C57.7936 5 59.3731 14.5 68 14.5"
                        stroke="url(#paint0_linear_622:13631)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_622:13631"
                          x1="68"
                          y1="7.74997"
                          x2="1.69701"
                          y2="8.10029"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#E323FF"></stop>
                          <stop offset="1" stopColor="#7517F8"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 ">UnVerifed</td>
                  <td className="text-gray-500 ">
                    <CountUp end={data?.unVerifedUsers} />
                  </td>
                  <td>
                    <svg
                      className="w-16 ml-auto"
                      viewBox="0 0 68 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.4"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="19"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="35"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="51"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <path
                        d="M0 12.929C8.69077 12.929 7.66833 9.47584 17.8928 9.47584C28.1172 9.47584 25.5611 15.9524 34.2519 15.9524C42.9426 15.9524 44.8455 10.929 51.6334 10.929C58.2217 10.929 59.3092 5 68 5"
                        stroke="url(#paint0_linear_622:13640)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_622:13640"
                          x1="34"
                          y1="5"
                          x2="34"
                          y2="15.9524"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#8AFF6C"></stop>
                          <stop offset="1" stopColor="#02C751"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="px-6 py-8 text-gray-600 duration-500 border border-gray-200 bg-gradient-to-br from-orange-50 to-orange-100 hover:scale-105 hover:bg-gradient-to-bl lg:h-full rounded-xl dark:bg-gray-800 dark:border-gray-700">
            <svg
              className="w-40 mx-auto"
              viewBox="0 0 146 146"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_49_17)">
                <path
                  d="M73.1867 5.71289C81.9991 5.71289 90.7251 7.44862 98.8667 10.821C107.008 14.1933 114.406 19.1363 120.637 25.3675C126.868 31.5988 131.811 38.9964 135.184 47.138C138.556 55.2796 140.292 64.0057 140.292 72.818C140.292 81.6304 138.556 90.3565 135.184 98.4981C131.811 106.64 126.868 114.037 120.637 120.269C114.406 126.5 107.008 131.443 98.8667 134.815C90.7251 138.187 81.9991 139.923 73.1867 139.923C64.3743 139.923 55.6482 138.187 47.5067 134.815C39.3651 131.443 31.9675 126.5 25.7362 120.269C19.5049 114.037 14.562 106.64 11.1896 98.4981C7.81727 90.3565 6.08154 81.6304 6.08154 72.818C6.08154 64.0057 7.81727 55.2796 11.1896 47.138C14.562 38.9964 19.5049 31.5988 25.7362 25.3675C31.9675 19.1363 39.3651 14.1933 47.5067 10.821C55.6482 7.44862 64.3743 5.71289 73.1867 5.71289Z"
                  stroke="#E4E4F2"
                  strokeWidth="4.89873"
                />
                <path
                  d="M73.5001 23.4493C79.9415 23.4493 86.3199 24.718 92.271 27.183C98.2221 29.6481 103.629 33.2611 108.184 37.8159C112.739 42.3706 116.352 47.7779 118.817 53.729C121.282 59.6801 122.551 66.0585 122.551 72.4999C122.551 78.9413 121.282 85.3197 118.817 91.2708C116.352 97.2219 112.739 102.629 108.184 107.184C103.629 111.739 98.2221 115.352 92.271 117.817C86.3199 120.282 79.9415 121.551 73.5001 121.551C67.0587 121.551 60.6803 120.282 54.7292 117.817C48.7781 115.352 43.3708 111.739 38.8161 107.184C34.2613 102.629 30.6482 97.2219 28.1832 91.2708C25.7182 85.3197 24.4495 78.9413 24.4495 72.4999C24.4495 66.0585 25.7182 59.6801 28.1832 53.729C30.6482 47.7779 34.2613 42.3706 38.8161 37.8159C43.3708 33.2611 48.7781 29.648 54.7292 27.183C60.6803 24.718 67.0587 23.4493 73.5001 23.4493Z"
                  stroke="#E4E4F2"
                  strokeWidth="4.89873"
                />
                <path
                  d="M73.0001 24C84.3365 24 95.3222 27.9307 104.085 35.1225C112.848 42.3142 118.847 52.322 121.058 63.4406C123.27 74.5592 121.558 86.1006 116.214 96.0984C110.87 106.096 102.225 113.932 91.7516 118.27C81.2781 122.608 69.6244 123.181 58.7762 119.89C47.9279 116.599 38.5563 109.649 32.2581 100.223C25.9599 90.7971 23.1249 79.479 24.236 68.1972C25.3472 56.9153 30.3358 46.3678 38.3519 38.3518"
                  stroke="url(#paint0_linear_49_17)"
                  strokeWidth="10"
                  strokeLinecap="round"
                />
                <path
                  d="M72.9999 5C84.3649 5 95.5487 7.84851 105.529 13.2852C115.509 18.7218 123.968 26.5732 130.131 36.1217C136.295 45.6702 139.967 56.6112 140.812 67.9448C141.657 79.2783 139.648 90.6429 134.968 101C130.288 111.357 123.087 120.375 114.023 127.232C104.96 134.088 94.3217 138.563 83.0823 140.248C71.843 141.933 60.3605 140.775 49.6844 136.878C39.0084 132.981 29.4792 126.471 21.968 117.942"
                  stroke="url(#paint1_linear_49_17)"
                  strokeWidth="10"
                  strokeLinecap="round"
                />
                <path
                  d="M9.60279 97.5926C6.37325 89.2671 4.81515 80.3871 5.01745 71.4595C5.21975 62.5319 7.1785 53.7316 10.7818 45.561C14.3852 37.3904 19.5626 30.0095 26.0184 23.8398C32.4742 17.6701 40.082 12.8323 48.4075 9.60278"
                  stroke="url(#paint2_linear_49_17)"
                  strokeWidth="10"
                  strokeLinecap="round"
                />
                <path
                  d="M73 5C86.6504 5 99.9849 9.1083 111.269 16.7904"
                  stroke="url(#paint3_linear_49_17)"
                  strokeWidth="10"
                  strokeLinecap="round"
                />
                <path
                  d="M73.5 101.5C89.5163 101.5 102.5 88.5163 102.5 72.5C102.5 56.4837 89.5163 43.5 73.5 43.5C57.4837 43.5 44.5 56.4837 44.5 72.5C44.5 88.5163 57.4837 101.5 73.5 101.5Z"
                  fill="#E4E4F2"
                  stroke="#E4E4F2"
                />
                <path
                  d="M71.879 67.519C73.05 66.494 74.95 66.494 76.121 67.519C77.293 68.544 77.293 70.206 76.121 71.231C75.918 71.41 75.691 71.557 75.451 71.673C74.706 72.034 74.001 72.672 74.001 73.5V74.25M83 72C83 73.1819 82.7672 74.3522 82.3149 75.4442C81.8626 76.5361 81.1997 77.5282 80.364 78.364C79.5282 79.1997 78.5361 79.8626 77.4442 80.3149C76.3522 80.7672 75.1819 81 74 81C72.8181 81 71.6478 80.7672 70.5558 80.3149C69.4639 79.8626 68.4718 79.1997 67.636 78.364C66.8003 77.5282 66.1374 76.5361 65.6851 75.4442C65.2328 74.3522 65 73.1819 65 72C65 69.6131 65.9482 67.3239 67.636 65.636C69.3239 63.9482 71.6131 63 74 63C76.3869 63 78.6761 63.9482 80.364 65.636C82.0518 67.3239 83 69.6131 83 72ZM74 77.25H74.008V77.258H74V77.25Z"
                  stroke="#6A6A9F"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_49_17"
                  x1="45.9998"
                  y1="19"
                  x2="46.0898"
                  y2="124.308"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E323FF" />
                  <stop offset="1" stopColor="#7517F8" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_49_17"
                  x1="-7.90627e-05"
                  y1="-1.32369e-06"
                  x2="-8.07402e-05"
                  y2="140.677"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#0CBC8B" />
                  <stop offset="1" stopColor="#4DA1FF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_49_17"
                  x1="36.4997"
                  y1="-1.19249e-05"
                  x2="36.6213"
                  y2="142.36"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#12B8A6" />
                  <stop offset="1" stopColor="#558FFF" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_49_17"
                  x1="4.5075e-06"
                  y1="-1.03139e-06"
                  x2="2.82994e-06"
                  y2="140.677"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#12B8A6" />
                  <stop offset="1" stopColor="#4DA1FF" />
                </linearGradient>
                <clipPath id="clip0_49_17">
                  <rect width="146" height="146" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            ></svg>

            <div className="mt-6">
              <h5 className="text-xl text-center text-gray-700 ">
                Ask Vary
              </h5>
              <div className="flex justify-center gap-4 mt-2">
                <h3 className="text-3xl font-bold text-gray-700 ">
                  <CountUp end={data?.answred + data?.notAnswred} />
                </h3>
                {/* <div className="flex items-end gap-1 text-green-500">
                  <svg
                    className="w-3"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.00001 0L12 8H-3.05176e-05L6.00001 0Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span>2%</span>
                </div> */}
              </div>
              <span className="block text-center text-gray-500 dark:text-gray-400">
                Total Questions
              </span>
            </div>
            <table className="w-full mt-6 -mb-2 text-gray-600">
              <tbody>
                <tr>
                  <td className="py-2 ">Answred</td>
                  <td className="text-gray-500 ">
                    <CountUp end={data?.answred} />
                  </td>
                  <td>
                    <svg
                      className="w-16 ml-auto"
                      viewBox="0 0 68 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.4"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="19"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="35"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="51"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <path
                        d="M0 7C8.62687 7 7.61194 16 17.7612 16C27.9104 16 25.3731 9 34 9C42.6269 9 44.5157 5 51.2537 5C57.7936 5 59.3731 14.5 68 14.5"
                        stroke="url(#paint0_linear_622:13631)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_622:13631"
                          x1="68"
                          y1="7.74997"
                          x2="1.69701"
                          y2="8.10029"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#E323FF"></stop>
                          <stop offset="1" stopColor="#7517F8"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 ">Not Answred</td>
                  <td className="text-gray-500 ">
                    <CountUp end={data?.notAnswred} />
                  </td>
                  <td>
                    <svg
                      className="w-16 ml-auto"
                      viewBox="0 0 68 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.4"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="19"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="35"
                        width="14"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <rect
                        opacity="0.4"
                        x="51"
                        width="17"
                        height="21"
                        rx="1"
                        fill="#e4e4f2"
                      ></rect>
                      <path
                        d="M0 12.929C8.69077 12.929 7.66833 9.47584 17.8928 9.47584C28.1172 9.47584 25.5611 15.9524 34.2519 15.9524C42.9426 15.9524 44.8455 10.929 51.6334 10.929C58.2217 10.929 59.3092 5 68 5"
                        stroke="url(#paint0_linear_622:13640)"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_622:13640"
                          x1="34"
                          y1="5"
                          x2="34"
                          y2="15.9524"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#8AFF6C"></stop>
                          <stop offset="1" stopColor="#02C751"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
