import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getBanner } from "../../../features/Global/Banner";
import { TextareaAutosize } from "@mui/material";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, chilren }) {
  const [status, setStatus] = useState(true);
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [fileEnglish, setFileEnglish] = useState(false);
  const [fileArabic, setFileArabic] = useState(false);
  const [fileKurdish, setFileKurdish] = useState(false);
  const [disable, setDisable] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const onSubmit = (data) => {
    setDisable(true);
    const form = new FormData();
    form.append("isActive", status);
    form.append("EnglishCarousel", fileEnglish);
    form.append("ArabicCarousel", fileArabic);
    form.append("KurdishCarousel", fileKurdish);
    form.append("EnglishText", data?.englishText);
    form.append("ArabicText", data?.arabicText);
    form.append("KurdishText", data?.kurdishText);
    axios
      .post("api/Banners", form, config)
      .then((r) => {
        if (r.status === 200) {
          setToast(true);
          dispatch(getBanner());
        }
        setDisable(false);
      })
      .catch((e) => {
        // console.log(e.response.status);
        // console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
        setDisable(false);
      });
  };
  useEffect(() => {
    dispatch(getBanner());
  }, []);

  const [previewEnglish, setPreviewEnglish] = useState();
  const [previewArabic, setPreviewArabic] = useState();
  const [previewKurdish, setPreviewKurdish] = useState();

  const handleSelectEnglish = (e) => {
    const file = e.target.files[0];
    if (file.type.startsWith("image/") || file.type.startsWith("video/")) {
      const file = e.target.files[0];
      setFileEnglish(file);
      const objectUrl = URL.createObjectURL(file);
      setPreviewEnglish(objectUrl);
    } else {
      setMessage("Please select an image!");
      setError(true);
    }
  };

  const handleSelectArabic = (e) => {
    const file = e.target.files[0];
    if (file.type.startsWith("image/") || file.type.startsWith("video/")) {
      const file = e.target.files[0];
      setFileArabic(file);
      const objectUrl = URL.createObjectURL(file);
      setPreviewArabic(objectUrl);
    } else {
      setMessage("Please select an image!");
      setError(true);
    }
  };

  const handleSelectKurdish = (e) => {
    const file = e.target.files[0];
    if (file.type.startsWith("image/") || file.type.startsWith("video/")) {
      const file = e.target.files[0];
      setFileKurdish(file);
      const objectUrl = URL.createObjectURL(file);
      setPreviewKurdish(objectUrl);
    } else {
      setMessage("Please select an image!");
      setError(true);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-teal-600 rounded-sm sm:px-6 dark:bg-teal-600">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PlusCircleIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    {/* <hr className="mx-1 text-gray-300 dark:text-teal-700" /> */}
                    <div className="relative flex-1 px-4 mt-2 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Image For English
                                </label>
                                <div className="mb-8">
                                  <input
                                    type="file"
                                    name="file"
                                    id="fileEnglish"
                                    className="sr-only"
                                    onChange={(e) => handleSelectEnglish(e)}
                                  />
                                  <label
                                    htmlFor="fileEnglish"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                    <div>
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        {previewEnglish && (
                                          <img
                                            src={previewEnglish}
                                            alt="image"
                                            className="mx-auto rounded-lg "
                                          />
                                        )}
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Image For Arabic
                                </label>
                                <div className="mb-8">
                                  <input
                                    type="file"
                                    name="file"
                                    id="fileArabic"
                                    className="sr-only"
                                    onChange={(e) => handleSelectArabic(e)}
                                  />
                                  <label
                                    htmlFor="fileArabic"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                    <div>
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        {previewArabic && (
                                          <img
                                            src={previewArabic}
                                            alt="image"
                                            className="mx-auto rounded-lg "
                                          />
                                        )}
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Image For Kurdish
                                </label>
                                <div className="mb-8">
                                  <input
                                    type="file"
                                    name="file"
                                    id="fileKurdish"
                                    className="sr-only"
                                    onChange={(e) => handleSelectKurdish(e)}
                                  />
                                  <label
                                    htmlFor="fileKurdish"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                    <div>
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        {previewKurdish && (
                                          <img
                                            src={previewKurdish}
                                            alt="image"
                                            className="mx-auto rounded-lg"
                                          />
                                        )}
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English text:
                                </label>
                                <input
                                  type="text"
                                  placeholder="enter english text"
                                  {...register("englishText", {
                                    required: false,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishText && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-teal-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>

                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic text:
                                </label>
                                <input
                                  type="text"
                                  placeholder="enter arabic text"
                                  {...register("arabicText", {
                                    required: false,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicText && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-teal-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish text:
                                </label>
                                <input
                                  type="text"
                                  placeholder="enter kurdish text"
                                  {...register("kurdishText", {
                                    required: false,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishText && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-teal-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Status:
                                </label>
                                <div className="flex flex-col mb-6 gap-y-2 gap-x-4 lg:flex-row">
                                  <div className="relative flex items-center justify-center w-56 px-4 py-3 font-medium text-gray-700 rounded-xl bg-gray-50">
                                    <input
                                      className="hidden peer"
                                      type="radio"
                                      name="radio"
                                      id="radio1"
                                      onClick={() => setStatus(true)}
                                      checked={status === true ? true : false}
                                    />
                                    <label
                                      className="absolute top-0 w-full h-full border cursor-pointer peer-checked:border-teal-600 peer-checked:bg-teal-200 rounded-xl"
                                      htmlFor="radio1"
                                    >
                                      {" "}
                                    </label>
                                    <div className="absolute w-5 h-5 bg-gray-200 border-2 border-gray-300 rounded-full peer-checked:border-transparent peer-checked:bg-teal-600 peer-checked:ring-2 left-4 ring-teal-600 ring-offset-2"></div>
                                    <span className="z-10 pointer-events-none">
                                      Acive
                                    </span>
                                  </div>
                                  <div className="relative flex items-center justify-center w-56 px-4 py-3 font-medium text-gray-700 rounded-xl bg-gray-50">
                                    <input
                                      className="hidden peer"
                                      type="radio"
                                      name="radio"
                                      id="radio3"
                                      onClick={() => setStatus(false)}
                                      checked={status === false ? true : false}
                                    />
                                    <label
                                      className="absolute top-0 w-full h-full border cursor-pointer peer-checked:border-rose-600 peer-checked:bg-rose-200 rounded-xl"
                                      htmlFor="radio3"
                                    >
                                      {" "}
                                    </label>
                                    <div className="absolute w-5 h-5 bg-gray-200 border-2 border-gray-300 rounded-full peer-checked:border-transparent peer-checked:bg-rose-600 peer-checked:ring-2 left-4 ring-rose-600 ring-offset-2"></div>
                                    <span className="z-10 pointer-events-none">
                                      InActive
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="float-right pt-2 pb-4">
                                {/* <button className="hover:shadow-form bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none">
                                  Send File
                                </button> */}
                                <button
                                  disabled={disable}
                                  type="submit"
                                  className={
                                    "bg-teal-100 mx-1 px-6 py-2 rounded-md text-teal-600 hover:bg-teal-200 hover:text-teal-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent dark:text-teal-100 dark:bg-teal-600"
                                  }
                                  // {...props}
                                >
                                  <PlusCircleIcon className="inline w-6 h-6 mx-2" />
                                  Add
                                  {/* {children} */}
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-rose-100 mx-1 px-4 py-2 rounded-md text-rose-600 hover:bg-rose-200 hover:text-rose-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                                  }
                                  // {...props}
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                  {/* {children} */}
                                </button>
                              </div>
                              <br />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {toast && (
                      <Toast
                        Text={"Banner Added Successfully"}
                        Type={"teal"}
                        setOpen={setToast}
                      />
                    )}
                    {error && (
                      <Toast Text={message} Type={"rose"} setOpen={setError} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
