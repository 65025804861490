import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getBasket = createAsyncThunk(
  "getBasket",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get('webAuth')}`,
      },
    };
    try {
      const { data } = await axios.get("api/BasketItems", config);
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "basket",
  initialState: {
    data: [],
    count:[],
    total:[],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getBasket.pending]: (state, action) => {
      state.loading = true;
    },
    [getBasket.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.basketItems;
      state.count = payload?.basketItems.length;
      state.total = (payload?.total);
      state.isSuccess = true;
    },
    [getBasket.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});

export default loremSlice;
