import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles } from "../../../features/Global/Profiles";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const VaryTeam = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Profiles.data);
  const l = localStorage.getItem("i18nextLng");
  // hook to fetch items
  useEffect(() => {
    dispatch(getProfiles());
    // console.log('data',data);
  }, []);
  return (
    <section className="bg-white dark:bg-gray-900" data-aos="fade-in">
      <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
        {/* <div className="max-w-screen-sm mx-auto mb-8 lg:mb-16">
          <h2
            className={`mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white ${l}`}
          >
            {t("varyTeam")}
          </h2>
          <p
            className={`font-light text-gray-500 sm:text-xl dark:text-gray-400 ${l}`}
          >
            {t("varyTeamDescription")}
          </p>
        </div> */}
        <div className="max-w-screen-sm mx-auto my-6 mb-8 lg:mb-16">
          <p
            className={`mb-4 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-teal-400 to-teal-600 text-center py-2 ${l}`}
          >
            {t("doctors")}
          </p>
          <p
            className={`font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center ${l}`}
          >
            {t("doctorsDescription")}
          </p>
        </div>
        <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {data
            ?.slice(0, 8)
            ?.filter((item) => item?.type === 1)
            ?.map((item, index) => (
              <div
                key={index}
                className="text-center text-gray-500 dark:text-gray-400"
              >
                <img
                  className="mx-auto mb-4 rounded-full w-44 h-36"
                  src={item.fileName}
                  alt="Bonnie Avatar"
                  style={{resize:'contain'}}
                />
                <h3
                  className={`mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white ${l}`}
                >
                  <p className={`${l}`}>
                    {l === "en-US" && item?.englishName}
                    {l === "ar" && item?.arabicName}
                    {l === "ku" && item?.kurdishName}
                  </p>
                </h3>
                <p className={`${l}`}>
                  {" "}
                  {l === "en-US" && item?.englishClinicName}
                  {l === "ar" && item?.arabicClinicName}
                  {l === "ku" && item?.kurdishClinicName}{" "}
                </p>
                <ul className="flex justify-center mt-4 space-x-4 list-none">
                  <li>
                    <span className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
                      {/* <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      />
                    </svg> */}
                      {item?.opensAt}
                    </span>
                  </li>
                  <li>-</li>
                  <li>
                    <span className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
                      {/* <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg> */}
                      {item?.closesAt}
                    </span>
                  </li>
                </ul>
              </div>
            ))}
        </div>
      </div>
      <div className="flex flex-row-reverse flex-wrap justify-center gap-4 pb-3 mx-auto md:gap-6 md:justify-end">
        <Link
          to="/doctors"
          type="button"
          title="Start buying"
          className="w-1/2 px-10 py-3 mx-auto text-center transition duration-500 rounded-lg shadow-xl md:w-1/4 lg:w-1/6 bg-gradient-to-br from-teal-400 to-teal-600 hover:bg-teal-600 active:bg-gray-700 focus:bg-teal-600 hover:scale-x-110 hover:scale-y-105"
        >
          <span className={`block font-semibold text-white ${l}`}>
            {t("viewMore")}
          </span>
        </Link>
      </div>
    </section>
  );
};

export default VaryTeam;
