import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/brand.svg";
import addIcon from "../../icons/add.svg";
import InfoCard from "../../components/Cards/Carts";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import Spiner from "../../components/Loader/Spiner";
import Cookies from "js-cookie";

// Icons
import {
  EyeIcon,
  PlusCircleIcon,
  RectangleGroupIcon,
  SwatchIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
// import { getBrand } from "../../../features/Global/Dashboard/Brands";
import { getBrand } from "../../../features/Global/Dashboard/Brands";
// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

const Index = () => {
  const [isHeadlessOpen, setIsHeadlessOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [editID, setEditId] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.BrandsDashboard.data);
  const loader = useSelector((state) => state.BrandsDashboard.loading);
  const isSuccess = useSelector((state) => state.BrandsDashboard.isSuccess);

  // Toast
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  // hook to fetch items
  useEffect(() => {
    dispatch(getBrand());
    //    console.log(data);
  }, []);
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "id", width: 70, hide: true },
    {
      field: "imageUrl",
      headerName: "image",
      width: 130,
      renderCell: (params) => (
        <img className="w-12 h-12 rounded-full" src={params.value} />
      ),
    },
    {
      field: "englishName",
      headerName: "English",
      minWidth: 200,
      flex: 1,
    },

    {
      field: "arabicName",
      headerName: "Arabic",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "kurdishName",
      headerName: "Kurdish",
      minWidth: 200,
      flex: 1,
    },
    { field: "orderInList", headerName: "Index id", width: 70 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 220,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleDelete(cellvalues?.id)}
          className={`text-center inline-flex items-center justify-center px-3 py-1 mx-auto text-sm font-medium shadow-md ${
            cellvalues.row.status === true
              ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400-600 focus:ring-emerald-500 focus:ring-offset-emerald-200 f "
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {cellvalues.row.status === true ? "Active" : "In Active"}
        </button>
      ),
    },
    // {
    //   field: "kurdish",
    //   headerName: "kurdish",
    //   width: 150,
    //   flex: 1,
    //   renderCell: (params) => <p>{params.value.kurdish}</p>,
    // },
    {
      field: "Edit",
      headerName: "Edit",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleEdit(cellvalues.id)}
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-700 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-700 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <PencilIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          Edit
        </button>
      ),
    },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 130,
    //   renderCell: (cellvalues) => (
    //     <button
    //       onClick={(e) => handleDelete(cellvalues.id)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-rose-500 hover:bg-rose-600 focus:ring-blue-700 focus:ring-offset-rose-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <TrashIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Delete
    //     </button>
    //   ),
    // },
    // {
    //   field: "View",
    //   headerName: "View",
    //   width: 130,
    //   renderCell: () => (
    //     <button
    //       onClick={() => setView(true)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full px-3 py-2 text-center text-white transition duration-200 ease-in bg-orange-500 rounded-lg shadow-md hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <EyeIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       View
    //     </button>
    //   ),
    // },
  ];
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const handleEdit = (id) => {
    setEdit(true);
    setEditId(id);
  };
  const handleDelete = (id) => {
    axios
      .delete(`api/Brands/${id}`, config)
      .then((r) => {
        // console.log(r);
        setMessage("Brand status updated Successfully.");
        if (r.status === 200) {
          setToast(true);
          dispatch(getBrand());
        }
        if (r.status === 204) {
          setToast(true);
          dispatch(getBrand());
        }
      })
      .catch((e) => {
        console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
      });
  };
  return (
    <div className="ltr">
      <main className="relative h-screen overflow-y-auto bg-gray-100 dark:bg-gray-800">
        <div className="flex items-start justify-between h-screen overflow-y-auto">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              {/* <div className="block ml-6 lg:hidden">
                <button className="flex items-center p-2 text-gray-500 bg-white rounded-full shadow text-md">
                  <svg
                    width="20"
                    height="20"
                    className="text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                  </svg>
                </button>
              </div> */}
              {/* Header */}
              <Header />
            </header>
            <div className="h-screen px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
              <div className="">
                <BreadCrumb
                  page={"Brands"}
                  icon={<SwatchIcon className="inline w-6 mx-2" />}
                />
              </div>
              <InfoCard />
              <div className="p-4 h-[44rem] mb-7 rounded-md bg-gray-50 dark:bg-gray-800">
                <button
                  onClick={() => setIsHeadlessOpen(true)}
                  type="button"
                  className="flex items-center justify-center float-right w-full px-4 py-2 text-center text-white transition duration-200 ease-in bg-teal-500 rounded-lg shadow-md w-fit hover:bg-teal-600 focus:ring-teal-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                >
                  <PlusCircleIcon
                    src={addIcon}
                    alt="add icon"
                    className="w-6 h-6 mr-2 text-white "
                  />
                  Add
                </button>{" "}
                {isHeadlessOpen && (
                  <Add
                    Title={"Add Brand"}
                    open={isHeadlessOpen}
                    setOpen={setIsHeadlessOpen}
                  />
                )}
                {edit && (
                  <Edit
                    Title={"Edit Brand"}
                    open={edit}
                    id={editID}
                    setOpen={setEdit}
                  />
                )}{" "}
                {view && (
                  <View Title={"View Brand"} open={view} setOpen={setView} />
                )}
                <br />
                <br />
                {loader && !isSuccess && <Spiner />}
                {data && (
                  <div className="flex items-center w-auto h-full mx-2 mt-2 dark:bg-gray-800">
                    {/* <DataGrid rows={data[0]} columns={columns} className="dark:bg-gray-800 dark:text-gray-200" /> */}
                    <DataGrid
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      className="min-h-full dark:text-gray-200 "
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      // checkboxSelection
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {toast && <Toast Text={message} Type={"teal"} setOpen={setToast} />}
      {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}
    </div>
  );
};

export default Index;
