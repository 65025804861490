import React, { useState, useEffect } from "react";
import { NewspaperIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../BreadCrumb";
import NewsSection from "./NewsSection";
import { Route, Link, Routes, useParams } from "react-router-dom";
import axios from "axios";
import Vary from "../../assets/images/vary.jpeg";
import Loading from "../Loader/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../../features/Global/News";
import Cookies from "js-cookie";
import Parser from "html-react-parser";
import ReactQuill from "react-quill";

const NewsDetail = () => {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const params = useParams();
  const [news, setNews] = useState();
  const data = useSelector((state) => state.News.data);
  const loader = useSelector((state) => state.News.laoding);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews());
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    axios
      .get(`api/News/${params.id}`)
      .then((r) => {
        setNews(r.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
    return () => {
      abortController.abort();
    };
  }, [params.id]);

  return (
    <div className="py-3 bg-white rounded-lg">
      <BreadCrumb Name={"information"} Iconn={NewspaperIcon} />
      {/* component */}
      <div className="mx-auto max-w-screen-2xl">
        {/* header */}

        {/* header ends here */}

        {loading ? (
          <Loading />
        ) : (
          <main className="mt-10">
            <div className="relative p-5 mx-3 max-w-screen-2xl sm:p-0">
              <div className="grid grid-cols-1 gap-10 sm:grid-cols-12">
                <div className="sm:col-span-12 lg:col-span-8">
                  <Link href="#">
                    <div
                      className="overflow-hidden text-center bg-cover"
                      // style="min-height: 500px; background-image: url('https://api.time.com/wp-content/uploads/2020/07/never-trumpers-2020-election-01.jpg?quality=85&amp;w=1201&amp;h=676&amp;crop=1')"
                      title="Woman holding a mug"
                    ></div>
                  </Link>
                  <div className="flex flex-col justify-between mt-3 leading-normal text-center bg-white rounded-b lg:rounded-b-none lg:rounded-r">
                    <div className="">
                      <Link
                        href="#"
                        className={`text-xs text-teal-600 uppercase font-medium hover:text-gray-900 transition duration-500 ease-in-out ${l}`}
                      >
                        {t("varyinformation")}
                      </Link>
                      <Link
                        href="#"
                        className={`block text-gray-900 font-bold text-2xl mb-2 hover:text-teal-600 transition duration-500 ease-in-out ${l}`}
                      >
                        {l === "en-US" && news?.englishTitle}
                        {l === "ar" && news?.arabicTitle}
                        {l === "ku" && news?.kurdishTitle}
                      </Link>
                      <p className="mx-5 mt-2 text-base text-gray-700 sm:mx-10">
                        <div className="relative w-full mx-auto mb-4 md:mb-0">
                          {/* <div className="px-4 lg:px-0">
                            <h2
                              className={`text-4xl font-semibold leading-tight text-gray-800 ${l}`}
                            ></h2>
                            <link
                              href="#"
                              className={`inline-flex items-center justify-center py-2 mb-2 text-green-700 ${l}`}
                            >
                              {t("varyInformation")}
                            </a>
                          </div> */}
                          <img
                            src={news?.fileName}
                            className="object-cover w-full rounded-lg min-h-72"
                            style={{ height: "28em" }}
                          />
                        </div>
                        <div className="flex flex-col lg:flex-row lg:space-x-12">
                          <div className="min-w-full px-4 mt-12 text-lg leading-relaxed text-gray-700 lg:px-0 lg:w-3/4">
                            <div
                              className={`pb-6 ${
                                l === "en-US" ? "" : "text-right rtl"
                              }`}
                            >
                              {l === "en-US" && Parser(news?.englishBody)}
                              {l === "ar" && Parser(news?.arabicBody)}
                              {/* {l === "ku" && Parser(news?.kurdishBody)} */}
                              {l === "ku" && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: news?.kurdishBody,
                                  }}
                                ></div>
                              )}
                            </div>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="overflow-hidden border border-gray-100 shadow-sm sm:col-span-12 lg:col-span-4 lg:overflow-y-auto h-3/4">
                  <h3 className={`text-2xl font-medium px-1 ${l}`}>
                    {t("latestInformation")}
                  </h3>
                  {data
                    ?.filter((item) => item.isActive === true)
                    .slice(0, 9)
                    .map((item) => (
                      // <div className="p-1 my-2 space-x-6 bg-white bg-opacity-50 shadow-md rounded-xl group sm:flex hover:rounded-2xl">
                      //   <img
                      //     src={item?.fileName}
                      //     alt="art cover"
                      //     loading="lazy"
                      //     width="1000"
                      //     height="667"
                      //     className="object-cover object-top w-full h-56 transition duration-500 rounded-lg sm:h-full sm:w-5/12 group-hover:rounded-xl"
                      //   />
                      //   <div className="p-1 pl-0 sm:w-7/12">
                      //     <div className="flex justify-between lg:flex-col">
                      //       <div className="space-y-4">
                      //         <p className="text-lg font-normal text-teal-900">
                      //           {l === "en-US" && item?.englishTitle}
                      //           {l === "ar" && item?.arabicTitle}
                      //           {l === "ku" && item?.kurdishTitle}
                      //         </p>
                      //         {/* <p className="text-gray-600">Laborum saepe laudantium in, voluptates ex placeat quo harum aliquam totam, doloribus eum impedit atque! Temporibus...</p> */}
                      //       </div>
                      //     </div>
                      //     <Link
                      //       to={`/information/${item?.id}`}
                      //       className="block text-teal-600 duration-500 w-max hover:underline"
                      //     >
                      //       {t("readMore")}
                      //     </Link>
                      //   </div>
                      // </div>
                      <ol>
                        <li
                          className={`${
                            l === "en-US" ? " border-l-2" : "border-r-2"
                          }  border-teal-600`}
                        >
                          <div className="md:flex flex-start">
                            {/* <div
                              className={`bg-teal-600 w-6 h-6 flex items-center justify-center rounded-full`}
                            >
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                className="w-3 h-3 text-white"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                                ></path>
                              </svg>
                            </div> */}
                            <div
                              className={`block p-6 lg:mx-4  rounded-lg overflow-hidden shadow-lg bg-gray-50 w-full hover:bg-gray-100 duration-500 hover:scale-105 ${
                                l === "en-US" ? "ml-3" : "mr-3"
                              }  mb-10`}
                            >
                              <div className="flex justify-between mb-4">
                                <Link
                                  to={`/information/${item?.id}`}
                                  className={`font-medium text-teal-600 hover:text-teal-700 focus:text-teal-800 duration-300 transition ease-in-out text-sm ${l}`}
                                >
                                  {l === "en-US" && item?.englishTitle}
                                  {l === "ar" && item?.arabicTitle}
                                  {l === "ku" && item?.kurdishTitle}
                                </Link>
                                <Link
                                  href="#!"
                                  className="text-sm font-medium text-teal-600 transition duration-300 ease-in-out hover:text-teal-700 focus:text-teal-800"
                                >
                                  {item?.updatedAt}
                                </Link>
                              </div>
                              <p
                                className={`text-gray-700 mb-6 h-10 text-sm truncate overflow-hidden ${l}`}
                              >
                                {l === "en-US" && Parser(item?.englishBody)}
                                {l === "ar" &&
                                  Parser(item?.arabicBody?.substring(0, 220))}
                                {l === "ku" &&
                                  Parser(item?.kurdishBody?.substring(0, 220))}
                              </p>
                              <Link
                                to={`/information/${item?.id}`}
                                className="inline-block px-4 py-1.5 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-teal-700 hover:shadow-lg focus:bg-teal-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-teal-800 active:shadow-lg transition ease-in-out hover:scale-y-105 hover:scale-x-105 duration-500"
                                data-mdb-ripple="true"
                              >
                                {t("readMore")}
                              </Link>
                            </div>
                          </div>
                        </li>
                      </ol>
                      // <span className="duration-300 hover:bg-gray-500">
                      //   <Link
                      //     to={`/information/${item?.id}`}
                      //     className="flex items-center pb-3 mb-3 duration-500 border-b group hover:bg-gray-50"
                      //   >
                      //     <img
                      //       src={item?.fileName}
                      //       className={`object-cover w-24 h-24 ${
                      //         l === "en-US" ? "mr-3" : "ml-3"
                      //       }  rounded-md`}
                      //     />
                      //     <span className="flex-1">
                      //       <span
                      //         className={`font-bold text-md truncate leading-tight  mb-2 ${l}  w-fit overflow-hidden`}
                      //       >
                      //         {l === "en-US" && news?.englishTitle}
                      //         {l === "ar" && news?.arabicTitle}
                      //         {l === "ku" && news?.kurdishTitle}
                      //       </span>
                      //       <div className="flex items-center">
                      //         <svg
                      //           aria-hidden="true"
                      //           focusable="false"
                      //           data-prefix="far"
                      //           data-icon="clock"
                      //           className={`h-3 ${
                      //             l === "en-US" ? "mr-1" : "ml-1"
                      //           }  group-hover:text-white`}
                      //           role="img"
                      //           xmlns="http://www.w3.org/2000/svg"
                      //           viewBox="0 0 512 512"
                      //         >
                      //           <path
                      //             fill="currentColor"
                      //             d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                      //           />
                      //         </svg>
                      //         <span
                      //           className={`text-md truncate sm:w-1/6 w-1/12 px-2 overflow-hidden ${l}  `}
                      //         >
                      //           {l === "en-US" && news?.englishBody}
                      //           {l === "ar" &&
                      //             news?.arabicBody?.substring(0, 220)}
                      //           {l === "ku" &&
                      //             news?.kurdishBody?.substring(0, 220)}
                      //         </span>
                      //       </div>
                      //     </span>
                      //   </Link>
                      // </span>

                      // <div className="mb-2">
                      //     <Link href="#">
                      //       <img
                      //         className="h-40 overflow-hidden text-center bg-cover rounded-md"
                      //         // style={`background-image: url('${item?.fileName}')`}
                      //         style={divStyle(item?.fileName)}
                      //         title="Woman holding a mug"
                      //         src={item?.fileName}
                      //       />
                      //     </Link>
                      //     <Link
                      //       href="#"
                      //       className="inline-block my-2 font-semibold text-gray-900 transition duration-500 ease-in-out text-md hover:text-teal-600"
                      //     >
                      //       {l === "en-US" && news?.englishTitle}
                      //       {l === "ar" && news?.arabicTitle}
                      //       {l === "ku" && news?.kurdishTitle}{" "}
                      //     </Link>
                      //   </div>
                    ))}
                </div>
              </div>
            </div>
          </main>
        )}

        {/* main ends here */}
        {/* footer */}
      </div>
    </div>
  );
};

export default NewsDetail;
