import React, {useState, useEffect} from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderAnim from "./ask.json";
const SnackBar = () => {
  const l = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
const [show, setshow] = useState(true)
useEffect(() => {
  setTimeout(() => {
    setshow(false);
  }, 4000);
}, [])

  return (
      <div
      className={`fixed z-40  bottom-5 ${
          l === "en-US" ? "right-3" : "left-3"
        }`}
    >
        {show && <>
      <div className="flex flex-col px-6 py-2 bg-white border shadow-md hover:shodow-lg rounded-2xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Player src={loaderAnim} autoplay loop className="w-32 player " />
            <div className="flex flex-col ml-3">
              <div className={`font-medium leading-none ${l}`}>
                {t("questionSentSuccesffully")}
              </div>
              {/* <p className={`text-sm text-gray-600 leading-none mt-1 ${l}`}>
                {t("nowYouCanShopWithUs")}
              </p> */}
            </div>
          </div>
          {/* <button className="px-5 py-2 ml-4 text-sm font-medium tracking-wider text-white bg-red-500 border-2 border-red-500 rounded-full shadow-sm flex-no-shrink hover:shadow-lg">
            Delete
          </button> */}
        </div>
      </div>
      </>}
    </div>
  );
};

export default SnackBar;
