import { GlobeAmericasIcon, LightBulbIcon } from "@heroicons/react/20/solid";
import {
  BanknotesIcon,
  BeakerIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckBadgeIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  SparklesIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import style from "./featueres.css";
const Features = () => {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  return (
    <div data-aos="fade-in">
      <div className="py-4 my-3 ">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          {/* <div className="text-center">
            <p
              className={`max-w-2xl mt-4 text-lg leading-7 text-gray-500 lg:mx-auto font-regular feture-text ${l}`}
            >
              {t("features")}
            </p>
            <h3
              className={`mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10 ${l}`}
            >
              {t("ourFeatures")}
            </h3>
          </div> */}
          <div className="max-w-screen-sm mx-auto my-6 mb-8 lg:mb-16">
            <p
              className={`mb-4 text-4xl font-semibold tracking-tight bg-clip-text text-transparent bg-gradient-to-br from-teal-400 to-teal-600 text-center py-2 ${l}`}
            >
              {t("features")}
            </p>
            <p
              className={`font-light text-gray-500 sm:text-xl dark:text-gray-400 text-center ${l}`}
            >
              {t("ourFeatures")}
            </p>
          </div>
          {/* <div className="mt-10 sm:mt-24">
            <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:col-gap-8 md:row-gap-10">
              <li className="my-10 md:mt-0">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-teal-500 rounded-md">
                      <svg
                        className="w-12 h-12"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <p className="feture-text"ath
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-500">
                      {t("featureName")}
                    </h4>
                  </div>
                </div>
              </li>
              <li className="my-10 md:mt-0">
                <div className="flex flex-col items-center justify-center ">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-teal-500 rounded-md">
                      <svg
                        className="w-10 h-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <p className="feture-text"ath
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-500">
                      {t("featureName")}
                    </h4>
                  </div>
                </div>
              </li>
              <li className="my-10 md:mt-0">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-teal-500 rounded-md">
                      <svg
                        className="w-10 h-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <p className="feture-text"ath
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-500">
                      {t("featureName")}
                    </h4>
                  </div>
                </div>
              </li>
              <li className="my-10 md:mt-0">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-teal-500 rounded-md">
                      <svg
                        className="w-10 h-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <p className="feture-text"ath
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-500">
                      {t("featureName")}
                    </h4>
                  </div>
                </div>
              </li>
              <li className="my-10 md:mt-0">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-teal-500 rounded-md">
                      <svg
                        className="w-10 h-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <p className="feture-text"ath
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-500">
                      {t("featureName")}
                    </h4>
                  </div>
                </div>
              </li>
              <li className="my-10 md:mt-0">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-teal-500 rounded-md">
                      <svg
                        className="w-10 h-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <p className="feture-text"ath
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-500">
                      {t("featureName")}
                    </h4>
                  </div>
                </div>
              </li>
              <li className="my-10 md:mt-0">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-teal-500 rounded-md">
                      <svg
                        className="w-10 h-10"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <p className="feture-text"ath
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-500">
                      {t("featureName")}
                    </h4>
                  </div>
                </div>
              </li>
              <li className="my-10 md:mt-0">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-teal-500 rounded-md">
                      <svg
                        className="w-12 h-12"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <p className="feture-text"ath
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-500">
                      {t("featureName")}
                    </h4>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}

          <section>
            <div className="row">
              <div className="column">
                <div className="feature-card">
                  <div className="icon-wrapper">
                    <ClockIcon className="w-10 font-medium" />
                  </div>
                  <h3 className={`feture-h3 ${l}`}>{t("featureName1")}</h3>
                  <p className={`feture-text ${l}`}>{t("featureDesc1")}</p>
                </div>
              </div>
              <div className="column">
                <div className="feature-card">
                  <div className="icon-wrapper">
                    <QuestionMarkCircleIcon className="w-10 font-medium" />
                  </div>
                  <h3 className={`feture-h3 ${l}`}>{t("askVary")}</h3>
                  <p className={`feture-text ${l}`}>{t("askVaryDescription")}</p>
                </div>
              </div>
              <div className="column">
                <div className="feature-card">
                  <div className="icon-wrapper">
                    <BeakerIcon className="w-10 font-medium" />
                  </div>
                  <h3 className={`feture-h3 ${l}`}>{t("featureName3")}</h3>
                  <p className={`feture-text ${l}`}>{t("featureDesc3")}</p>
                </div>
              </div>
              <div className="column">
                <div className="feature-card">
                  <div className="icon-wrapper">
                    <SparklesIcon className="w-10 font-medium" />
                  </div>
                  <h3 className={`feture-h3 ${l}`}>{t("featureName4")}</h3>
                  <p className={`feture-text ${l}`}>{t("featureDesc4")}</p>
                </div>
              </div>
              <div className="column">
                <div className="feature-card">
                  <div className="icon-wrapper">
                    <CheckBadgeIcon className="w-10 font-medium" />
                  </div>
                  <h3 className={`feture-h3 ${l}`}>{t("featureName5")}</h3>
                  <p className={`feture-text ${l}`}>{t("featureDesc5")}</p>
                </div>
              </div>
              <div className="column">
                <div className="feature-card">
                  <div className="icon-wrapper">
                    <LightBulbIcon className="w-10 font-medium" />
                  </div>
                  <h3 className={`feture-h3 ${l}`}>{t("featureName6")}</h3>
                  <p className={`feture-text ${l}`}>{t("featureDesc6")}</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Features;
