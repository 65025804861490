import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
  },
};
export const getSubCategory = createAsyncThunk(
  "getSubCategory",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const { data } = await axios.get("api/controlPane/SubCategories", config);

      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    copyData: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    selectCategory: (state, action) => {
      state.copyData = state.data.filter(
        (sub) => sub.categoryId === 5
      );
// console.log(state.copyData);    
},
    removeCategoryFilter: (state, action) => {
      state.copyData = state.data;
    },
  },
  extraReducers: {
    [getSubCategory.pending]: (state, action) => {
      state.loading = true;
    },
    [getSubCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getSubCategory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});
export const { selectCategory, removeCategoryFilter } = loremSlice.actions;

export default loremSlice;
