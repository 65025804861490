import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getBrand } from "../../../features/Global/Brands";
import { TextareaAutosize } from "@mui/material";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, chilren, id }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [file, setFile] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category.data);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  useEffect(() => {
    axios.get(`api/Brands/${id}`).then((r) => {
      console.log("data", r.data);
      setValue("englishName", r.data.name.english, { shouldDirty: true });
      setValue("arabicName", r.data.name.arabic, { shouldDirty: true });
      setValue("kurdishName", r.data.name.kurdish, { shouldDirty: true });
    });
  }, [id]);

  const onSubmit = (data) => {
    ////    console.log(data);

    setDisable(true);
    const form = new FormData();
    form.append("id", id);
    form.append("englishName", data.englishName);
    form.append("arabicName", data.arabicName);
    form.append("kurdishName", data.kurdishName);
    form.append("File", file);
    axios
      .put("api/Brands", form, config)
      .then((r) => {
        setDisable(false);
        if (r.status === 200) {
          setToast(true);
          dispatch(getBrand());
        }
      })
      .catch((e) => {
        setDisable(false);
        // console.log(e.response.status);
        // console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
      });
  };
  useEffect(() => {
    dispatch(getBrand());
  }, []);

  const [preview, setPreview] = useState();
  const handleSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-indigo-600 rounded-sm sm:px-6 dark:bg-indigo-700">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PlusCircleIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    {/* <hr className="mx-1 text-gray-300 dark:text-blue-700" /> */}
                    <div className="relative flex-1 px-4 mt-2 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Image
                                </label>

                                <div className="mb-8">
                                  <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="sr-only"
                                    onChange={(e) => handleSelect(e)}
                                  />
                                  <label
                                    htmlFor="file"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                    <div>
                                      {/*                       <span className="mb-2 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                        Drop files here
                                      </span> */}
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        {preview && (
                                          <img
                                            src={preview}
                                            alt="image"
                                            className="mx-auto rounded-lg "
                                          />
                                        )}
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name english"
                                  {...register("englishName", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name arabic"
                                  {...register("arabicName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Name:
                                </label>
                                <input
                                  type="text"
                                  {...register("kurdishName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  placeholder="product name kurdish"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="float-right pt-2 pb-4">
                                <button
                                  type="submit"
                                  disabled={disable}
                                  className={
                                    "bg-blue-100 mx-1 px-6 py-2 rounded-md text-blue-600 hover:bg-blue-200 hover:text-blue-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:text-blue-100 dark:bg-blue-600"
                                  }
                                >
                                  <PencilIcon className="inline w-6 h-6 mx-2" />
                                  Update
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-rose-100 mx-1 px-4 py-2 rounded-md text-rose-600 hover:bg-rose-200 hover:text-rose-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                                  }
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                </button>
                              </div>
                              <br />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {toast && (
                      <Toast
                        Text={"Brand Updated Successfully."}
                        Type={"blue"}
                        setOpen={setToast}
                      />
                    )}
                    {error && (
                      <Toast Text={message} Type={"rose"} setOpen={setError} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
