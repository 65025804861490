import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "axios";
const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};
const locale = localStorage.getItem("i18nextLng");
export const getOffersItem = createAsyncThunk(
  "getOffersItem",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const { data } = await axios.get("api/Products", config);
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    copyData: [],
    loading: true,
    isSuccess: false,
    message: "",
  },
  reducers: {
    filteredItem: (state, action) => {
      state.copyData = state.data?.filter(
        (item) =>
          item.subCategory.english
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.subCategory.arabic
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.subCategory.kurdish
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.name.english
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.name.arabic
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.name.kurdish
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.brand.english
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.brand.arabic
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.brand.kurdish
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.tags.toLowerCase().includes(action.payload) ||
          item.price === action.payload
        // item.qty.toLowerCase().includes(action.payload)
      );
    },
    selectFilter: (state, action) => {
      // console.log("payload", action.payload);
      state.copyData = state.data.filter((item) =>
        action.payload.includes(item.subCategoryId)
      );
    },
    selectCategoryItemFilter: (state, action) => {
      // console.log("fillter", action.payload);
      // console.log("data", state.copyData);
      state.copyData = state.data.filter(
        (item) => item.categoryId === action.payload
      );
      // console.log("length", state.copyData?.length);
    },
    selectStateInStock: (state) => {
      state.copyData = state.data.filter((item) => item.qty > 1);
    },
    selectStateOutStock: (state) => {
      state.copyData = state.data.filter((item) => item.qty < 1);
    },
    sort: (state, action) => {
      if (action.payload === "A") {
        state.copyData.sort(function (a, b) {
          if (locale === "en-US") {
            if (a.name?.english.toLowerCase() < b.name?.english.toLowerCase())
              return -1;
            if (a.name?.english.toLowerCase() > b.name?.english.toLowerCase())
              return 1;
            return 0;
          } else if (locale === "ar") {
            if (a.name?.arabic.toLowerCase() < b.name?.arabic.toLowerCase())
              return -1;
            if (a.name?.arabic.toLowerCase() > b.name?.arabic.toLowerCase())
              return 1;
            return 0;
          } else if (locale === "ku") {
            if (a.name?.kurdish.toLowerCase() < b.name?.kurdish.toLowerCase())
              return -1;
            if (a.name?.kurdish.toLowerCase() > b.name?.kurdish.toLowerCase())
              return 1;
            return 0;
          }
        });
      } else if (action.payload === "Z") {
        state.copyData.sort(function (a, b) {
          if (locale === "en-US") {
            if (a.name?.english.toLowerCase() > b.name?.english.toLowerCase())
              return -1;
            if (a.name?.english.toLowerCase() < b.name?.english.toLowerCase())
              return 1;
            return 0;
          } else if (locale === "ar") {
            if (a.name?.arabic.toLowerCase() > b.name?.arabic.toLowerCase())
              return -1;
            if (a.name?.arabic.toLowerCase() < b.name?.arabic.toLowerCase())
              return 1;
            return 0;
          } else if (locale === "ku") {
            if (a.name?.kurdish.toLowerCase() > b.name?.kurdish.toLowerCase())
              return -1;
            if (a.name?.kurdish.toLowerCase() < b.name?.kurdish.toLowerCase())
              return 1;
            return 0;
          }
        });
      } else if (action.payload === "C") {
        state.copyData = state.data;
      } else if (action.payload === "H") {
        state.copyData.sort(function (a, b) {
          if (a.price > b.price) return -1;
          if (a.price < b.price) return 1;
          return 0;
        });
      } else if (action.payload === "L") {
        state.copyData.sort(function (a, b) {
          if (a.price < b.price) return -1;
          if (a.price > b.price) return 1;
          return 0;
        });
      }
    },
    removeFilter: (state) => {
      state.copyData = state.data;
    },
  },
  extraReducers: {
    [getOffersItem.pending]: (state, action) => {
      state.loading = true;
    },
    [getOffersItem.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.filter((item) => item.isOfferActive === true);
      state.copyData = payload?.filter((item) => item.isOfferActive === true);
      state.isSuccess = true;
    },
    [getOffersItem.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});
export const {
  filteredItem,
  selectFilter,
  removeFilter,
  selectStateInStock,
  selectStateOutStock,
  selectCategoryItemFilter,
} = loremSlice.actions;
export default loremSlice;
