import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import usalogo from "../../../web/assets/icons/usa.svg";
import krlogo from "../../assets/images/flag/kurdistan.png";
import irlogo from "../../assets/images/flag/iraq.png";
import i18next, { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { LanguageIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
function MobileLocale() {
  const rtlLanguages = ["ar", "ku"];
  const selected = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const locale = localStorage.getItem("i18nextLng");
  function setPageDirection(selected) {
    const dir = rtlLanguages.includes(selected) ? "rtl" : "ltr";
    document.documentElement.dir = dir;
  }
  const languages = [
    // {
    //   key: 1,
    //   code: "en-US",
    //   name: "English",
    //   country_code: "en-US",
    //   dir: "ltr",
    //   image: usalogo,
    // },
    {
      key: 2,
      code: "ku",
      name: "کوردی (سۆرانی)",
      dir: "rtl",
      country_code: "ku",
      image: krlogo,
    },

    {
      key: 3,
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
      image: irlogo,
    },
  ];
  const changeLanguageCustom = (code) => {
    i18next.changeLanguage(code);
    // Cookies.set('i18nextLng', code);
    localStorage.setItem("i18nextLng", code);
  };
  return (
    <div className="flex md:hidden">
      {languages.map(({ code, name, country_code, image, index }) => (
        <>
          {({ active }) => (
            <span
              key={country_code}
              className={`${
                active ? "bg-teal-500 text-black" : "text-gray-900"
              } group flex w-full items-center rounded-md px-2 py-2 text-sm ${
                code === "en-US" ? "" : locale
              }`}
            >
              {active ? (
                <img
                  src={image}
                  alt="flag image"
                  className="inline w-6 h-6 rounded-md"
                />
              ) : (
                <img
                  src={image}
                  alt="flag image"
                  className="inline w-6 h-6 rounded-md"
                />
              )}
            </span>
          )}
        </>
      ))}
    </div>
  );
}

export default MobileLocale;
