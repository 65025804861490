import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getMainReport = createAsyncThunk(
  "getMainReport",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get('dashboardToken')}`,
        },
      };
      const { data } = await axios.get("api/Report/get", config);
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    copyData: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    filteredItem: (state, action) => {
      state.copyData = state.data.filter(
        (item) =>
          item.name.toLowerCase().includes(action.payload?.toLowerCase()) ||
          item.subCategory
            .toLowerCase()
            .includes(action.payload?.toLowerCase()) ||
          item.category.toLowerCase().includes(action.payload?.toLowerCase()) ||
          item.brand.toLowerCase().includes(action.payload?.toLowerCase()) ||
          item.totalPrice === action.payload ||
          item.price === action.payload ||
          item.qty === action.payload ||
          item.totalOrdered === action.payload
      );
    },
    filterDate: (state, action) => {
      state.copyData = state.data.filter(
        (item) =>
          item?.orderDate > action.payload[0] &&
          item?.orderDate < action.payload[1]
      );
    },
    removeFilter: (state) => {
      state.copyData = state.data;
    },
  },
  extraReducers: {
    [getMainReport.pending]: (state, action) => {
      state.loading = true;
    },
    [getMainReport.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getMainReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});
export const { filteredItem, removeFilter, filterDate } = loremSlice.actions;
export default loremSlice;
