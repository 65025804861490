import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getSubCategory } from "../../../features/Global/SubCategory";
import { getBrand } from "../../../features/Global/Brands";
import { getItem } from "../../../features/Global/Items";
import Cookies from "js-cookie";

import { TextareaAutosize } from "@mui/material";

export default function SlideOver({ Title, open, setOpen, chilren, id }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const category = useSelector((state) => state.SubCategory.data);
  const brand = useSelector((state) => state.Brands.data);
  const [englishTags, setEnglishTags] = useState("");
  const [arabicTags, setArabicTags] = useState("");
  const [kurdishTags, setKurdishTags] = useState("");
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    axios.get(`api/Products/${id}`).then((r) => {
      console.log(r.data);
      setValue("englishName", r.data.name.english);
      setValue("arabicName", r.data.name.arabic);
      setValue("kurdishName", r.data.name.kurdish);
      setValue("englishDescription", r.data.description.english);
      setValue("arabicDescription", r.data.description.arabic);
      setValue("kurdishDescription", r.data.description.kurdish);
      setValue("englishUsage", r.data.usage.english);
      setValue("arabicUsage", r.data.usage.arabic);
      setValue("kurdishUsage", r.data.usage.kurdish);
      setValue("subCategoryId", r.data.subCategoryId);
      setValue("brandId", r.data.brandId);
      setValue("offerType", r.data.offerType);
      setValue("qty", r.data.qty);
      setValue("price", r.data.price);
      setValue("isOfferActive", r.data.isOfferActive);
      // setValue("tags", r.data.tags);
      const myArray = r.data.tags.split("|");
      // console.log(myArray);
      setEnglishTags(myArray[0]);
      setArabicTags(myArray[1]);
      setKurdishTags(myArray[2]);
    });
  }, [id]);
  const onSubmit = (data) => {
    setDisable(true);
    data.id = id;
    data.Tags = `${englishTags} | ${arabicTags} | ${kurdishTags}`;
    data.isOfferActive = data.isOfferActive === "true" ? true : false;
    data.isApplicableForOffers =
      data.isApplicableForOffers === "true" ? true : false;
    // var form = new FormData();
    // form.append("EnglishName", data.englishName);
    // form.append("KurdishName", data.kurdishDescription);
    // form.append("ArabicName", data.arabicName);
    // form.append("EnglishDescription", data.englishDescription);
    // form.append("KurdishDescription", data.kurdishDescription);
    // form.append("ArabicDescription", data.arabicDescription);
    // form.append("EnglishUsage", data.englishUsage);
    // form.append("KurdishUsage", data.kurdishUsage);
    // form.append("ArabicUsage", data.arabicUsage);
    // form.append("CategoryId", data.categoryId);
    // form.append("BrandId", data.brandId);
    // form.append("Qty", data.qty);
    // form.append("Price", data.price);
    // form.append("tags", data.tags);
    // form.append("Files", file);
    // form.append("Id", id);
    axios
      .put("api/Products", data, config)
      .then((r) => {
        setDisable(false);
        if (r.status === 200) {
          setToast(true);
          dispatch(getItem());
        }
      })
      .catch((e) => {
        setDisable(false);
        console.log(e);
        setMessage(e.response?.data);
        setError(true);
      });
  };
  useEffect(() => {
    dispatch(getSubCategory());
    dispatch(getBrand());
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-blue-600 rounded-sm sm:px-6 dark:bg-blue-700">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PencilIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    {/* <hr className="mx-1 text-gray-300 dark:text-blue-700" /> */}
                    <div className="relative flex-1 px-4 mt-2 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              {/* <div className="pt-4 mb-6">
                                <label className="mb-5 block text-xl font-semibold text-[#07074D] dark:text-gray-300">
                                  Upload Image
                                </label>

                                <div className="mb-8">
                                  <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    required
                                    className="sr-only"
                                    onChange={(e) => handleFileSelect(e)}
                                  />
                                  <label
                                    htmlFor="file"
                                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                  >
                                  
                                      <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                        {preview && (
                                          <img
                                            src={preview}
                                            alt="image"
                                            className="mx-auto rounded-lg "
                                          />
                                        )}
                                      </span>
                                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                        Browse
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div> */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name english"
                                  {...register("englishName", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name arabic"
                                  {...register("arabicName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Name:
                                </label>
                                <input
                                  type="text"
                                  placeholder="product name kurdish"
                                  {...register("kurdishName", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishName && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              {/* Descrition */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Description:
                                </label>
                                <TextareaAutosize
                                  rows={5}
                                  type="text"
                                  placeholder="product description english"
                                  {...register("englishDescription", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishDescription &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Description:
                                </label>
                                <TextareaAutosize
                                  rows={5}
                                  type="text"
                                  placeholder="product description arabic"
                                  {...register("arabicDescription", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicDescription &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Description:
                                </label>
                                <TextareaAutosize
                                  rows={5}
                                  type="text"
                                  placeholder="product name kurdish"
                                  {...register("kurdishDescription", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishDescription &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              {/* Description */}
                              {/* Usage */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Usage:
                                </label>
                                <TextareaAutosize
                                  type="text"
                                  placeholder="product usage english"
                                  {...register("englishUsage", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishUsage && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Usage:
                                </label>
                                <TextareaAutosize
                                  type="text"
                                  placeholder="product usage arabic"
                                  {...register("arabicUsage", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicUsage && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Usage:
                                </label>
                                <TextareaAutosize
                                  type="text"
                                  placeholder="product usage kurdish"
                                  {...register("kurdishUsage", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishUsage && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              {/* Tags */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Tags:
                                </label>
                                <input
                                  value={englishTags}
                                  type="text"
                                  placeholder="product tags in english"
                                  onChange={(e) =>
                                    setEnglishTags(e.target.value)
                                  }
                                  // {...register("tags", {
                                  //   required: true,
                                  // })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.tags && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Tags:
                                </label>
                                <input
                                  type="text"
                                  value={arabicTags}
                                  onChange={(e) =>
                                    setArabicTags(e.target.value)
                                  }
                                  placeholder="product tags arabic"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Tags:
                                </label>
                                <input
                                  value={kurdishTags}
                                  type="text"
                                  onChange={(e) =>
                                    setKurdishTags(e.target.value)
                                  }
                                  placeholder="product tags arabic"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicName &&
                                    "border-rose-500 dark:border-rose-400"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              {/* End-Tags */}
                              {/* Numerics */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Sub Category
                                </label>
                                <select
                                  {...register("subCategoryId", {
                                    required: true,
                                  })}
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.subCategoryId && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  <option value="null" selected>
                                    select subcategory
                                  </option>
                                  {category?.map((item) => (
                                    <option
                                      value={item.id}
                                      // {...register("subCategoryId", {
                                      //   required: true,
                                      // })}
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                        errors.subCategoryId &&
                                        "border-rose-500"
                                      } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {item.englishSubCategory}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Brand
                                </label>
                                <select
                                  {...register("brandId", {
                                    required: true,
                                  })}
                                  placeholder="Select category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.brandId && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  {/* <option value="null" selected>
                                    select category
                                  </option> */}
                                  {brand?.map((item) => (
                                    <option
                                      value={item.id}
                                      className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                        errors.brandId && "border-rose-500"
                                      } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                    >
                                      {item.name.english}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Offer Type
                                </label>
                                <select
                                  {...register("offerType", {
                                    required: true,
                                  })}
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.offerType && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  <option
                                    value={0}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    No Offer
                                  </option>
                                  <option
                                    value={1}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Buy one get one
                                  </option>{" "}
                                  <option
                                    value={2}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Percentage
                                  </option>
                                  {/* <option
                                    value={3}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Buy one get some percenteage of
                                  </option>*/}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Offer Status
                                </label>
                                <select
                                  {...register("isOfferActive", {
                                    required: true,
                                  })}
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.isOfferActive && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  <option
                                    value={"false"}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.isOfferActive && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    In Active
                                  </option>
                                  <option
                                    value={"true"}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.offerType && "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Active
                                  </option>{" "}
                                </select>
                              </div>
                              {/*  */}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Item Applicable For Offers
                                </label>
                                <select
                                  {...register("isApplicableForOffers", {
                                    required: true,
                                  })}
                                  placeholder="Select sub category"
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.isApplicableForOffers &&
                                    "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                >
                                  <option
                                    value={"false"}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.isApplicableForOffers &&
                                      "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Not Applicable
                                  </option>
                                  <option
                                    value={"true"}
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                      errors.isApplicableForOffers &&
                                      "border-rose-500"
                                    } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                  >
                                    Applicable
                                  </option>{" "}
                                </select>
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Price:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Price"
                                  {...register("price", {
                                    required: true,
                                    type: Number,
                                    pattern: /[0-9]/,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.price && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Quantity:
                                </label>
                                <input
                                  type="number"
                                  placeholder="Quantity"
                                  {...register("qty", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.qty && "border-rose-500"
                                  } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="float-right pt-2 pb-4">
                                {/* <button className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none">
                                  Send File
                                </button> */}
                                <button
                                  type="submit"
                                  disabled={disable}
                                  className={
                                    "bg-blue-100 mx-1 px-6 py-2 rounded-md text-blue-600 hover:bg-blue-200 hover:text-blue-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:text-blue-100 dark:bg-blue-600"
                                  }
                                  // {...props}
                                >
                                  <PencilIcon className="inline w-6 h-6 mx-2" />
                                  Update
                                  {/* {children} */}
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-rose-100 mx-1 px-4 py-2 rounded-md text-rose-600 hover:bg-rose-200 hover:text-rose-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                                  }
                                  // {...props}
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                  {/* {children} */}
                                </button>
                              </div>
                              <br />
                            </form>
                            {toast && (
                              <Toast
                                Text={"Subcategory Update Successfully"}
                                Type={"blue"}
                                setOpen={setToast}
                              />
                            )}
                            {error && (
                              <Toast
                                Text={message}
                                Type={"rose"}
                                setOpen={setError}
                              />
                            )}
                          </div>
                        </div>

                        {/* <div className=F"h-full border-2 border-gray-200 border-dashed" aria-hidden="true" /> */}
                      </div>
                      {/* {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
