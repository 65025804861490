import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";
import Cookies from "js-cookie";
const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const isLogedIn = useSelector((state) => state.Auth.value);
  return Cookies.get("webAuth")?.length > 0 ||
    (isLogedIn !== true && isLogedIn !== "") ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
