import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { getConfiguration } from "../../../features/Global/Configuration";
import { useDispatch } from "react-redux";
import { TextareaAutosize } from "@mui/material";
import { getContact } from "../../../features/Global/ContactUs";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, chilren, id }) {
  const [status, setStaus] = useState();
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  useEffect(() => {
    axios.get(`api/Contact/${id}`).then((response) => {
      setValue("englishAddress", response?.data?.englishAddress, {
        shouldDirty: true,
      });
      setValue("arabicAddress", response?.data?.arabicAddress, {
        shouldDirty: true,
      });
      setValue("kurdishAddress", response?.data?.kurdishAddress, {
        shouldDirty: true,
      });
      setValue("emailAddress", response?.data?.emailAddress, {
        shouldDirty: true,
      });
      setValue("primaryPhoneNumber", response?.data?.primaryPhoneNumber, {
        shouldDirty: true,
      });
      setValue("secondaryPhoneNumber", response?.data?.secondaryPhoneNumber, {
        shouldDirty: true,
      });
      setValue("englsihBanner", response?.data?.englsihBanner, {
        shouldDirty: true,
      });
      setValue("arabicBanner", response?.data?.arabicBanner, {
        shouldDirty: true,
      });
      setValue("kurdishBanner", response?.data?.kurdishBanner, {
        shouldDirty: true,
      });
      setStaus(response?.data?.bannerStatus);
    });
  }, [id]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setDisable(true);
    ////    console.log(data);

    data.id = id;
    data.bannerStatus = status;
    axios
      .put("api/Contact", data, config)
      .then((r) => {
        setDisable(false);
        if (r.status === 200 || r.status === 204) {
          setMessage("Contact Us Updated Successfully.");
          dispatch(getContact());
          setToast(true);
        }
      })
      .catch((e) => {
        setDisable(false);
        console.log(e.response.status);
        console.log(e.response);
        setMessage(e.response.data);
        dispatch(getContact());

        setError(true);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-blue-600 rounded-sm sm:px-6 dark:bg-blue-700">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <PencilIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    {/* <hr className="mx-1 text-gray-300 dark:text-blue-700" /> */}
                    <div className="relative flex-1 px-4 mt-2 sm:px-6">
                      {/* Replace with your content */}

                      <div className="absolute inset-0 px-4 sm:px-6 ">
                        {/* <!-- component --> */}
                        <div className="">
                          {/* <!-- Author: FormBold Team --> */}
                          {/* <!-- Learn More: https://formbold.com --> */}
                          <div className="mx-auto w-full max-w-[550px] bg-white dark:bg-gray-800">
                            <form
                              className="px-1 py-6"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Address:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Address english"
                                  {...register("englishAddress", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englishAddress && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Address:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Address arabic"
                                  {...register("arabicAddress", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicAddress && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Address:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Address kurdish"
                                  {...register("kurdishAddress", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishAddress && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Email:
                                </label>
                                <input
                                  type="text"
                                  placeholder="emailAddress"
                                  {...register("emailAddress", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.emailAddress && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Primary Phone Number:
                                </label>
                                <input
                                  type="tel"
                                  placeholder="Primary phone number"
                                  {...register("primaryPhoneNumber", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.primaryPhoneNumber &&
                                    "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Secondary Phone Number:
                                </label>
                                <input
                                  type="tel"
                                  placeholder="Secondary phone number"
                                  {...register("secondaryPhoneNumber", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.secondaryPhoneNumber &&
                                    "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  English Banner:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Banner english"
                                  {...register("englsihBanner", {
                                    required: true,
                                  })}
                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.englsihBanner && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Arabic Banner:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Banner arabic"
                                  {...register("arabicBanner", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.arabicBanner && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>{" "}
                              <div className="mb-5">
                                <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  Kurdish Banner:
                                </label>
                                <input
                                  type="text"
                                  placeholder="Banner kurdish"
                                  {...register("kurdishBanner", {
                                    required: true,
                                    pattern: /[\u0600-\u06FF]{1}/,
                                  })}                                   style={{ unicodeBidi: "plaintext" }}

                                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                    errors.kurdishBanner && "border-rose-500"
                                  } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-500 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                                />
                              </div>
                              <div className="mb-5">
                                <div className="mb-5">
                                  <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                    Banner Status:
                                  </label>
                                  <div className="mb-6 flex flex-col gap-y-2 gap-x-4 lg:flex-row">
                                    <div className="relative flex w-56 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 font-medium text-gray-700">
                                      <input
                                        className="peer hidden"
                                        type="radio"
                                        name="radio"
                                        id="radio1"
                                        onClick={() => setStaus(true)}
                                        checked={status === true ? true : false}
                                      />
                                      <label
                                        className="peer-checked:border-blue-600 peer-checked:bg-blue-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border"
                                        htmlFor="radio1"
                                      >
                                        {" "}
                                      </label>
                                      <div className="peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2 absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2"></div>
                                      <span className="pointer-events-none z-10">
                                        Show
                                      </span>
                                    </div>
                                    <div className="relative flex w-56 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 font-medium text-gray-700">
                                      <input
                                        className="peer hidden"
                                        type="radio"
                                        name="radio"
                                        id="radio3"
                                        onClick={() => setStaus(false)}
                                        checked={
                                          status === false ? true : false
                                        }
                                      />
                                      <label
                                        className="peer-checked:border-rose-600 peer-checked:bg-rose-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border"
                                        htmlFor="radio3"
                                      >
                                        {" "}
                                      </label>
                                      <div className="peer-checked:border-transparent peer-checked:bg-rose-600 peer-checked:ring-2 absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-rose-600 ring-offset-2"></div>
                                      <span className="pointer-events-none z-10">
                                        Hide
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="float-right">
                                <button
                                  type="submit"
                                  disabled={disable}
                                  className={
                                    "bg-blue-100 mx-1 px-6 py-2 rounded-md text-blue-600 hover:bg-blue-200 hover:text-blue-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:text-blue-100 dark:bg-blue-600"
                                  }
                                  // {...props}
                                >
                                  <PencilIcon className="inline w-6 h-6 mx-2" />
                                  Update
                                  {/* {children} */}
                                </button>
                                <button
                                  onClick={() => setOpen(false)}
                                  type="button"
                                  className={
                                    "bg-rose-100 mx-1 px-4 py-2 rounded-md text-rose-600 hover:bg-rose-200 hover:text-rose-700 border border-transparent focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent dark:text-rose-100 dark:bg-rose-600"
                                  }
                                  // {...props}
                                >
                                  <XCircleIcon className="inline w-6 h-6 mx-2" />
                                  Cancel
                                  {/* {children} */}
                                </button>
                              </div>
                            </form>
                            {toast && (
                              <Toast
                                Text={message}
                                Type={"blue"}
                                setOpen={setToast}
                              />
                            )}
                            {error && (
                              <Toast
                                Text={message}
                                Type={"rose"}
                                setOpen={setError}
                              />
                            )}
                          </div>
                        </div>

                        {/* <div className=F"h-full border-2 border-gray-200 border-dashed" aria-hidden="true" /> */}
                      </div>
                      {/* {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
