import React, { useState, useEffect, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowPathIcon,
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckIcon,
  MicrophoneIcon,
  PaperAirplaneIcon,
  PhotoIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

import sty from "./style.css";
import { getMessages } from "../../../features/Global/Messages";
import { Connect } from "../../../features/Global/Chat";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SnackBar from "../Snackbar/SnackBarDynamic";
import ChatStatus, { setChatStatus } from "../../../features/web/ChatStatus";
import { motion } from "framer-motion";
const ChatPopupTailwind = () => {
  const messagesEndRef = useRef(null);
  const locale = localStorage.getItem("i18nextLng");
  const [scrolled, setSetScolled] = useState(false);
  const [message, setMessage] = useState("");
  let data = useSelector((state) => state.Messages.data);
  // const connection = useSelector((state) => state.Chat.value);
  const [connection, setConnection] = useState("");
  const reversed = data?.slice()?.reverse();
  const [open, setOpen] = useState(false);
  const [newMessage, setnewMessage] = useState(false);
  const [user, setUser] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const chatStatus = useSelector((state) => state.ChatStatus.value);

  // console.log(user);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };
  const bottomRef = useRef(null);
  useEffect(() => {
    // console.log("set");
    if (Cookies.get("Customer")) {
      setUser(JSON?.parse(Cookies.get("Customer")));
    }
    if (chatStatus) {
      axios
        .post("api/Messages/SetCustmerChatsToSeen", 0, config)
        .then((r) => console.log("seen"));
    }
    dispatch(getMessages());
  }, [chatStatus]);

  const sendMessage = (message) => {
    if (isVoiceSend === false) {
      handleSendRecording(user?.id);
    } else {
      if (
        message !== null &&
        message !== "" &&
        message !== "undefined" &&
        message.length < 250 &&
        message.trim()?.length > 0
      ) {
        connection.invoke("SendMessage", "", message).then((r) => {
          setMessage("");
        });
        dispatch(getMessages());
      }
    }
  };

  useEffect(() => {
    if (!connection && Cookies.get("webAuth")) {
      const connection = new HubConnectionBuilder()
        .withUrl(`https://varypharmacy.com/api/chatHub`, {
          accessTokenFactory: () => Cookies.get("webAuth"),
          UseDefaultCredentials: true,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      connection.logging = true;
      connection.start();
      setConnection(connection);
    }
  }, [connection]);
  const handlePopUp = function () {
    "use strict";
  };
  const token = Cookies.get("webAuth");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    dispatch(getMessages());
  }, []);
  useEffect(() => {
    if (connection) {
      connection.on("receive", () => {
        setnewMessage(true);
        dispatch(getMessages());
        if (chatStatus === true) {
          axios
            .post("api/Messages/SetCustmerChatsToSeen", 0, config)
            .then((r) => console.log("seen"));
        }
      });
    }
  }, [connection, dispatch]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, [chatStatus, data]);
  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      sendMessage(message);
    }
  };
  const handleFileChange = (e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("webAuth")}`,
      },
    };
    setSelectedFile(e.target.files[0]);
    var form = new FormData();
    form.append("File", e.target.files[0]);
    form.append("UserId", "");
    axios
      .post("api/Messages", form, config)
      .then((r) => {
        console.log("send.......");
        if (r.status === 200) {
          connection.invoke("SendFile", "");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isVoiceSend, setIsVoiceSend] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [micAccess, setMicAccess] = useState(null);
  const [isRecording, setIsRecording] = useState(null);

  const handleStartRecording = (userId) => {
    if (!isRecording) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          recorder.start();
          setIsRecording(true);
          setRecordedChunks([]);

          recorder.addEventListener("dataavailable", (event) => {
            setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
          });

          recorder.addEventListener("stop", () => {
            setIsRecording(false);
            console.log("stop recording");
            setIsVoiceSend(false);

            // const audioBlob = new Blob(recordedChunks, {
            //   type: "audio/webm",
            //   name: ".webm",
            //   fileName: ".webm",
            // });
            // const formData = new FormData();
            // formData.append("File", audioBlob, "recorded_audio.webm"); // Set the desired file name
            // formData.append("UserId", "");
            // for (const value of formData.values()) {
            //   console.log(value);
            // }

            // const config = {
            //   headers: {
            //     Authorization: `Bearer ${Cookies.get("webAuth")}`,
            //     "Content-Type": "multipart/form-data",
            //   },
            // };

            // axios
            //   .post("api/Messages", formData, config)
            //   .then((response) => {
            //     console.log("File sent");
            //     connection.invoke("SendFile", userId?.toString());
            //     setIsVoiceSend(true);
            //   })
            //   .catch((error) => {
            //     console.log("Error sending file: ", error);
            //     setIsVoiceSend(false);
            //   });
          });
        })
        .catch((error) => {
          setMicAccess(false);
          console.log("Error accessing microphone: ", error);
          setTimeout(() => {
            setMicAccess(true);
          }, 5000);
        });
    } else {
      if (mediaRecorder && mediaRecorder.state === "recording") {
        mediaRecorder.stop();
      }
    }
  };

  const handleSendRecording = (userId) => {
    // Handle sending the recorded chunks to the server
    const audioBlob = new Blob(recordedChunks, {
      type: "audio/webm",
      name: ".webm",
      fileName: ".webm",
    });
    const formData = new FormData();
    formData.append("File", audioBlob, "recorded_audio.webm"); // Set the desired file name
    formData.append("UserId", "");
    for (const value of formData.values()) {
      console.log(value);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("webAuth")}`,
        "Content-Type": "multipart/form-data",
      },
    };

    axios
      .post("api/Messages", formData, config)
      .then((response) => {
        console.log("File sent");
        connection.invoke("SendFile", "");
        setIsVoiceSend(true);
      })
      .catch((error) => {
        console.log("Error sending file: ", error);
        setIsVoiceSend(false);
      });
  };

  return (
    <div style={{ direction: "ltr" }}>
      <div
        className="duration-500"
        id="web3forms__widget"
        x-data="{ open: true }"
        x-init="() => setTimeout(() => open = false, 2000)"
      >
        {chatStatus && (
          <>
            <motion.div
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed flex flex-col z-50 bottom-[100px] top-0 right-0 h-auto left-0 sm:top-auto sm:right-5 sm:left-auto h-[calc(100%-95px)] min-w-lg sm:w-[450px] overflow-auto min-h-[250px] sm:h-[600px] border border-gray-300 bg-white shadow-2xl rounded-md duration-500"
            >
              <div className="fixed  min-w-full md:min-w-[448px] lg:min-w-lg  lg:min-w-[448px]  overflow-auto z-10">
                <div className="flex flex-row items-center justify-center w-full h-12 bg-teal-500">
                  <div className="flex items-center justify-center w-10 h-10 text-teal-700 bg-teal-50 rounded-2xl">
                    <ChatBubbleLeftRightIcon className="w-6 h-6" />
                  </div>
                  <div
                    className={`${
                      locale === "en-US" ? "ml-2" : "ml-2"
                    }  text-2xl font-medium text-white ${locale}`}
                  >
                    {" "}
                    {t("varyChat")}
                  </div>
                </div>
              </div>
              <div className="flex-grow p-2 bg-gray-50">
                <div className="needs-validation">
                  <input
                    type="hidden"
                    name="apikey"
                    value="YOUR_ACCESS_KEY_HERE"
                  />
                  <input
                    type="hidden"
                    name="subject"
                    value="New Submission from Web3Forms"
                  />
                  <input
                    type="checkbox"
                    name="botcheck"
                    id=""
                    style={{ display: "none" }}
                  />

                  <div className="mb-4 overflow-hidden">
                    {/* <!-- SINGLE MESSAGE --> */}
                    {/* MESSAGES */}
                    <div className="mt-8 mb-16">
                      <div className="clearfix">
                        {reversed?.map((item, index) =>
                          item?.isFromThisUser === false ? (
                            <>
                              <div
                                className="col-start-1 col-end-8 p-3 rounded-lg"
                                key={index}
                              >
                                <div className="flex flex-row items-center">
                                  <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-teal-500 bg-white border border-gray-300 rounded-full border-spacing-0">
                                    V{" "}
                                  </div>
                                  <div
                                    className={`relative px-4 py-2 ml-2 text-sm bg-white shadow rounded-xl`}
                                  >
                                    <div className={`${locale}`}>
                                      {item.type === 1 ? (
                                        <>
                                          <a
                                            href={item?.message}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={item?.message}
                                              alt="sent image"
                                              className="max-w-44 max-h-44"
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        <>
                                          {item.type === 3 ? (
                                            <>
                                              <audio
                                                controls
                                                src={item.message}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              {item?.message?.includes(
                                                "http"
                                              ) ? (
                                                <>
                                                  <a
                                                    href={item?.message}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 hover:underline"
                                                  >
                                                    {item?.message}
                                                  </a>
                                                </>
                                              ) : (
                                                <>{item?.message}</>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="flex justify-between mt-2">
                                      <span className="text-xs text-gray-500 ">
                                        {new Date(
                                          item?.dateTime
                                        )?.toLocaleString(undefined, {
                                          dateStyle: "short",
                                          timeStyle: "short",
                                        })}
                                      </span>
                                      <span className="text-xs">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className={`icon icon-tabler icon-tabler-checks w-5 h-5 ${
                                            item?.isSeenByCustmer === true
                                              ? "text-teal-500"
                                              : "text-gray-400"
                                          }`}
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          currentColor="green"
                                          stroke={`${
                                            item?.isSeenByCustmer === true
                                              ? "#14b8a6"
                                              : "#94a3b8"
                                          }`}
                                          fill="none"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <path d="M7 12l5 5l10 -10" />
                                          <path d="M2 12l5 5m5 -5l5 -5" />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="col-start-6 col-end-13 p-3 rounded-lg"
                                key={index}
                              >
                                <div className="flex flex-row-reverse items-center justify-start">
                                  <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-teal-500 border rounded-full border-spacing-0">
                                    {user?.firstName?.charAt(0)}
                                  </div>
                                  <div
                                    className={`relative px-4 py-2 mr-2 text-sm bg-teal-50 shadow rounded-xl`}
                                  >
                                    <div className={`${locale}`}>
                                      {item.type === 1 ? (
                                        <>
                                          <a
                                            href={item?.message}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={item?.message}
                                              alt="sent image"
                                              className="max-w-44 max-h-44"
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        <>
                                          {item.type === 3 ? (
                                            <>
                                              <audio
                                                controls
                                                src={item.message}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              {item?.message?.includes(
                                                "http"
                                              ) ? (
                                                <>
                                                  <a
                                                    href={item?.message}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 hover:underline"
                                                  >
                                                    {item?.message}
                                                  </a>
                                                </>
                                              ) : (
                                                <>{item?.message}</>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="flex justify-between mt-2">
                                      <span className="text-xs text-gray-500 ">
                                        {new Date(
                                          item?.dateTime
                                        )?.toLocaleString(undefined, {
                                          dateStyle: "short",
                                          timeStyle: "short",
                                        })}
                                      </span>
                                      <span className="text-xs">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className={`icon icon-tabler icon-tabler-checks w-5 h-5 ${
                                            item?.isSeenByAdmin
                                              ? "text-teal-500"
                                              : "text-gray-400"
                                          }`}
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke={`${
                                            item?.isSeenByAdmin === true
                                              ? "#14b8a6"
                                              : "#94a3b8"
                                          }`}
                                          fill="none"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <path d="M7 12l5 5l10 -10" />
                                          <path d="M2 12l5 5m5 -5l5 -5" />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>
                      <div ref={bottomRef} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="fixed bottom-24  min-w-full md:min-w-[448px] lg:min-w-lg  lg:min-w-[448px]  overflow-auto z-10">
                <div className="flex flex-row items-center w-full h-16 px-4 bg-white rounded-xl">
                  <div className="flex-grow ml-4">
                    <div className="relative w-full">
                      <input
                        type="text"
                        style={{ unicodeBidi: "plaintext" }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => handleEnterKey(e)}
                        className="flex w-full h-10 pl-4 border rounded-xl focus:outline-none focus:border-teal-600"
                      />
                      {isVoiceSend !== false && (
                        <>
                          <label className="absolute w-6 h-6 mb-1 mr-2 cursor-pointer right-1 -top-4">
                            <input
                              type="file"
                              className="w-0 h-0 opacity-0"
                              onChange={handleFileChange}
                            />
                            <PhotoIcon className="w-6 h-6 text-gray-400 hover:text-gray-600" />
                          </label>
                        </>
                      )}
                      {isVoiceSend !== false && (
                        <>
                          <label className="absolute w-6 h-6 my-auto cursor-pointer right-10 top-2">
                            <MicrophoneIcon
                              onClick={() => handleStartRecording(user?.id)}
                              className={`w-6 h-6 ${
                                isRecording
                                  ? "text-rose-500 animate-ping  duration-500"
                                  : " text-gray-400"
                              } `}
                            />
                          </label>
                        </>
                      )}
                      {isVoiceSend === false && (
                        <label className="absolute w-6 h-6 my-auto cursor-pointer right-1 top-2">
                          <span className="absolute right-0 cursor-pointer top-1">
                            <div className="flex items-center">
                              <audio
                                src={URL.createObjectURL(
                                  new Blob(recordedChunks, {
                                    type: "audio/webm",
                                  })
                                )}
                                controls
                                className="h-8 "
                              />
                            </div>
                            {isVoiceSend}
                          </span>
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="ml-4">
                    <button
                      onClick={() => sendMessage(message)}
                      onKeyDown={(e) => handleEnterKey(e)}
                      className={`flex items-center justify-center flex-shrink-0 px-4 py-1 text-white bg-teal-500 hover:bg-teal-600 rounded-xl`}
                    >
                      <span className={`${locale}`}>{t("send")}</span>
                      <span className={`ml-2 `}>
                        <PaperAirplaneIcon className="inline w-4" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
        <button
          id="w3f__widget--btn"
          onClick={() => dispatch(setChatStatus())}
          className={`fixed z-40 flex items-center justify-center transition duration-300 bg-teal-500 rounded-full shadow-lg right-5 bottom-5 w-14 h-14 focus:outline-none hover:bg-teal-600 focus:bg-teal-600 ease`}
        >
          {!chatStatus && (
            <>
              {/* Icon */}
              {reversed.filter(
                (item) => !item?.isFromThisUser && !item.isSeenByCustmer
              ).length > 0 && (
                <>
                  {/* <span className="absolute h-5 mx-1 my-auto text-xs font-semibold bg-teal-400 rounded-full right-2 top-3 text-gray-50"></span> */}
                  <span className="absolute top-0 w-4 h-4 text-xs text-white rounded-full bg-rose-500 text right-2 animate-pulse">
                    {
                      reversed.filter(
                        (item) => !item?.isFromThisUser && !item.isSeenByCustmer
                      ).length
                    }
                  </span>
                </>
              )}
              <ChatBubbleLeftRightIcon className="inline text-white h-7 w-7" />
            </>
          )}

          {chatStatus && (
            <>
              <XCircleIcon className="inline text-white h-7 w-7" />
            </>
          )}
        </button>
      </div>

      {/* <!-- Web3Forms Popup Widget End here --> */}

      {/* <main className="flex flex-col items-center justify-center h-screen space-y-2 bg-gray-50">
        <div className="flex flex-col items-center justify-center -translate-x-1/2">
          {" "}
          <p className="text-xl text-center text-gray-500">Web3Forms</p>
          <h1 className="text-4xl font-bold text-center text-teal-500">
            Contact Widget Demo
          </h1>
          <p className="mt-3 text-xl text-center text-gray-500">
            Please open in Full Screen ?
          </p>
          <div>
            <svg
              className="mt-6 ml-16 opacity-20"
              xmlns="http://www.w3.org/2000/svg"
              //   xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              width="100"
              height="100"
              viewBox="0 0 415.262 415.261"
              //   style={{enableBackground: new |}}
              //   xml:space="preserve"
            >
              <g>
                <path d="M414.937,374.984c-7.956-24.479-20.196-47.736-30.601-70.992c-1.224-3.06-6.12-3.06-7.956-1.224   c-10.403,11.016-22.031,22.032-28.764,35.496h-0.612c-74.664,5.508-146.88-58.141-198.288-104.652   c-59.364-53.244-113.22-118.116-134.64-195.84c-1.224-9.792-2.448-20.196-2.448-30.6c0-4.896-6.732-4.896-7.344,0   c0,1.836,0,3.672,0,5.508C1.836,12.68,0,14.516,0,17.576c0.612,6.732,2.448,13.464,3.672,20.196   C8.568,203.624,173.808,363.356,335.376,373.76c-5.508,9.792-10.403,20.195-16.523,29.988c-3.061,4.283,1.836,8.567,6.12,7.955   c30.6-4.283,58.14-18.972,86.292-29.987C413.712,381.104,416.16,378.656,414.937,374.984z M332.928,399.464   c3.673-7.956,6.12-15.912,10.404-23.868c1.225-3.061-0.612-5.508-2.448-6.12c0-1.836-1.224-3.061-3.06-3.672   c-146.268-24.48-264.996-124.236-309.06-259.489c28.764,53.244,72.828,99.756,116.28,138.924   c31.824,28.765,65.484,54.468,102.204,75.888c28.764,16.524,64.872,31.824,97.92,21.421l0,0c-1.836,4.896,5.508,7.344,7.956,3.672   c7.956-10.404,15.912-20.196,24.48-29.376c8.567,18.972,17.748,37.943,24.479,57.527   C379.44,382.94,356.796,393.956,332.928,399.464z" />
              </g>
            </svg>
          </div>
        </div>
      </main> */}
      {/* <script type="module" src="https://cdn.skypack.dev/twind/shim"></script>
    <script
      src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js"
      defer
    ></script> */}
      {micAccess === false && <SnackBar Title={"Can not access your mic!"} />}
    </div>
  );
};

export default ChatPopupTailwind;
