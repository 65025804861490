import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const BMI = () => {
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [bmiResult, setBMIResult] = useState(null);

  const calculateBMI = () => {
    if (weight && height) {
      const bmi = weight / (height / 100) ** 2;
      setBMIResult(bmi.toFixed(2));
    }
  };
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");

  return (
    <div className="flex w-full max-w-screen-xl px-4 mx-auto my-10 bg-pattern">
      <div
        className={`to flex flex-col lg:flex-row items-center rounded-xl bg-gradient-to-br
        from-teal-600 to-teal-500
        duration-500
   
         px-8 text-white shadow-lg py-6`}
      >
        <div className="my-10 lg:my-8 lg:w-1/2">
          <h1 className={`text-2xl font-bold ${l}`}>{t("BMI")} </h1>
          <p className={`mt-4 text-lg ${l}`}>{t("bmiDesc")}</p>
        </div>
        <div className="flex justify-center flex-shrink-0 w-full h-72 lg:w-1/2">
          <div className="p-8 transition-transform duration-500 transform scale-100 bg-white rounded-lg shadow-lg hover:scale-105">
            <div className="flex flex-col space-y-2">
              <div>
                <label
                  htmlFor="weight"
                  className={`font-medium text-gray-800 ${l}`}
                >
                  {t("Weight")} ({t("kg")}) :
                </label>
                <input
                  type="number"
                  id="weight"
                  className="w-full px-3 py-2 mx-1 mt-1 text-gray-800 border border-gray-300 rounded-lg focus:outline-none focus:ring-teal-500 ku"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor="height"
                  className={`font-medium text-gray-800 ${l}`}
                >
                  {t("Height")} ({t("cm")}) :
                </label>
                <input
                  type="number"
                  id="height"
                  className="w-full px-3 py-1 mx-1 mt-1 text-gray-800 border border-gray-300 rounded-lg focus:outline-none focus:ring-teal-500 ku"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
              <button
                onClick={calculateBMI}
                className={`bg-gradient-to-br from-teal-400 to-teal-600 hover:bg-teal-600 active:bg-gray-700 focus:bg-teal-600 text-white font-medium rounded px-4 py-2 transition-colors duration-300 ${l}`}
              >
                {t("CalculateBMI")}
              </button>
              {bmiResult && (
                <div className="flex justify-around mb-2 text-center" >
                  <motion.h2
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className={`text-xl font-bold text-gray-800 my-auto ${l}`}
                  >
                    {t("BMIResault")} : {bmiResult}
                  </motion.h2>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="flex my-auto"
                  >
                    {bmiResult < 18.5 && (
                      <motion.span
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className={`bg-cyan-500 px-3 text-sm rounded-lg py-2 border border-1 border-cyan-600 mx-auto`}
                      >
                        <CheckCircleIcon className="inline w-6 mx-1" />
                        {t("underWeight")}
                      </motion.span>
                    )}
                    {bmiResult >= 18.5 && bmiResult < 25 && (
                      <motion.span
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className={`bg-green-500 px-3 text-sm rounded-lg py-2 mx-auto ${l}`}
                      >
                        <CheckCircleIcon className="inline w-6 mx-1" />
                        {t("normal")}
                      </motion.span>
                    )}
                    {bmiResult >= 25 && bmiResult < 30 && (
                      <motion.span
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className={`bg-yellow-500 px-3 text-sm rounded-lg py-2 mx-auto ${l}`}
                      >
                        <CheckCircleIcon className="inline w-6 mx-1" />
                        {t("overWeight")}
                      </motion.span>
                    )}
                    {bmiResult >= 30 && bmiResult < 35 && (
                      <motion.span
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className={`bg-orange-500 px-3 text-sm rounded-lg py-2 mx-auto ${l}`}
                      >
                        <CheckCircleIcon className="inline w-6 mx-1" />
                        {t("obese")}
                      </motion.span>
                    )}
                    {bmiResult >= 35 && (
                      <motion.span
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className={`bg-red-500 px-3 text-sm rounded-lg py-2 mx-auto ${l}`}
                      >
                        <CheckCircleIcon className="inline w-6 mx-1" />
                        {t("extremelyObese")}
                      </motion.span>
                    )}
                  </motion.div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BMI;
