import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getProfiles = createAsyncThunk(
  "getProfiles",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const { data } = await axios.get(
        "api/Doctors"
        );
        // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    copyData:[],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    searchFilter: (state, action) => {
      // console.log("payload", action.payload);
      state.data = state.copyData.filter(
        (item) =>
          item.englishName.toLowerCase().includes(action.payload) ||
          item.kurdishName.toLowerCase().includes(action.payload) ||
          item.englishDescription.toLowerCase().includes(action.payload) ||
          item.arabicDescription.toLowerCase().includes(action.payload) ||
          item.kurdishDescription.toLowerCase().includes(action.payload) ||
          item.englishClinicName.toLowerCase().includes(action.payload) ||
          item.arabicClinicName.toLowerCase().includes(action.payload) ||
          item.kurdishClinicName.toLowerCase().includes(action.payload) ||
          item.englishAddress.toLowerCase().includes(action.payload) ||
          item.arabicAddress.toLowerCase().includes(action.payload) ||
          item.kurdishAddress.toLowerCase().includes(action.payload) 
      );
    },
  },
  extraReducers: {
    [getProfiles.pending]: (state, action) => {
      state.loading = true;
    },
    [getProfiles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getProfiles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});

export const { searchFilter } = loremSlice.actions;
export default loremSlice;