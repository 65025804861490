import React, { useState, useEffect } from "react";
import {
  GiftIcon,
  GiftTopIcon,
  HeartIcon,
  ShoppingBagIcon,
} from "@heroicons/react/20/solid";
import Breadcrumb from "../BreadCrumb";
import Parser from "html-react-parser";
import Share from "./Share";
// import zoom from "./zoom.js";
import {
  ChevronDoubleDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EllipsisHorizontalCircleIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getBasket } from "../../../features/web/ShopingCart";
import CheckAuth from "../CheckAuthModal/CheckAuth";
import SnackBar from "./SnackBarDynamic";
import AddedSnackBar from "./AddedToBasketSnackBar";
import Related from "../shop/RelatedItemsCarousel";
import ReactImageMagnify from "react-image-magnify";
import Cookies from "js-cookie";

import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
  MagnifierContainer,
  MagnifierPreview,
  MagnifierZoom,
} from "react-image-magnifiers";
import inputStyle from "./InputStyle/input.css";
import $ from "jquery";

const ProductDetails4 = () => {
  $(document).ready(function () {
    $("#img_producto_container")
      // tile mouse actions
      .on("mouseover", function () {
        $(this)
          .children(".img_producto")
          .css({ transform: "scale(" + $(this).attr("data-scale") + ")" });
      })
      .on("mouseout", function () {
        $(this).children(".img_producto").css({ transform: "scale(1)" });
      })
      .on("mousemove", function (e) {
        $(this)
          .children(".img_producto")
          .css({
            "transform-origin":
              ((e.pageX - $(this).offset().left) / $(this).width()) * 200 +
              "% " +
              ((e.pageY - $(this).offset().top) / $(this).height()) * 200 +
              "%",
          });
      });
  });
  function convertToArabicNumber(number) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];

    return String(number).replace(/\d/g, (digit) => arabicDigits[digit]);
  }

  const params = useParams();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const l = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const isLogedIn = Cookies.get("webAuth")?.length > 0 ? true : false;
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  const [checkLoginModal, setcheckLoginModal] = useState(false);
  const [AddedToBasket, setAddedToBasket] = useState(false);

  const [snackBar, setSnackBar] = useState(false);
  const handleQuantity = (val) => {
    if (val === "inc") {
      setQuantity(quantity + 1);
    } else {
      if (quantity - 1 > 0) setQuantity(quantity - 1);
    }
  };
  useEffect(() => {
    const abortController = new AbortController();

    axios.get(`api/Products/${params.id}`, params).then((r) => {
      // console.log(r.data);
      setData(r.data);
      setMainPicture(0);
      setLoading(false);
      // console.log(r.data);
    });
    return () => {
      abortController.abort();
    };
  }, [params.id]);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
    },
  };
  // Submit Order
  const handleSubmitOrder = (id) => {
    if (isLogedIn) {
      console.log(id);
      const data = {};
      data.productId = id;
      data.qty = quantity;
      axios
        .post("api/BasketItems/AddAndRemoveFromBasket", data, config)
        .then((r) => {
          // console.log(r);
          setAddedToBasket(true);
          setTimeout(() => {
            setAddedToBasket(false);
          }, 4000);
          dispatch(getBasket());
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.status === 401) {
          }
          if (e.response.data === "not enough items in stock") {
            setSnackBar(true);
            setTimeout(() => {
              setSnackBar(false);
            }, 4000);
          }
        });
    } else {
      setcheckLoginModal(true);
    }
  };

  const handleZoom = () => {};
  const product = {
    name: "Glass Teapot Master",
    price: 199,
    rating: 4.4,
    reviews: 96,
    hrefReviews: "#link",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, id tenetur consectetur, rem vel repudiandae, obcaecati autem corporis maxime laborum debitis ullam. Similique nisi, porro perspiciatis vel ipsam aliquam repudiandae. Facilis dolorem incidunt nobis quaerat exercitationem quos eaque ducimus aut possimus, aperiam, enim nulla provident! Ad necessitatibus atque pariatur, iste dignissimos ex.",
    features: [
      { name: "Materials", details: "Bamboo, Glass" },
      { name: "Teapot", details: '7"L x 4.5"W x 4.5"H' },
      { name: "Capacity", details: "650 ml." },
      { name: "Cleaning", details: "Hand Wash" },
    ],
    colors: [
      { name: "Black", className: "bg-black" },
      { name: "White", className: "bg-white" },
      { name: "Blue", className: "bg-sky-400" },
    ],
    sizes: [
      { name: "Size 1", inStock: true },
      { name: "Size 2", inStock: true },
      { name: "Size 3", inStock: false },
    ],
    pictures: [
      {
        src: "https://fancytailwind.com/static/teapot1-e0cd61cf4337f086df22de4c2f646db0.webp",
        alt: "Teapot model",
      },
      {
        src: "https://fancytailwind.com/static/teapot2-9482ecf0cb04a9cbeacd01c7c869c970.webp",
        alt: "Teapot to pour water",
      },
      {
        src: "https://fancytailwind.com/static/teapot3-0596668cb1f92d4f0463e37d3ee69b36.webp",
        alt: "Teapot for breakfast",
      },
      {
        src: "https://fancytailwind.com/static/teapot4-73239d591549fa281e07e14a5e9939fb.webp",
        alt: "Teapot on warming machine",
      },
    ],
  };

  const [mainPicture, setMainPicture] = useState(0);

  const starsNumber = Math.floor(product.rating);
  const isHalfStar = !Number.isInteger(product.rating);
  const emptyStars = 5 - Math.ceil(product.rating);
  const props = {
    width: 400,
    height: 250,
    zoomWidth: 500,
    img: product.pictures[1],
  };
  const [img, setImg] = useState();
  const handleSelectImage = (index, img) => {
    setMainPicture(index);
    setImg(img);
  };
  return (
    <div className="container max-w-full px-4 mx-auto text-gray-700 bg-white lg:max-w-7xl x">
      <Breadcrumb
        Name={"productDetail"}
        Iconn={EllipsisHorizontalCircleIcon}
        product={"products"}
        productIcon={ShoppingBagIcon}
        category={data}
      />
      <div className="flex flex-col lg:flex-row">
        {/* :PICTURES CONTAINER */}
        <div className="flex flex-col items-center w-full py-8 lg:w-1/2">
          {/* ::Like Button */}
          <span className="self-start ml-10">
          </span>
          {/* ::Main Picture */}
          <div
            className="relative w-full h-56 overflow-hidden duration-300 rounded-lg sm:h-72 lg:h-full max-h-96 "
            id="img_producto_container"
            data-scale="1.6"
          >
            {data.qty < 1 && (
              <span
                className={`absolute top-0  ${
                  l === "en-US" ? "left-0" : "right-0"
                } m-2 rounded-md bg-gradient-to-br from-rose-600 to-rose-500 px-3 py-0.5 text-center text-sm font-medium text-white ${l}`}
              >
                {t("outOfStock")}
              </span>
            )}

            <img
              src={data && data?.imageUrl[mainPicture]}
              alt={product?.pictures[mainPicture]}
              className="object-contain w-full h-full duration-700 ease-in-out cursor-move img_producto"
            />
          </div>
          {/* ::Gallery */}
          <div className="mx-auto mt-6">
            <ul className="grid grid-flow-col gap-4 list-none auto-cols-fr">
              {data?.imageUrl
                ?.map((picture, index) => (
                  <li
                    key={picture?.alt}
                    className={`col-span-1 p-1 w-16 rounded-lg border-2 ${
                      index === mainPicture
                        ? "border-teal-600"
                        : "border-transparent"
                    }`}
                  >
                    <button
                      type="button"
                      className="block h-full overflow-hidden rounded"
                      onClick={() => handleSelectImage(index, picture)}
                    >
                      <img
                        src={picture}
                        alt={picture?.alt}
                        className="object-contain duration-300"
                      />
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div
          className={`flex flex-col w-full border-gray-200 lg:py-8 lg:w-1/2 ${
            l === "en-US" ? "lg:border-l-2" : "lg:border-r-2"
          } `}
        >
          <div className="px-4 md:flex-1">
            <h2
              className={`mb-2 leading-tight tracking-tight font-bold text-gray-800 text-2xl md:text-3xl ${l}`}
            >
              {l === "en-US" && data?.name?.english}{" "}
              {l === "ar" && data?.name?.arabic}{" "}
              {l === "ku" && data?.name?.kurdish}{" "}
            </h2>
            <p className={`text-teal-500 text-md font-medium ${l}`}>
              {t("brand")}: {l === "en-US" && data?.brand?.english}{" "}
              {l === "ar" && data?.brand?.arabic}{" "}
              {l === "ku" && data?.brand?.kurdish}{" "}
              <a href="#" className="text-teal-600 hover:underline"></a>
            </p>

            <div className="flex items-center my-4 space-x-4">
              <div>
                <div className="flex px-3 py-2 bg-gray-100 rounded-lg">
                  <span className="text-2xl font-bold text-teal-600">
                    {data?.isOfferActive ? (
                      <>
                        {data.offerType === 2
                          ? Intl.NumberFormat().format(data?.priceAfterDiscount)
                          : Intl.NumberFormat().format(data?.price)}
                      </>
                    ) : (
                      Intl.NumberFormat().format(data?.price)
                    )}
                  </span>
                  <span className={`mt-1 mr-1 text-teal-500 ${l}`}>
                    {t("iqd")}
                  </span>
                </div>
              </div>
              <div className={`flex-1 ${l === "en-US" ? "" : "ml-auto"}`}>
                {data?.offerType === 2 && data?.isOfferActive ? (
                  <>
                    <span className="px-3 py-1 mx-3 my-auto text-xl font-semibold text-white align-middle rounded-lg bg-gradient-to-br from-rose-600 to-rose-500">
                      <ChevronDoubleDownIcon className="inline w-6 mx-1" /> %
                      {data?.percentageOf}
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {data?.offerType === 1 && data?.isOfferActive ? (
                  <>
                    <span className="px-3 py-1 mx-3 my-auto text-xl font-semibold text-white align-middle rounded-lg bg-gradient-to-br from-teal-600 to-teal-500">
                      <GiftIcon className="inline w-6 mx-1" />
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div>
              <ul className="flex flex-col my-5 space-y-2 list-none">
                <li className="inline-flex items-center space-x-2 text-gray-500 list-none">
                  <span className="w-1.5 h-1.5 rounded-full bg-teal-600 mx-1" />
                  <span className={`mx-1 font-semibold text-md ${l}`}>
                    {t("category")}:
                  </span>
                  <span className={`font-normal ${l}`}>
                    {l === "en-US" && data?.category?.english}{" "}
                    {l === "ar" && data?.category?.arabic}{" "}
                    {l === "ku" && data?.category?.kurdish}{" "}
                  </span>
                </li>
                <li
                  className={`inline-flex items-center space-x-2 text-gray-500 ${l}`}
                >
                  <span className="w-1.5 h-1.5 rounded-full bg-teal-600 mx-1" />
                  <span className={`mx-1 font-semibold ${l}`}>
                    {t("subCategory")}:
                  </span>
                  <span className={`font-normal ${l}`}>
                    {l === "en-US" && data?.subCategory?.english}{" "}
                    {l === "ar" && data?.subCategory?.arabic}{" "}
                    {l === "ku" && data?.subCategory?.kurdish}{" "}
                  </span>
                </li>
              </ul>
              <div>
                {data.isOfferActive && (
                  <>
                    {data.offerType === 1 && (
                      <>
                        <div className="flex flex-col m-auto bg-white p-auto">
                          <label
                            className={`flex py-5  md:px-10 px-5  md:mx-20 mx-1 text-md font-semibold items-start lg:mx-0 ${
                              l === "en-Us"
                                ? " text-left float-left"
                                : "text-right float-right"
                            } text-gray-700  ${l}`}
                          >
                            {l === "en" &&
                              data.buyOneGetThese.length > 1 &&
                              `buy ${data?.buyOneGetOneOfferMainItemHowMnay} and get this product free`}
                            {l === "en" &&
                              data.buyOneGetThese.length === 1 &&
                              `buy ${data?.buyOneGetOneOfferMainItemHowMnay} and get this products free`}
                            {l === "ku" && (
                              <>
                                {convertToArabicNumber(
                                  data?.buyOneGetOneOfferMainItemHowMnay
                                )}{" "}
                                {t("buying")}
                                {data.buyOneGetThese.length > 1
                                  ? t("freeItems")
                                  : t("freeItem")}
                              </>
                            )}
                            {l === "ar" &&
                              data.buyOneGetThese.length > 1 &&
                              `اشتر ${convertToArabicNumber(
                                data?.buyOneGetOneOfferMainItemHowMnay
                              )} واحصل على هذه المنتجات مجانًا`}
                            {l === "ar" &&
                              data.buyOneGetThese.length === 1 &&
                              `اشتر ${convertToArabicNumber(
                                data?.buyOneGetOneOfferMainItemHowMnay
                              )} واحصل على هذه منتج مجانًا`}
                          </label>
                          <div className="flex pb-10 hide-scroll-bar">
                            {data.buyOneGetThese.map((item, index) => (
                              <li className="flex px-3 mx-1 duration-500 border border-gray-100 rounded-lg flex-nowrap min-w-2xl hover:bg-gray-50 hover:scale-110">
                                <div className="flex items-center flex-1 px-3 py-2 overflow-hidden cursor-pointer select-none">
                                  <div
                                    className={`flex flex-col w-16 h-10 justify-center items-center  ${
                                      l === "en-US" ? "mr-3" : "ml-3"
                                    }`}
                                  >
                                    <span href="#" className="relative block">
                                      <img
                                        alt="image"
                                        src={item.imageUrl}
                                        id="dynamic-image"
                                        className="object-cover mx-auto rounded-lg h-14 w-14"
                                      />
                                    </span>
                                  </div>
                                  <div className="flex-1 pl-1">
                                    <div
                                      id="dynamic-title"
                                      className={`font-medium dark:text-white w-2xl ${l}`}
                                    >
                                      {l === "en-US" && item?.name?.english}{" "}
                                      {l === "ar" && item?.name?.arabic}{" "}
                                      {l === "ku" && item?.name?.kurdish}{" "}
                                    </div>
                                    <div
                                      className={`text-sm text-gray-600 dark:text-gray-200 ${l}`}
                                    >
                                      {l === "en-US" && item?.category?.english}{" "}
                                      {l === "ar" && item?.category?.arabic}{" "}
                                      {l === "ku" && item?.category?.kurdish}{" "}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </div>
                        </div>{" "}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex w-full py-4 space-x-7">
              <div className="relative mx-3">
                <div
                  className={`absolute left-0 right-0 block pt-1 text-xs font-semibold tracking-wide text-center text-gray-400 uppercase mx-auto ${l}`}
                >
                  {t("qty")}
                </div>
                <input
                  type="number"
                  value={quantity}
                  min="1"
                  onChange={(e) => setQuantity(e.target.value)}
                  className="flex items-end w-full px-4 pt-3 pb-1 text-center duration-700 ease-linear border border-gray-200 appearance-none cursor-pointer auto pr- rounded-xl h-14 "
                />
                <ChevronUpIcon
                  className="absolute right-0 w-8 h-6 mb-2 mr-2 text-teal-500 bottom-5 hover:scale-110 hover:text-teal-600 hover:text-bold"
                  onClick={() => handleQuantity("inc")}
                />
                <ChevronDownIcon
                  className="absolute bottom-0 right-0 w-8 h-6 mb-2 mr-2 text-rose-500 hover:scale-110 hover:text-rose-600 hover:text-bold"
                  onClick={() => handleQuantity("dec")}
                />
              </div>

              <button
                type="button"
                onClick={(e) => handleSubmitOrder(data.id)}
                disabled={data.qty < 1}
                hidden={data.qty < 1}
                className={`px-6 py-2 font-semibold text-white bg-gradient-to-br from-teal-600 to-teal-500 h-14 rounded-xl hover:scale-x-110 hover:scale-y-105 duration-500 mx-auto  ${l}`}
              >
                <ShoppingBagIcon className="inline w-6 mx-1" />
                {t("addToCart")}
              </button>
            </div>
            {/* {data && (
              <>
                <Share
                  postUrl={window.location.href}
                  postHeader={
                    l === "en"
                      ? data?.name?.english
                      : l === "ar"
                      ? data?.name?.arabic
                      : data?.name?.kurdish
                  }
                  description={
                    data?.description?.english.length > 0 && (
                      <>
                        {(() => {
                          switch (l) {
                            case "ar":
                              return Parser(data?.description?.arabic);
                            case "ku":
                              return Parser(data?.description?.kurdish);
                            case "en-US":
                              return Parser(data?.description?.english);
                            default:
                              return null;
                          }
                        })()}
                      </>
                    )
                  }
                  imageUrl={data?.imageUrl?.[0]}
                />
              </>
            )} */}
          </div>
        </div>
        {checkLoginModal && (
          <CheckAuth open={checkLoginModal} setOpen={setcheckLoginModal} />
        )}
        {snackBar && <SnackBar />}
        {AddedToBasket && <AddedSnackBar />}
      </div>
      <div className="max-w-full">
        <div className="border-b border-gray-300">
          <nav className="flex gap-4">
            <a
              href="#"
              title=""
              className={`border-b-2 border-gray-900 py-4 text-sm font-medium text-gray-900 hover:border-gray-400 hover:text-gray-800text-transparent  bg-clip-text bg-gradient-to-br from-teal-600 to-teal-700 ${l}`}
            >
              {" "}
              {t("details")}{" "}
            </a>
          </nav>
        </div>

        <div className="mt-8 sm:mt-12">
          <h1
            className={` mb-2 leading-tight tracking-tight font-bold text-gray-800 text-xl md:text-2xl text-transparent  bg-clip-text bg-gradient-to-br from-teal-600 to-teal-700 ${l} `}
          >
            {t("description")}
          </h1>
          <p className={`mt-4  mx-3`} style={{ userSelect: "none" }}>
            {data?.description?.english.length > 0 && (
              <>
                {l === "ar" && Parser(data?.description?.arabic)}{" "}
                {l === "ku" && Parser(data?.description?.kurdish)}{" "}
                {l === "en-US" && Parser(data?.description?.english)}{" "}
              </>
            )}
          </p>

          <h1
            className={`mt-4 mb-2 leading-tight tracking-tight font-bold text-gray-800 text-xl md:text-2xl text-transparent  bg-clip-text bg-gradient-to-br from-teal-600 to-teal-700 ${l}`}
          >
            {t("usage")}
          </h1>
          <p className={`mt-4  mx-3`} style={{ userSelect: "none" }}>
            {data?.usage?.english.length > 0 && (
              <>
                {l === "en-US" && Parser(data?.usage?.english)}{" "}
                {l === "ar" && Parser(data?.usage?.arabic)}{" "}
                {l === "ku" && Parser(data?.usage?.kurdish)}{" "}
              </>
            )}
          </p>
          <h1
            className={` my-2 leading-tight tracking-tight font-bold text-gray-800 text-xl md:text-2xl text-transparent  bg-clip-text bg-gradient-to-br from-teal-600 to-teal-700 ${l} `}
          >
            {t("descriptionInVoice")}
          </h1>
          <p className={`mt-4  mx-3`}>
            {l === "ar" && (
              <>
                <audio className="mt-2" controls src={data?.arabicVoice} />
              </>
            )}{" "}
            {l === "ku" && (
              <>
                <audio className="mt-2" controls src={data?.kurdishVoice} />
              </>
            )}{" "}
            {l === "en-US" && (
              <>
                <audio className="mt-2" controls src={data?.englishVoice} />
              </>
            )}
          </p>
        </div>
        <div className="max-w-sm mx-auto md:min-w-full">
          <h2
            className={`my-6 text-2xl font-medium text-gray-800 uppercase  ${l}`}
          >
            {t("relatedProducts")}
          </h2>
          <div className="col-span-12 lg:col-span-3" data-aos="fade-up">
            <Related categoryId={data?.categoryId} id={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails4;
