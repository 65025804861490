import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getMessages = createAsyncThunk(
  "getMessages",
  async (object, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get('webAuth')}`,
      },
    };
    // console.log(getState());
    try {
      const { data } = await axios.get("api/Messages", config);
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getMessages.pending]: (state, action) => {
      state.loading = true;
    },
    [getMessages.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getMessages.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});

export default loremSlice;
