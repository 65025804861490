import { XMarkIcon } from "@heroicons/react/20/solid";
import { getwebBanner } from "../../features/Global/Banner";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
export default function Banner() {
  const dispatch = useDispatch();
  const banner = useSelector((state) => state.Banner.webData);
  const isSuccess = useSelector((state) => state.Banner.webIsSuccess);
  useEffect(() => {
    return () => {
      dispatch(getwebBanner());
    };
  }, []);
  const locale = localStorage.getItem("i18nextLng");
  const isImageOrGif = (url) => {
    return (
      url.toLowerCase().endsWith(".jpg") ||
      url.toLowerCase().endsWith(".jpeg") ||
      url.toLowerCase().endsWith(".png") ||
      url.toLowerCase().endsWith(".gif") ||
      url.toLowerCase().endsWith(".webp") ||
      url.toLowerCase().endsWith(".web")
    );
  };
  return (
    <>
      {isSuccess && banner.isActive && (
        <>
          <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gradient-to-br bg-rose-500 to-rose-700 px-6 py-1 sm:px-3.5 sm:before:flex-1">
            <div
              className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl animate-gradient1"
              aria-hidden="true"
            >
              <div
                // className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ef4444] to-[#dc2626] opacity-30"
                className=" bg-gradient-to-br from-rose-500 to-rose-600"
                // style={{
                //   clipPath:
                //     "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                // }}
              />
            </div>
            <div
              className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl animate-gradient2"
              aria-hidden="true"
            >
              <div
                className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#f43f5e] to-[#be123c] opacity-30"
                // style={{
                //   clipPath:
                //     "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                // }}
              />
            </div>
            <div className="flex flex-wrap w-full mx-auto gap-x-4">
              <span className="flex mx-auto">
                <p className="my-auto text-sm leading-6 text-gray-50">
                  {banner.englishText?.length > 0 &&
                    // banner.englishText !== null &&
                    banner.arabicText?.length > 0 &&
                    // banner.arabicText !== null &&
                    banner.kurdishText?.length > 0 && (
                      // banner.kurdishText !== null &&
                      <>
                        <strong className={`font-semibold my-auto ${locale}`}>
                          {locale === "en" && banner.englishText}
                          {locale === "ar" && banner.arabicText}
                          {locale === "ku" && banner.kurdishText}
                        </strong>
                      </>
                    )}
                  {/* <svg
              viewBox="0 0 2 2"
              className="mx-2 inline h-0.5 w-0.5 fill-current"
              aria-hidden="true"
            >
              <circle cx={1} cy={1} r={1} />
            </svg> */}
                </p>
                {banner.englishImageUrl !== "" &&
                  banner.englishImageUrl !== null &&
                  banner.arabicImageUrl !== "" &&
                  banner.arabicImageUrl !== null &&
                  banner.kurdishImageUrl !== "" &&
                  banner.kurdishImageUrl !== null && (
                    <>
                      <span className="flex-none rounded-full px-3.5 text-sm font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                        {banner &&
                        isSuccess &&
                        locale === "en" &&
                        isImageOrGif(banner?.englishImageUrl) ? (
                          <img
                            src={banner?.englishImageUrl}
                            alt="Image or GIF"
                            className="inline object-cover w-10 h-10 rounded-full"
                          />
                        ) : (
                          <video
                            src={banner?.englishImageUrl}
                            autoPlay
                            loop
                            muted
                            className="inline object-cover w-10 h-auto rounded-full"
                          ></video>
                        )}
                        {banner &&
                        isSuccess &&
                        locale === "ar" &&
                        isImageOrGif(banner?.arabicImageUrl) ? (
                          <img
                            src={banner?.arabicImageUrl}
                            alt="Image or GIF"
                            className="inline w-8 h-auto"
                          />
                        ) : (
                          <video
                            src={banner?.arabicImageUrl}
                            autoPlay
                            loop
                            muted
                            className="inline object-cover w-10 h-auto rounded-full"
                          ></video>
                        )}
                        {banner &&
                        isSuccess &&
                        locale === "ku" &&
                        isImageOrGif(banner?.kurdishImageUrl) ? (
                          <img
                            src={banner?.kurdishImageUrl}
                            alt="Image or GIF"
                            className="inline object-cover w-10 h-auto rounded-full"
                          />
                        ) : (
                          <video
                            src={banner?.kurdishImageUrl}
                            autoPlay
                            loop
                            muted
                            className="inline w-8 h-auto"
                          ></video>
                        )}
                        {/* <span aria-hidden="true">&rarr;</span> */}
                      </span>
                    </>
                  )}
              </span>
            </div>
            {/* <div className="flex justify-end flex-1">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="w-5 h-5 text-gray-900" aria-hidden="true" />
        </button>
      </div> */}
          </div>
        </>
      )}
    </>
  );
}
