import { Fragment } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloudArrowUpIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, id }) {
  const [checkPassword, setCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const handleSelect = (e) => {
    const fileList = e.target.files;
    const fileArray = [];
    const previewArray = [];

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.startsWith("image/")) {
        fileArray.push(fileList[i]);
        previewArray.push(URL.createObjectURL(fileList[i]));
      } else {
        setMessage("Please select an image!");
        setError(true);
      }
    }

    setFiles(fileArray);
    setPreviews(previewArray);
  };

  const [images, setImages] = useState();
  useEffect(() => {
    axios.get(`api/ProductImages/${id}`).then((r) => {
      setImages(r.data);
    });
  }, [id]);

  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  let [categories] = useState({
    Images: images,
    Upload: [],
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(files);
    setDisable(true);
    const form = new FormData();
    form.append("productId", id);

    for (let i = 0; i < files.length; i++) {
      form.append("File", files[i]);
    }
    form.append("Index", data.ImageIndex);
    axios
      .post("api/ProductImages", form, config)
      .then((r) => {
        setDisable(false);
        // console.log(r.data);
        if (r.status === 200) {
          setToast(true);
          setMessage("Image Uploaded Successfully");
          setOpen(false);
        }
      })
      .catch((e) => {
        setDisable(false);
        // console.log(e);
        console.log(e);
        if (
          e.response.status === 400 &&
          e.response.data.message === "email or phone number is already taken"
        ) {
          console.log(e.response.data);
          setMessage(e.response.data);
          setError(true);
        }
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`api/ProductImages/${id}`, config)
      .then((r) => {
        if (r.status === 200 || r.status === 204) {
          setToast(true);
          setMessage("Image Deleted Successfully");
          setOpen(false);
          // console.log(r);
        }
      })
      .catch((e) => {
        console.log(e);
        if (
          e.response.status === 400 &&
          e.response.data.message === "email or phone number is already taken"
        ) {
          console.log(e.response.data);
          setMessage(e.response.data);
          setError(true);
        }
      });
  };
  const handleOrder = (imageId, index, order) => {
    if (order === "inc") {
      ++index;
    } else {
      --index;
    }
    const data = { id: imageId, index: index };
    axios.post(`/api/Products/ReorderImages`, data, config).then((r) =>
      axios.get(`api/ProductImages/${id}`).then((r) => {
        setImages(r.data);
      })
    );
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="my-2 text-lg font-medium leading-6 text-gray-900"
                >
                  Product Images
                </Dialog.Title>
                <Tab.Group>
                  <Tab.List className="flex p-1 space-x-1 rounded-xl bg-teal-900/20">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-teal-700",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-teal-400 focus:outline-none focus:ring-2",
                          selected
                            ? "bg-white shadow"
                            : "text-black hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      Images
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-teal-700",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-teal-400 focus:outline-none focus:ring-2",
                          selected
                            ? "bg-white shadow"
                            : "text-black hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      Upload
                    </Tab>
                  </Tab.List>
                  <Tab.Panels>
                    {/* Images Secion */}
                    <Tab.Panel>
                      <div className="my-2 antialiased text-gray-600 ">
                        <div className="flex flex-col justify-center my-2">
                          {/* <!-- Table --> */}
                          <div className="w-full max-w-2xl mx-auto my-2 bg-white border border-gray-200 rounded-sm shadow-lg">
                            <div className="w-full p-3">
                              <div className="overflow-x-auto">
                                <table className="w-full table-auto">
                                  <thead className="text-xs font-semibold text-gray-400 uppercase bg-gray-50">
                                    <tr>
                                      <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">
                                          Image
                                        </div>
                                      </th>
                                      <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">
                                          Index
                                        </div>
                                      </th>
                                      <th className="p-2 whitespace-nowrap">
                                        <div className="font-semibold text-left">
                                          Delete
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="text-sm divide-y divide-gray-100">
                                    {images?.map((item, index) => (
                                      <tr>
                                        <td className="p-2 whitespace-nowrap">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0 mr-2 sm:mr-3">
                                              <img
                                                className="w-20 h-20 rounded-full"
                                                src={item.imageUrl}
                                                alt="Alex Shatov"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          <div className="flex items-left">
                                            <div className="flex-shrink-0 w-1/2 mr-2 sm:mr-3">
                                              <p className="flex justify-between w-full font-semibold align-middle">
                                                <span className="my-auto">
                                                  {item.index}
                                                </span>
                                                <span className="flex flex-col gap-y-1">
                                                  <ChevronUpIcon
                                                    onClick={() =>
                                                      handleOrder(
                                                        item.imageId,
                                                        item.index,
                                                        "inc"
                                                      )
                                                    }
                                                    className="btn-increase"
                                                  />
                                                  <ChevronDownIcon
                                                    onClick={() =>
                                                      handleOrder(
                                                        item.imageId,
                                                        item.index,
                                                        "dec"
                                                      )
                                                    }
                                                    className="btn-decrease"
                                                  />
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          {images?.length > 1 && (
                                            <>
                                              <div className="inline-flex justify-center px-4 py-1 text-sm font-medium border border-transparent rounded-md bg-teal-900 group bg-rose-100 hover:bg-rose-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-rose-500 focus-visible:ring-offset-2">
                                                <TrashIcon
                                                  className="w-6 h-6 text-rose-500 group-hover:animate-pulse"
                                                  onClick={() =>
                                                    handleDelete(item.imageId)
                                                  }
                                                />
                                              </div>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))}

                                    <div></div>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Panel>

                    {/* Upload Section */}
                    <Tab.Panel>
                      {" "}
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-2">
                          <div className="flex items-center justify-center w-full">
                            <label
                              htmlFor="dropzone-file"
                              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                              <div className="flex flex-row flex-wrap items-center justify-center pt-5 pb-6 mx-1 space-x-2">
                                {previews.length > 0 ? (
                                  previews.map((preview, index) => (
                                    <img
                                      key={index}
                                      src={preview}
                                      alt={`user image ${index + 1}`}
                                      className="flex-row w-20 h-20 rounded-full"
                                    />
                                  ))
                                ) : (
                                  <svg
                                    aria-hidden="true"
                                    className="w-10 h-10 mb-3 text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    ></path>
                                  </svg>
                                )}
                              </div>
                              <label
                                className="cursor-pointer hover:text-blue-500 dark:hover:text-blue-400"
                                htmlFor="file-upload"
                              >
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                  <span className="font-semibold">
                                    Click to upload
                                  </span>
                                  {/* drag and drop */}
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                  SVG, PNG, JPG
                                </p>
                                {/* <input
                                    id="file-upload"
                                    type="file"
                                    accept=".svg,.png,.jpg"
                                    multiple
                                    className="hidden"
                                    onChange={handleSelect}
                                  /> */}
                              </label>
                              <input
                                id="dropzone-file"
                                type="file"
                                accept=".svg,.png,.jpg"
                                multiple
                                className="hidden"
                                onChange={(e) => {
                                  handleSelect(e);
                                }}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="my-3">
                          <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                            Image Index:
                          </label>
                          <input
                            type="number"
                            placeholder="Image Index"
                            {...register("ImageIndex", {
                              required: true,
                            })}
                            className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                              errors.ImageIndex && "border-rose-500"
                            } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                          />
                        </div>

                        <div className="mt-4">
                          <button
                            type="submit"
                            disabled={disable}
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-teal-900 bg-teal-100 border border-transparent rounded-md hover:bg-teal-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                          >
                            <CloudArrowUpIcon className="w-5 h-5 mx-1 my-auto" />{" "}
                            Upload
                          </button>
                        </div>
                      </form>
                    </Tab.Panel>
                  </Tab.Panels>
                  {toast && (
                    <Toast
                      Text={"Brand Created Successfully"}
                      Type={"teal"}
                      setOpen={setToast}
                    />
                  )}
                  {error && (
                    <Toast Text={message} Type={"rose"} setOpen={setError} />
                  )}
                </Tab.Group>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
