import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Authorization: `Bearer ${Cookies.get('dashboardToken')}`,
  },
};
export const getQuestion = createAsyncThunk(
  "getQuestion",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const { data } = await axios.get("api/controlPane/Questions", config);
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    copyData: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    searchFilter: (state, action) => {
      // console.log("payload", action.payload);
      state.copyData = state.data.filter(
        (item) =>
          item.qestion.toLowerCase().includes(action.payload) ||
          item.answer.toLowerCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    [getQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [getQuestion.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getQuestion.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});
export const { searchFilter } = loremSlice.actions;
export default loremSlice;
