import React, { useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getMainReport } from '../../features/Global/MostSellingBySubCategorise'
import { useDispatch, useSelector } from 'react-redux';
ChartJS.register(ArcElement, Tooltip, Legend);


export function PieChart() {
  const dispatch = useDispatch();
  const databse = useSelector((state) => state.MostSellingBySubCategories.data);
  useEffect(() => {
    dispatch(getMainReport());
  }, [])
  const data = {
    labels: databse?.map((item) => item?.subCategory),
    datasets: [
      {
        label: '# of Orders',
        data: databse?.map((item) => item?.selledItem),
        backgroundColor: [
          'rgba(46, 196, 182, 0.8)',
          'rgba(255, 159, 28, 0.8)',
          'rgba(0, 166, 251, 0.8)',
          'rgba(226, 115, 150, 0.8)',
          'rgba(241, 91, 181, 0.8)',
          'rgba(254, 228, 64, 0.8)',
          'rgba(0, 129, 167, 0.8)',
          'rgba(240, 113, 103, 0.8)',
          'rgba(0, 175, 185, 0.8)',
          'rgba(187, 208, 255, 0.8)',
        ],
        borderColor: [
          'rgba(46, 196, 182, 1)',
          'rgba(255, 159, 28, 1)',
          'rgba(0, 166, 251, 1)',
          'rgba(226, 115, 150, 1)',
          'rgba(241, 91, 181, 1)',
          'rgba(254, 228, 64, 1)',
          'rgba(0, 129, 167, 1)',
          'rgba(240, 113, 103, 1)',
          'rgba(0, 175, 185, 1)',
          'rgba(187, 208, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data} />
}
