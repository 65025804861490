import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  BellAlertIcon,
  CheckCircleIcon,
  FireIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../../features/Global/Items";
import { Listbox } from "@headlessui/react";
import { Autocomplete, Chip, TextField, TextareaAutosize } from "@mui/material";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import { getOffersBuyOneGet } from "../../../features/Global/OffersBuyOneGet";
export default function SlideOver({ id, open, setOpen, quantity }) {
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const product = useSelector((state) => state.item.data);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [selected, setSelected] = useState([]);
  const [disable, setDisable] = useState(false);
  const [notifiactionStatus, setNotificationStatus] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  useEffect(() => {
    axios.get(`api/Products/${id}`).then((r) => {
      console.log(r.data?.buyOneGetThese.map((item) => item));
      setValue("mainItemHowMany", quantity);
      setSelectedPerson(
        r.data?.buyOneGetThese.map((x) => ({
          id: x?.getThisProductId,
          label: x.name?.english,
        }))
      );
    });
  }, [id]);

  let result = selectedPerson?.map((a) => a?.id);
  const onSubmit = (data) => {
    setDisable(true);
    // console.log(data);
    console.log("resault", result);
    data.getThese = result;
    data.buyThisId = id;
    axios
      .put("api/Offers/updateBogo", data, config)
      .then((r) => {
        setDisable(false);
        if (r.status === 200) {
          setMessage("Operation Done Successfully.");
          setToast(true);
          dispatch(getOffersBuyOneGet());
          if (notifiactionStatus) {
            setDisable(true);
            axios
              .post("api/Notification", data, config)
              .then((r) => {
                setDisable(false);
                if (r.status === 200) {
                  setMessage("Notification Sent Successfully.");
                  setToast(true);
                }
              })
              .catch((e) => {
                setDisable(false);
                // console.log(e.response.status);
                // console.log(e.response.data);
                setMessage(e.response.data);
                setError(true);
              });
          }
          setOpen(false);
        }
      })
      .catch((e) => {
        setDisable(false);
        setMessage(e.response.data);
        setError(true);
      });
  };
  useEffect(() => {
    dispatch(getItem());
  }, []);
  const options = product.map((option) => ({
    id: option.id,
    label: option?.name?.english,
  }));
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="py-10 text-center text-white bg-gradient-to-br from-blue-600 to-blue-500">
                  <p className={` text-2xl font-semibold`}>
                    <PencilIcon className="inline mx-2 w-7 " /> Update Order Buy
                    One Get One Or More
                  </p>
                </div>
                <div className="mx-5 mt-2">
                  <form className="px-1 py-6" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-5">
                      <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                        Main Item quantity:
                      </label>
                      <input
                        type="number"
                        min={1}
                        placeholder="Main item quantity"
                        {...register("mainItemHowMany", {
                          required: true,
                        })}
                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                          errors.mainItemHowMany && "border-rose-500"
                        } bg-white py-2 px-6 text-base font-medium text-gray-700 outline-none focus:border-blue-600 focus:shadow-md dark:bg-gray-700 dark:text-gray-300`}
                      />
                    </div>
                    <div className="mb-5 ">
                      <Autocomplete
                        id="combo-box-demo"
                        defaultValue={selectedPerson}
                        options={options}
                        value={selectedPerson}
                        multiple
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Free items"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, newValue) => {
                          console.log(newValue);
                          setSelectedPerson(newValue);
                        }}
                      />
                    </div>
                    <div className="flex">
                      <div className="flex items-center h-5">
                        <input
                          id="helper-checkbox"
                          aria-describedby="helper-checkbox-text"
                          type="checkbox"
                          value={notifiactionStatus}
                          onChange={(e) =>
                            setNotificationStatus(!notifiactionStatus)
                          }
                          className="w-4 h-4 text-blue-600 active:bg-blue-500 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-blue-500 focus:ring-2 <dark:bg-blue-2></dark:bg-blue-2>00 dark:border-gray-600"
                        />
                      </div>
                      <div className="ml-2 text-sm">
                        <label
                          for="helper-checkbox"
                          className="font-medium text-gray-900 dark:text-gray-800"
                        >
                          Send Notification
                        </label>
                        <p
                          id="helper-checkbox-text"
                          className="text-xs font-normal text-gray-500 dark:text-gray-800"
                        >
                          Notifiy users about this discount.
                        </p>
                      </div>
                    </div>
                    {notifiactionStatus && (
                      <>
                        <motion.div
                          className="px-1 py-3"
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5 }}
                          // onSubmit={handleSubmit(onSubmit)}
                        >
                          <span className="flex p-2 m-2 mx-auto text-xl text-center text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-600">
                            <BellAlertIcon className="inline w-6 mx-1" />{" "}
                            Notification
                          </span>
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              English Title:
                            </label>
                            <input
                              type="text"
                              placeholder="title english"
                              defaultValue="New buy one get one or more"
                              {...register("englishTitle", {
                                required: true,
                                pattern: /[A-Za-z]{1}/,
                              })}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.englishTitle && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              Arabic Title:
                            </label>
                            <input
                              type="text"
                              placeholder="title arabic"
                              defaultValue="شراء جديد واحد الحصول على واحد أو أكثر"
                              {...register("arabicTitle", {
                                required: true,
                                pattern: /[\u0600-\u06FF]{1}/,
                              })}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.arabicTitle && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>{" "}
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              Kurdish Title:
                            </label>
                            <input
                              type="text"
                              defaultValue="بەرهەمێک بکڕەو  دانەیەک یان زیاتر بە دیاری وەربگرە"
                              {...register("kurdishTitle", {
                                required: true,
                                pattern: /[\u0600-\u06FF]{1}/,
                              })}
                              placeholder="title kurdish"
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.kurdishTitle && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              English Message:
                            </label>
                            <TextareaAutosize
                              type="text"
                              placeholder="message english"
                              {...register("englishBody", {
                                required: true,
                                pattern: /[A-Za-z]{1}/,
                              })}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.englishBody && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              Arabic Message:
                            </label>
                            <TextareaAutosize
                              type="text"
                              placeholder="message arabic"
                              {...register("arabicBody", {
                                required: true,
                                pattern: /[\u0600-\u06FF]{1}/,
                              })}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.arabicBody && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>{" "}
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              Kurdish Message:
                            </label>
                            <TextareaAutosize
                              type="text"
                              {...register("kurdishBody", {
                                required: true,
                                pattern: /[\u0600-\u06FF]{1}/,
                              })}
                              placeholder="message kurdish"
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.kurdishBody && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-blue-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>
                        </motion.div>
                      </>
                    )}
                    <div className="mt-4">
                      <button
                        type="submit"
                        disabled={disable}
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          {toast && (
            <Toast
              Text={message}
              Type={"blue"}
              setOpen={setToast}
              className="z-50"
            />
          )}
          {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}{" "}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
