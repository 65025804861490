import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/news.svg";
import addIcon from "../../icons/add.svg";
import InfoCard from "../../components/Cards/Carts";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { getItem } from "../../../features/Global/Items";
import Spiner from "../../components/Loader/Spiner";
import Cookies from "js-cookie";

// Icons
import {
  CheckCircleIcon,
  EyeIcon,
  NewspaperIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";

// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../../features/Global/Dashboard/News";
const Index = () => {
  const [isHeadlessOpen, setIsHeadlessOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [viewId, setViewId] = useState(false);
  const [editID, setEditId] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.NewsDashboard.data);
  const loader = useSelector((state) => state.NewsDashboard.laoding);
  // Toast
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  // hook to fetch items
  useEffect(() => {
    dispatch(getNews());
  }, []);
  // Data Grid Compoenets
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const handleView = (id) => {
    setViewId(id);
    setView(true);
  };
  const columns = [
    { field: "id", headerName: "id", width: 70 },
    {
      field: "fileName",
      headerName: "Image",
      width: 70,
      renderCell: (params) => {
        return (
          <img
            src={params.value}
            alt="news Image"
            className="w-12 h-12 rounded-full"
          />
        );
      },
    },
    { field: "englishTitle", headerName: "English", width: 200, flex: 1 },
    { field: "arabicTitle", headerName: "Arabic", width: 200, flex: 1 },
    { field: "kurdishTitle", headerName: "Kurdish", width: 200, flex: 1 },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleDelete(cellvalues?.id)}
          className={`text-center inline-flex items-center justify-center px-2 py-1 mx-auto text-sm font-medium shadow-md ${
            cellvalues.row.isActive === true
              ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400-600 focus:ring-emerald-500 focus:ring-offset-emerald-200 f "
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {cellvalues.row.isActive === true ? (
            <>
              <CheckCircleIcon className="inline w-5 mx-1" /> Active
            </>
          ) : (
            <>
              <XCircleIcon className="inline w-5 mx-1" /> In Active
            </>
          )}
        </button>
      ),
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={(e) => handleEdit(cellvalues.id)}
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-600 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <PencilIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          Edit
        </button>
      ),
    },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 130,
    //   renderCell: (cellvalues) => (
    //     <button
    //       onClick={(e) => handleDelete(cellvalues.id)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-rose-500 hover:bg-rose-600 focus:ring-rose-500 focus:ring-offset-rose-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <TrashIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Delete
    //     </button>
    //   ),
    // },
    {
      field: "View",
      headerName: "View",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleView(cellvalues.row)}
          type="button"
          className="flex items-center justify-center float-right w-full px-3 py-2 text-center text-white transition duration-200 ease-in bg-orange-500 rounded-lg shadow-md hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <EyeIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          View
        </button>
      ),
    },
  ];
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get('dashboardToken')}`,
    },
  };
  const handleEdit = (id) => {
    setEditId(id);
    setEdit(true);
    dispatch(getNews());
  };
  const handleDelete = (id) => {
    axios
      .delete(`api/News/${id}`, config)
      .then((r) => {
        // console.log(r);
        if (r.status === 200 || r.status === 204) {
          setMessage("News updated successfully.");
          setToast(true);
          dispatch(getNews());
        }
        if (r.status === 204) {
          setToast(true);
          dispatch(getNews());
        }
      })
      .catch((e) => {
        console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
      });
  };
  return (
    <div className="ltr">
      <main className="relative h-screen bg-gray-100 dark:bg-gray-800">
        <div className="flex items-start justify-between h-screen overflow-y-auto">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              {/* Header */}
              <Header />
            </header>
            <div className="h-screen px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
              <div className="">
                <BreadCrumb
                  page={"News"}
                  icon={<NewspaperIcon className="inline w-6 mx-2" />}
                />
              </div>
              <InfoCard />
              <div className="p-4 h-[44rem] mb-7 rounded-md bg-gray-50 dark:bg-gray-800">
                <button
                  onClick={() => setIsHeadlessOpen(true)}
                  type="button"
                  className="flex items-center justify-center float-right px-4 py-2 text-center text-white transition duration-200 ease-in bg-teal-500 rounded-lg shadow-md w-fit hover:bg-teal-600 focus:ring-teal-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                >
                  <PlusCircleIcon
                    src={addIcon}
                    alt="add icon"
                    className="w-6 h-6 mr-2 text-white "
                  />
                  Add
                </button>{" "}
                {isHeadlessOpen && (
                  <Add
                    Title={"Add News"}
                    open={isHeadlessOpen}
                    setOpen={setIsHeadlessOpen}
                  />
                )}
                {edit && (
                  <Edit
                    Title={"Edit News"}
                    open={edit}
                    id={editID}
                    setOpen={setEdit}
                  />
                )}{" "}
                {view && (
                  <View
                    Title={"View News"}
                    open={view}
                    setOpen={setView}
                    id={viewId}
                  />
                )}
                <br />
                <br />
                {/* {loader && <Spiner />} */}
                {data?.length > 0 && loader ? (
                  <>
                    <Spiner />
                  </>
                ) : (
                  <div className="flex items-center w-auto h-full mx-2 mt-2 dark:bg-gray-800">
                    {/* <DataGrid rows={data[0]} columns={columns} className="dark:bg-gray-800 dark:text-gray-200" /> */}
                    <DataGrid
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      className="min-h-full dark:text-gray-200 "
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      // checkboxSelection
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {toast && <Toast Text={message} Type={"teal"} setOpen={setToast} />}
      {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}
    </div>
  );
};

export default Index;
