import { Fragment, useState, useEffect, useReducer } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import GmailLoader from "../../componets/Loader/GmailLoader";
import { reducer } from "../../../features/Global/reducer";
import { getSortedData } from "../../../features/Global/getSortedData";
import { getFilteredData } from "../../../features/Global/getFilteredData";
import { Link } from "react-router-dom";
import { getNews } from "../../../features/Global/News";
import { NewspaperIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../BreadCrumb";
import { Pagination } from "@mui/material";
import loaderAnim from "../Loader/Loader.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Parser from "html-react-parser";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../features/Global/Category";
import {
  getItem,
  filteredItem,
  selectFilter,
} from "../../../features/Global/Items";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import usePagination from "../../componets/pagination/Pagination";
// import Products from "./Products";
import Cookies from "js-cookie";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const data = useSelector((state) => state.News.data);
  const loader = useSelector((state) => state.News.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNews());
  }, []);
  const locale = localStorage.getItem("i18nextLng");

  const handleFilter = (e) => {
    dispatch(filteredItem(e.target.value));
  };
  let ids = [];
  const handleSelectFilter = (id, checked) => {
    // if(checked === true)
    ids.push(id);

    console.log(ids);
    dispatch(selectFilter(id));
  };

  const PER_PAGE = 20;
  let [page, setPage] = useState(1);
  const count = Math.ceil(data?.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <div className="">
      {/* {loader ? (
          <GmailLoader />
        ) : ( */}
      <div className="p-4 px-4 mx-auto bg-white rounded-md max-w-7xl sm:px-6 lg:px-8">
        <BreadCrumb Name={"information"} Iconn={NewspaperIcon} />
        <section className="container flex flex-row flex-wrap py-10 mx-auto">
          {/* <div className="container p-4 rounded-md bg-gray-50">
            <div className="flex flex-wrap justify-center -mx-4">
              <div className="w-full px-4">
                <div className="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">
                  <span
                    className={`block mb-2 text-lg font-semibold text-primary ${l}`}
                  >
                    {t("varyinformation")}
                  </span>
                  <h2
                    className={`
                       font-bold
                       text-3xl
                       sm:text-4xl
                       md:text-[40px]
                       text-dark
                       mb-4
                       ${l} `}
                  >
                    {t("ourRecentNews")}
                  </h2>
                  <p className={`text-base text-body-color ${l} `}>
                    There are many variations of passages of Lorem Ipsum
                    available but the majority have suffered alteration in some
                    form.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="flex items-end justify-between mb-12 header">
            <div className="title">
              <p className={`mb-4 text-4xl font-bold text-teal-600 ${l}`}>
                {t("ourRecentNews")}
              </p>
              <p className={`text-2xl font-light text-gray-400 ${l}`}>
                {t("newsSubtitle")}
              </p>
              {/* <div className="container mt-4 text-end">
              <form className="flex items-center ">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <MagnifyingGlassIcon
                      aria-hidden="true"
                      className="w-5 h-5 text-teal-500 dark:text-gray-400"
                      // fill="currentColor"
                    />
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-600 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-600"
                    placeholder={t('search')}
                    // required
                    onChange={(e)=> handleSearch(e.target.value)}
                  />
                </div>
              </form>
            </div> */}
            </div>
          </div>
          {/* Card Component */}
          {/* <div className="container">  */}
          <div className="grid max-w-screen-lg grid-cols-1 gap-5 py-5 mx-auto sm:grid-cols-2 md:grid-cols-3 lg:gap-5">
            {_DATA
              .currentData()
              ?.filter((item) => item.isActive)
              .map((item, index) =>
                _DATA.currentData().length === 0 ? (
                  <>
                    <p className="text-3-xl text-rose">no data found</p>
                  </>
                ) : (
                  <>
                    {loader ? (
                      <div className="container flex px-4 py-6 transition-all duration-150">
                        <Player
                          src={loaderAnim}
                          autoplay
                          loop
                          className="flex px-4 py-6 transition-all duration-150 player"
                        />
                      </div>
                    ) : (
                      <>
                        <Link
                          to={`/information/${item.id}`}
                          key={index}
                          className="h-full overflow-hidden border-2 border-gray-200 rounded-lg shadow-lg group border-opacity-60"
                        >
                          <img
                            className="object-cover object-center w-full transition duration-500 ease-in-out transform group-hover:scale-105 md:h-36 lg:h-48"
                            src={item.fileName}
                            alt="blog"
                          />
                          <h2
                            className={`title-font inline-block cursor-pointer px-6 pt-4 pb-1 text-xs font-semibold uppercase tracking-widest text-blue-600 hover:font-bold ${l}`}
                          >
                            {t("varyInformation")}
                          </h2>
                          <div className="px-6 py-1">
                            <h1
                              className={`title-font mb-3 inline-block cursor-pointer text-xl capitali font-extrabold tracking-wide text-gray-800 ${l}`}
                            >
                              {l === "en-US" && item.englishTitle}{" "}
                              {l === "ar" && item.arabicTitle}{" "}
                              {l === "ku" && item.kurdishTitle}{" "}
                            </h1>
                            <p
                              className={`line-clamp-6 mb-3 cursor-pointer overflow-hidden leading-relaxed text-gray-500 ${l}`}
                            >
                              {l === "en-US" && Parser(item?.englishBody)}
                              {l === "ar" && Parser(item?.arabicBody)}
                              {l === "ku" && Parser(item?.kurdishBody)}
                            </p>
                          </div>
                          <div className="flex flex-wrap items-center justify-between px-6 pt-1 pb-4">
                            <div className="flex flex-wrap text-sm text-gray-500">
                              <span className="mr-1"> {item?.updatedAt}</span>
                              {/* <span className="">· 9 min read</span> */}
                            </div>
                            <div className="mt-1">
                              <span className="inline-flex items-center py-1 pr-3 ml-auto mr-3 text-sm leading-none text-gray-400 md:ml-0 lg:ml-auto">
                                {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        ></path>
                      </svg> */}
                                {/* <img
                                  className="object-cover h-8 rounded-full"
                                  src={item?.userImage}
                                  alt="Avatar"
                                /> */}
                                {item?.user}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </>
                    )}
                  </>
                )
              )}{" "}
          </div>
          <div className="flex justify-end w-full px-3 my-5">
            <Pagination
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
              color="success"
              className="text-teal-600 border-teal-400 "
              showFirstButton
              showLastButton
              dir={`${l === "en-US" ? "ltr" : "rtl"}`}
            />
          </div>
          {/* </div> */}
        </section>
      </div>
      {/* )} */}
    </div>
  );
}
