import React, { useState, useEffect, useRef, Fragment } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/orders.svg";
import addIcon from "../../icons/add.svg";
import InfoCard from "../../components/Cards/Carts";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import { getItem } from "../../../features/Global/Items";
import Spiner from "../../components/Loader/Spiner";
import Cookies from "js-cookie";

// Icons
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ClockIcon,
  ExclamationCircleIcon,
  EyeIcon,
  FunnelIcon,
  ListBulletIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";

// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import {
  filterDate,
  filteredItem,
  getOrders,
  removeFilter,
} from "../../../features/Global/Orders";
import { Menu, Transition } from "@headlessui/react";
const Index = () => {
  const [isHeadlessOpen, setIsHeadlessOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [viewId, setViewId] = useState();
  const [editID, setEditId] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Orders.copyData);
  const loader = useSelector((state) => state.Orders.loading);
  const isSuccess = useSelector((state) => state.Orders.isSuccess);
  // Toast
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [showfilter, setShowFilter] = useState(false);

  const [message, setMessage] = useState("");
  // hook to fetch items
  useEffect(() => {
    dispatch(getOrders());
  }, []);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  // Data Grid Compoenets
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const handleView = (id) => {
    setViewId(id);
    setView(true);
  };
  const handleOrderTypes = (type) => {
    if (type === 0) {
      return { Color: "orange", Status: "Waiting" };
    } else if (type === 1) {
      return { Color: "teal", Status: "Accepted" };
    } else if (type === 2) {
      return { Color: "rose", Status: "Canceled" };
    } else if (type === 3) {
      return { Color: "yellow", Status: "Waiting for FIB payment" };
    } else if (type === 4) {
      return { Color: "indigo", Status: "FIB payment declined" };
    }
  };
  
  const renderStatusButton = (status) => {
    const { Color, Status } = handleOrderTypes(status);
  
    return (
      <button
        className={`inline-flex items-center justify-center px-4 py-1 text-sm font-medium shadow-md
          text-${Color}-700 bg-${Color}-200 hover:bg-${Color}-400 focus:ring-${Color}-500 focus:ring-offset-${Color}-200
          border-none rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}
      >
        {Status}
      </button>
    );
  };
  
  const columns = [
    { field: "id", headerName: "id", width: 70, hidden: true, hide: true },
    { field: "userName", headerName: "Customer Name", minWidth: 150, flex: 1 },
    {
      field: "userPhoneNumber",
      headerName: "Phone Number",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      minWidth: 150,
      flex: 1,
      renderCell: (cellvalues) => (
        <p>{Intl.NumberFormat()?.format(cellvalues?.row.totalPrice)}</p>
      ),
    },
    { field: "orderAddress", headerName: "Address", minWidth: 150, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 170,
      flex: 1,
      renderCell: (cellValues) => renderStatusButton(cellValues?.row?.status),
    },
    {
      field: "orderDate",
      headerName: "Date",
      minWidth: 200,
      flex: 1,
      renderCell: (cellvalues) => (
        <p>
          {cellvalues.row.orderDate.split("T")[0] +
            " At " +
            cellvalues.row.orderDate.split("T")[1]}
        </p>
      ),
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={(e) => handleEdit(cellvalues.row)}
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-600 focus:ring-offset-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
          <PencilIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          Edit
        </button>
      ),
    },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 130,
    //   renderCell: (cellvalues) => (
    //     <button
    //       onClick={(e) => handleDelete(cellvalues.id)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-rose-500 hover:bg-rose-600 focus:ring-rose-500 focus:ring-offset-rose-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <TrashIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Delete
    //     </button>
    //   ),
    // },
    {
      field: "View",
      headerName: "View",
      width: 130,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleView(cellvalues.id)}
          type="button"
          className="flex items-center justify-center float-right w-full px-3 py-2 text-center text-white transition duration-200 ease-in bg-orange-500 rounded-lg shadow-md hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <EyeIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          View
        </button>
      ),
    },
  ];
  const handleStatus = (params) => {
    console.log(params);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setEdit(true);
    dispatch(getOrders());
  };
  useEffect(() => {
    if (from !== "" && to !== "") {
      dispatch(filterDate([from, to]));
    }
  }, [from, to]);
  const handleDelete = (id) => {
    axios
      .delete(`api/Categories/${id}`)
      .then((r) => {
        // console.log(r);
        if (r.status === 200) {
          setToast(true);
          dispatch(getOrders());
        }
        if (r.status === 204) {
          setToast(true);
          dispatch(getOrders());
        }
      })
      .catch((e) => {
        console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
      });
  };

  // Filter Section Codes
  const search = useRef();
  // ------------------------------------ Filters ------------------------------------ //
  const handleSearch = (e) => {
    search.current.value = e;
    const now = new Date();

    dispatch(filteredItem(search.current.value));
  };
  const handleRemoveFilters = () => {
    dispatch(removeFilter());
    search.current.value = "";
    setFrom("");
    setTo("");
  };
  const now = new Date();

  const handleSelectedFilter = (f, t) => {
    setFrom(f);
    setTo(t);
  };
  const handleShowFilter = () => {
    setShowFilter(!showfilter);
  };

  return (
    <div className="ltr">
      <main className="relative h-screen bg-gray-100 dark:bg-gray-800">
        <div className="flex items-start justify-between h-screen overflow-y-auto">
          <Sidebar />
          <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
            <header className="z-10 flex items-center justify-between w-full h-16">
              {/* <div className="block ml-6 lg:hidden">
                <button className="flex items-center p-2 text-gray-500 bg-white rounded-full shadow text-md">
                  <svg
                    width="20"
                    height="20"
                    className="text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                  </svg>
                </button>
              </div> */}
              {/* Header */}
              <Header />
            </header>
            <div className="h-screen px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
              <div className="">
                <BreadCrumb
                  page={"Orders"}
                  icon={<ListBulletIcon className="inline w-6 mx-2" />}
                />
              </div>
              <InfoCard />
              <div className="p-4 h-[44rem] mb-7 rounded-md bg-gray-50 dark:bg-gray-800">
                {showfilter === false && (
                  <>
                    <button
                      onClick={() => handleShowFilter()}
                      type="button"
                      className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-700 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-700 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    >
                      <FunnelIcon
                        alt="add icon"
                        className="w-4 h-4 mr-2 text-white "
                      />
                      Filter
                    </button>
                  </>
                )}
                {showfilter === true && (
                  <>
                    <div className="mt-2 mb-5">
                      <div className="flex w-full">
                        <div className="w-full p-6 bg-white border border-gray-200 shadow-lg rounded-xl dark:bg-gray-800 dark:border-gray-600">
                          <div className="">
                            <div className="relative flex items-center justify-between w-full mb-5 rounded-md">
                              <svg
                                className="absolute block w-5 h-5 text-gray-400 left-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <circle
                                  cx="11"
                                  cy="11"
                                  r="8"
                                  className=""
                                ></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                  className=""
                                ></line>
                              </svg>
                              <input
                                type="name"
                                name="search"
                                ref={search}
                                onChange={(e) => handleSearch(e.target.value)}
                                className="w-full h-12 py-4 pl-12 pr-40 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-text focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:bg-gray-700 dark:text-gray-700 dark:border-gray-600"
                                placeholder="Search by Customer Name, Phone number, Address, Price, etc"
                              />
                            </div>

                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                              <div className="flex flex-col">
                                <label
                                  htmlFor="date"
                                  className="text-sm font-medium text-stone-600 dark:text-stone-300"
                                >
                                  From
                                </label>
                                <input
                                  type="date"
                                  id="date"
                                  value={from}
                                  onChange={(e) => setFrom(e.target.value)}
                                  className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                />
                              </div>
                              <div className="flex flex-col">
                                <label
                                  htmlFor="date"
                                  className="text-sm font-medium text-stone-600 dark:text-stone-300"
                                >
                                  To
                                </label>
                                <input
                                  type="date"
                                  id="date"
                                  value={to}
                                  onChange={(e) => setTo(e.target.value)}
                                  className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"
                                />
                              </div>

                              {/* <div className="flex flex-col">
                          <label
                            htmlFor="status"
                            className="text-sm font-medium text-stone-600"
                          >
                            Status
                          </label>

                          <select
                            id="status"
                            className="block w-full px-2 py-2 mt-2 bg-gray-100 border border-gray-100 rounded-md shadow-sm outline-none cursor-pointer focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          >
                            <option>Dispached Out</option>
                            <option>In Warehouse</option>
                            <option>Being Brought In</option>
                          </select>
                        </div> */}
                              <div className="right-0 grid float-right w-full grid-cols-2 mt-6 space-x-4 md:flex">
                                {/* <button className="px-8 py-2 font-medium text-white bg-blue-600 rounded-lg outline-none hover:opacity-80 focus:ring">
                          Search
                        </button> */}
                                <Menu
                                  as="div"
                                  className="relative inline-block h-full py-1 my-auto text-left align-middle "
                                >
                                  <div>
                                    <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus-ring-offset-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">
                                      Filters
                                      <ChevronDownIcon
                                        className="w-5 h-5 ml-2 -mr-1"
                                        aria-hidden="true"
                                      />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 dark:text-gray-300">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                handleSelectedFilter(
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth(),
                                                    now.getDate() - 1
                                                  )
                                                    .toISOString()
                                                    .split("T")[0],
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth(),
                                                    now.getDate()
                                                  )
                                                    .toISOString()
                                                    .split("T")[0]
                                                )
                                              }
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                                  : "text-gray-700 dark:text-gray-300",
                                                "block px-4 py-2 text-sm w-full text-left"
                                              )}
                                            >
                                              Last Day{" "}
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                handleSelectedFilter(
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth(),
                                                    now.getDate() - 7
                                                  )
                                                    .toISOString()
                                                    .split("T")[0],
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth(),
                                                    now.getDate()
                                                  )
                                                    .toISOString()
                                                    .split("T")[0]
                                                )
                                              }
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                                  : "text-gray-700 dark:text-gray-300",
                                                "block px-4 py-2 text-sm w-full text-left"
                                              )}
                                            >
                                              Last week{" "}
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                handleSelectedFilter(
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth() - 1,
                                                    now.getDate()
                                                  )
                                                    .toISOString()
                                                    .split("T")[0],
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth(),
                                                    now.getDate()
                                                  )
                                                    .toISOString()
                                                    .split("T")[0]
                                                )
                                              }
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                                  : "text-gray-700 dark:text-gray-300",
                                                "block px-4 py-2 text-sm w-full text-left"
                                              )}
                                            >
                                              Last month{" "}
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                handleSelectedFilter(
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth() - 3,
                                                    now.getDate()
                                                  )
                                                    .toISOString()
                                                    .split("T")[0],
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth(),
                                                    now.getDate()
                                                  )
                                                    .toISOString()
                                                    .split("T")[0]
                                                )
                                              }
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                                  : "text-gray-700 dark:text-gray-300",
                                                "block px-4 py-2 text-sm w-full text-left"
                                              )}
                                            >
                                              Last three month
                                            </button>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              onClick={() =>
                                                handleSelectedFilter(
                                                  new Date(
                                                    now.getFullYear() - 1,
                                                    now.getMonth(),
                                                    now.getDate()
                                                  )
                                                    .toISOString()
                                                    .split("T")[0],
                                                  new Date(
                                                    now.getFullYear(),
                                                    now.getMonth(),
                                                    now.getDate()
                                                  )
                                                    .toISOString()
                                                    .split("T")[0]
                                                )
                                              }
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                                  : "text-gray-700 dark:text-gray-300",
                                                "block px-4 py-2 text-sm w-full text-left"
                                              )}
                                            >
                                              Last three month
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                                <button
                                  className="px-8 py-2 font-medium text-gray-600 bg-gray-200 rounded-lg outline-none active:scale-95 focus:ring hover:opacity-90 dark:bg-gray-700 dark:text-gray-300"
                                  onClick={() => handleRemoveFilters()}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* <button
                  onClick={() => setIsHeadlessOpen(true)}
                  type="button"
                  className="flex items-center justify-center float-right px-4 py-2 text-center text-white transition duration-200 ease-in bg-teal-500 rounded-lg shadow-md w-fit hover:bg-teal-600 focus:ring-teal-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                >
                  <PlusCircleIcon
                    src={addIcon}
                    alt="add icon"
                    className="w-6 h-6 mr-2 text-white "
                  />
                  Add
                </button>{" "} */}
                {/* {isHeadlessOpen && (
                  <Add
                    Title={"Add News"}
                    open={isHeadlessOpen}
                    setOpen={setIsHeadlessOpen}
                  />
                )}
              */}{" "}
                {edit && (
                  <Edit
                    Title={"Edit News"}
                    open={edit}
                    id={editID}
                    setOpen={setEdit}
                  />
                )}{" "}
                {view && (
                  <View
                    Title={"View Order Detail"}
                    open={view}
                    setOpen={setView}
                    id={viewId}
                  />
                )}
                {loader && !isSuccess ? (
                  <Spiner /> // Show spinner when loader is true
                ) : data?.length === 0 ? (
                  <div className="flex items-center justify-center w-full h-full align-middle">
                    <span className="inline-flex items-center px-4 py-2 text-xl font-medium text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/10">
                      <ExclamationCircleIcon className="w-8 mx-auto" />
                      No data found!
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center w-auto h-full mx-2 mt-2 dark:bg-gray-800">
                    {/* Render the data grid when data is available */}
                    <DataGrid
                      rows={data}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      className="min-h-full dark:text-gray-200"
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      // checkboxSelection
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {toast && (
        <Toast
          Text={"News Deleted Successfully"}
          Type={"teal"}
          setOpen={setToast}
        />
      )}
      {error && (
        <Toast
          Text={"email or phone number is allready taken!"}
          Type={"rose"}
          setOpen={setError}
        />
      )}
    </div>
  );
};

export default Index;
