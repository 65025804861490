import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getClients = createAsyncThunk(
  "getClients",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get('dashboardToken')}`,
        },
      };
      const { data } = await axios.get("api/Users", config);
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    copyData: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    filterDate: (state, action) => {
      // console.log(action.payload[0]);
      // console.log(action.payload[1]);

      state.copyData = state.data.filter(
        (item) =>
          item?.createdAt > action.payload[0] &&
          item?.updatedAt < action.payload[1]
      );
      // console.log(state.copyData);
    },
    removeFilter: (state) => {
      state.copyData = state.data;
    },
  },
  extraReducers: {
    [getClients.pending]: (state, action) => {
      state.loading = true;
    },
    [getClients.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getClients.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});
export const { removeFilter, filterDate } = loremSlice.actions;
export default loremSlice;
