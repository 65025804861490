import React from "react";
import Navbar from "../componets/Navbar";
import ShopSideBar from "../componets/shop/ShopSideBar";
import Footer from "../componets/Footer";
import CopyWrite from "../componets/CopyWrite";
import ProductDetails from "../componets/shop/ProductDetail";
import Related from "../componets/shop/RelatedItemsCarousel";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const ProductDetail = () => {
  const l = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const params = useParams();

  return (
    <div className="w-full">
      {/* <Header /> */}
      <Navbar />
      {/* <!-- shop wrapper --> */}
      <div className="grid items-start gap-0 pt-4 pb-16 md:gap-6">
        {/* <!-- breadcrumb --> */}
        {/* <!-- ./breadcrumb --> */}
        {/* <!-- sidebar --> */}
        {/* <ShopSideBar /> */}
        <ProductDetails />
        {/* <!-- ./sidebar --> */}
        {/* <!-- products --> */}
        {/* <Products /> */}
        {/* <!-- ./products --> */}
      </div>
 
      {/* <!-- ./shop wrapper --> */}
      {/* <!-- footer --> */}
      <Footer />
      {/* <!-- ./footer --> */}
      {/* <!-- copyright --> */}
      <CopyWrite />
      {/* <!-- ./copyright --> */}
    </div>
  );
};

export default ProductDetail;
