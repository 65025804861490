import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  MapPinIcon,
  PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
// import Toast from "../../components/Cards/Toast;
import { getNews } from "../../../features/Global/News";
import { useDispatch, useSelector } from "react-redux";
import { TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAddresses } from "../../../features/Global/Address";
import { getCities } from "../../../features/Global/Cities";
import Cookies from "js-cookie";

export default function SlideOver({ id, open, setOpen, chilren }) {
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [cityId, setCityId] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const l = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const cities = useSelector((state) => state.Cities.data);

  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  useEffect(() => {
    dispatch(getCities());
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  // console.log("iddddddd", id);
  useEffect(() => {
    setValue("addressTitle", id.addressTitle);
    setValue("city", id.city?.id);
    // setCityId(id?.city?.id);
    setValue("streetName", id.streetName);
    setValue("phoneNumber", id.phoneNumber);
    setValue("buildingName", id.buildingName);
    setValue("buildingFloor", id.buildingFloor);
    setValue("appartmentNumber", id.appartmentNumber);
    setValue("detailedAdderess", id.detailedAdderess);
  }, [id]);
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get('webAuth')}`,
    },
  };
  const onSubmit = (data) => {
    // console.log(data);
    data.oldId = id?.id;

    axios
      .put("api/OrderAddresses", data, config)
      .then((r) => {
        if (r.status === 200 || r.status === 204) {
          setToast(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          console.log(r);
          dispatch(getAddresses());
        }
      })
      .catch((e) => {
        console.log(e.response.status);
        console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
      });
    setToast(false);
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="py-10 text-center text-white bg-gradient-to-br from-teal-600 to-teal-500">
                  <p className={`${l} text-2xl font-semibold`}>
                    <MapPinIcon className="inline mx-2 w-7 " />{" "}
                    {t("updateAddress")}
                  </p>
                </div>
                <form
                  className="px-8 py-10 space-y-4 "
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {/* <label className="block" htmlFor="name">
                    <input
                      type="text"
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.addressTitle &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                      placeholder={t("addressTitle")}
                      {...register("addressTitle", { required: true })}
                    />
                  </label> */}
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className={`block mb-2 text-sm text-gray-600 ${
                        l === "en-US" ? "" : "float-right text-right"
                      } ${l}`}
                    >
                      {t("addressTitle")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("addressTitle")}
                      {...register("addressTitle", { required: true })}
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.addressTitle &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                    />
                  </div>
                  {/* <label className="block" htmlFor="name">
                    <input
                      type="text"
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.city &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                      placeholder={t("city")}
                      {...register("city", { required: true })}
                    />
                  </label> */}
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className={`block mb-2 text-sm text-gray-600 ${
                        l === "en-US" ? "" : "float-right text-right"
                      } ${l}`}
                    >
                      {t("city")}
                    </label>
                    <select
                      // value={cityId}
                      type="text"
                      placeholder={t("city")}
                      {...register("city", { required: true })}
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.city &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                    >
                      {cities?.map((item) => (
                        <option
                          value={item?.id}
                          // onChange={() => setCityId(item?.id)}
                          // onClick={() => setCityId(item?.id)}
                          className={`${l === "en-US" ? "" : "ku"}`}
                        >
                          {l === "en-US" && item?.englishCity}
                          {l === "ar" && item?.arabicCity}
                          {l === "ku" && item?.kurdishCity}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <label className="block" htmlFor="name">
                    <input
                      type="text"
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.streetName &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                      placeholder={t("streetName")}
                      {...register("streetName", { required: true })}
                    />
                  </label> */}
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className={`block mb-2 text-sm text-gray-600 ${
                        l === "en-US" ? "" : "float-right text-right"
                      } ${l}`}
                    >
                      {t("streetName")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("streetName")}
                      {...register("streetName")}
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.streetName &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                    />
                  </div>
                  {/* <label className="block" htmlFor="name">
                    <input
                      type="text"
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.phoneNumber &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                      placeholder={t("city")}
                      {...register("city", { required: true })}
                    />
                  </label> */}
                  {/* <label className="block" htmlFor="name">
                    <input
                      type="text"
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.buildingName &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                      placeholder={t("buildingName")}
                      {...register("buildingName", { required: true })}
                    />
                  </label> */}
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className={`block mb-2 text-sm text-gray-600 ${
                        l === "en-US" ? "" : "float-right text-right"
                      } ${l}`}
                    >
                      {t("buildingName")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("buildingName")}
                      {...register("buildingName")}
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.buildingName &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                    />
                  </div>
                  {/* <label className="block" htmlFor="name">
                    <input
                      type="text"
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.buildingFloor &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                      placeholder={t("buildingFloor")}
                      {...register("buildingFloor", { required: true })}
                    />
                  </label> */}
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className={`block mb-2 text-sm text-gray-600 ${
                        l === "en-US" ? "" : "float-right text-right"
                      } ${l}`}
                    >
                      {t("buildingFloor")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("buildingFloor")}
                      {...register("buildingFloor")}
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.buildingFloor &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                    />
                  </div>
                  {/* <label className="block" htmlFor="name">
                    <input
                      type="text"
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.appartmentNumber &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                      placeholder={t("appartmentNumber")}
                      {...register("appartmentNumber", {
                        required: true,
                      })}
                    />
                  </label> */}
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className={`block mb-2 text-sm text-gray-600 ${
                        l === "en-US" ? "" : "float-right text-right"
                      } ${l}`}
                    >
                      {t("appartmentNumber")}
                    </label>
                    <input
                      type="text"
                      placeholder={t("appartmentNumber")}
                      {...register("appartmentNumber")}
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.appartmentNumber &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className={`block mb-2 text-sm text-gray-600 ${
                        l === "en-US" ? "" : "float-right text-right"
                      } ${l}`}
                    >
                      {t("note")}
                    </label>
                    <TextareaAutosize
                      type="text"
                      className={`w-full px-3 py-3 placeholder-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:ring-teal-100 focus:border-teal-600 ${
                        errors.detailedAdderess &&
                        "focus:ring-rose-100 focus:border-rose-300"
                      } ${l}`}
                      placeholder={t("detailedAdderess")}
                      {...register("detailedAdderess")}
                    />
                  </div>
                  {/* <label className="block" htmlFor="name">
                            <p className="text-gray-600">Email Address</p>
                            <input
                              className="w-full px-2 py-2 bg-white border rounded-md outline-none ring-blue-600 focus:ring-1"
                              type="email"
                              placeholder="Enter your email"
                            />
                          </label>
                          <label className="block" htmlFor="name">
                            <p className="text-gray-600">Request</p>
                            <textarea
                              className="w-full h-32 px-2 py-2 bg-white border rounded-md outline-none ring-blue-600 focus:ring-1"
                              type="text"
                              placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                            ></textarea>
                          </label> */}
                  <hr className="w-4/5 mx-auto text-gray-400" />
                  <button
                    type="submit"
                    className={`mt-4 rounded-md bg-gradient-to-br from-teal-600 to-teal-500 px-10 py-2 font-semibold text-white ${l}`}
                  >
                    <PencilSquareIcon className={`inline w-6 mx-1 ${l}`} />{" "}
                    {toast === false ? t("update") : <>{t("update")}</>}
                  </button>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
      {/* {toast && <Toast />} */}
    </Transition>
  );
}
