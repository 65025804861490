import React, { useEffect, useState } from "react";
import src from "../../assets/images/medicine.jpeg";
import { useParams, Link, BrowserRouter } from "react-router-dom";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../../features/Global/Items";
import { getAuth } from "../../../features/web/WebAuthentication";
import CheckAuth from "../CheckAuthModal/CheckAuth";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getBasket } from "../../../features/web/ShopingCart";
import ShopLoader from "../Loader/ShopLoader";
import loaderAnim from "../Loader/Loader.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { Navigate } from "react-router-dom";
import SnackBar from "./SnackBarDynamic";
import AddedSnackBar from "./AddedToBasketSnackBar";
import NotFoundAnim from "./Animation/NotFound.json";
import ReactPaginate from "react-paginate";

import usePagination from "../../componets/pagination/Pagination";
import { Pagination } from "@mui/material";
import { teal } from "@mui/material/colors";
import { GiftIcon, ReceiptPercentIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

const Products = () => {
  const locale = localStorage.getItem("i18nextLng");
  const params = useParams();
  // const category = useSelector((state) => state.filter.value) || params.id;
  // console.log('stateee',category, 'params', params.id);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.item.copyData);
  const loading = useSelector((state) => state.item.loading);
  const isSuccess = useSelector((state) => state.item.isSuccess);
  const loader = useSelector((state) => state.item.loading);
  const categoryLoader = useSelector((state) => state.category.loading);
  // const checkTool = useSelector((state) => state.WebAuthentication.getAuth);
  const [currentPage, setCurrentPage] = useState(0);
  const [snackBar, setSnackBar] = useState(false);
  const [AddedToBasket, setAddedToBasket] = useState(false);
  const [checkLoginModal, setcheckLoginModal] = useState(false);
  function handlePageClick({ selected: selectedPage }) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(selectedPage);
  }
  const isLogedIn = Cookies.get("webAuth")?.length > 0 ? true : false;
  // console.log("isloged in", isLogedIn);
  const color = teal[500];

  const PER_PAGE = 30;
  let [page, setPage] = useState(1);
  const count = Math.ceil(data?.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);

  // useEffect(() => {
  //   dispatch(getItem());
  //   // dispatch(getAuth());
  // }, []);

  const { t } = useTranslation();
  const checkLocale = () => {
    if (locale === "en-Us") {
      return "english";
    }
    if (locale === "ar") {
      return "arabic";
    }
    if (locale === "ku") {
      return "kurdish";
    }
  };
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("webAuth")}`,
    },
  };
  // Submit Order
  const handleSubmitOrder = (id) => {
    if (isLogedIn) {
      const data = {};
      data.productId = id;
      data.qty = 1;
      axios
        .post("api/BasketItems/AddAndRemoveFromBasket", data, config)
        .then((r) => {
          // console.log(r);
          setAddedToBasket(true);
          setTimeout(() => {
            setAddedToBasket(false);
          }, 4000);
          dispatch(getBasket());
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.status === 401) {
          }
          if (e.response.data === "not enough items in stock") {
            setSnackBar(true);
            setTimeout(() => {
              setSnackBar(false);
            }, 4000);
          }
        });
    } else {
      setcheckLoginModal(true);
    }
  };
  return (
    <div className="col-span-12 lg:col-span-3 ">
      {/* <div className="flex items-center mb-4">
        <select
          name="sort"
          id="sort"
          className="px-4 py-3 text-sm text-gray-600 border-gray-300 rounded shadow-sm w-44 focus:ring-primary focus:border-primary"
        >
          <option value="">Default sorting</option>
          <option value="price-low-to-high">Price low to high</option>
          <option value="price-high-to-low">Price high to low</option>
          <option value="latest">Latest product</option>
        </select>

        <div className="flex gap-2 ml-auto">
          <div className="flex items-center justify-center w-10 text-white border rounded cursor-pointer border-primary h-9 bg-primary">
            <i className="fa-solid fa-grip-vertical"></i>
          </div>
          <div className="flex items-center justify-center w-10 text-gray-600 border border-gray-300 rounded cursor-pointer h-9">
            <i className="fa-solid fa-list"></i>
          </div>
        </div>
      </div> */}
      {isSuccess &&
      _DATA.currentData().length < 1 &&
      loader === false &&
      categoryLoader === false ? (
        <>
          <span className="flex items-center justify-center min-w-full min-h-full mx-auto my-auto align-middle">
            <Player
              src={NotFoundAnim}
              autoplay
              loop
              className="items-center w-full mx-auto my-auto text-center align-middle h-96"
            />
            {/* <div className="flex flex-col ml-3"></div>{" "} */}
          </span>{" "}
        </>
      ) : (
        <></>
      )}
      <div className="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 ">
        {isSuccess &&
          _DATA
            .currentData()
            ?.filter((item) => item.isActive === true)
            .map((item, index) =>
              _DATA.currentData().length < 1 ? (
                <>
                  <p className="text-3-xl text-rose">no data found</p>
                </>
              ) : (
                <>
                  {loader ? (
                    <Player src={loaderAnim} autoplay loop className="player" />
                  ) : (
                    <>
                      <div
                        key={index}
                        className="duration-500 bg-white shadow-md whitespace-nowrap md:w-full hover:scale-105 hover:shadow-xl rounded-xl"
                      >
                        {/* <a className="relative flex mx-3 mt-3 overflow-hidden h-60 rounded-xl" href="#">
    <img className="object-cover" src="https://images.unsplash.com/photo-1600185365483-26d7a4cc7519?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c25lYWtlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="product image" />
    <span className="absolute top-0 left-0 px-2 m-2 text-sm font-medium text-center text-white bg-black rounded-full">39% OFF</span>
  </a> */}
                        <Link
                          to={`/shop/product/${item.id}`}
                          replace={true}
                          relative={true}
                          className="relative flex mt-3 overflow-hidden h-60"
                        >
                          {item.qty < 1 && (
                            <span
                              className={`absolute top-0 ${
                                locale === "en-US" ? "left-0" : "right-0"
                              } m-2 rounded-md bg-gradient-to-br from-rose-600 to-rose-500 px-2 text-center text-sm font-medium text-white ${locale}`}
                            >
                              {t("outOfStock")}
                            </span>
                          )}
                          <img
                            src={item?.imageUrl}
                            alt="Product image"
                            className="object-contain w-full rounded-t-xl"
                          />
                          {item.isOfferActive && (
                            <div
                              className={` absolute z-10 top-2 ${
                                item.offerType === 1
                                  ? "bg-rose-500 "
                                  : "bg-rose-500 "
                              } py-2 px-4 rounded-full text-white ${
                                locale === "en-US" ? "right-5" : "left-6"
                              }`}
                            >
                              <span>
                                {item.offerType === 1 && (
                                  <GiftIcon className="inline w-6 py-2 text-white" />
                                )}
                                {item.offerType === 2 && (
                                  <>
                                    <strong className="flex-col text-sm ku">
                                      {" "}
                                      {item.percentageOf}%
                                      <div className="ku"> OFF</div>
                                    </strong>
                                    {/* <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" /> */}
                                  </>
                                )}
                                {item.offerType === 3 && (
                                  <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" />
                                )}
                              </span>
                            </div>
                          )}
                        </Link>

                        <div
                          className="px-4 py-3 whitespace-nowrap"
                          key={index}
                        >
                          <span
                            className={`mr-3 text-xs text-gray-400 uppercase ${locale}`}
                          >
                            {locale === "en-US" && item.brand?.english}
                            {locale === "ar" && item.brand?.arabic}
                            {locale === "ku" && item.brand?.kurdish}{" "}
                          </span>
                          {/* {item.isOfferActive &&
                            item?.isApplicableForOffers && (
                              <span
                                className={` ${
                                  locale === "en-US"
                                    ? "float-right"
                                    : "float-left"
                                }`}
                              >
                                <p>
                                  {item.offerType === 1 && (
                                    <GiftIcon className="inline w-5 mx-1 text-green-500" />
                                  )}
                                  {item.offerType === 2 &&
                                    item?.isApplicableForOffers && (
                                      <>
                                        <strong className="text-md">
                                          {" "}
                                          {item.percentageOf}%
                                        </strong>
                                        <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" />
                                      </>
                                    )}
                                  {item.offerType === 3 && (
                                    <ReceiptPercentIcon className="inline w-5 mx-1 text-blue-500" />
                                  )}
                                </p>
                              </span>
                            )} */}
                          <p
                            className={`block text-md font-bold text-black capitalize truncate max-w-xs mx-1 md:mx-0 md:max-w-lg   ${locale}`}
                          >
                            {locale === "en-US" && item.name?.english}
                            {locale === "ar" && item.name?.arabic}
                            {locale === "ku" && item.name?.kurdish}{" "}
                          </p>
                          <div className="flex items-center">
                            <p
                              className={`my-3 text-md font-semibold text-black cursor-auto max-w-fit ${locale}`}
                            >
                              {item?.isOfferActive === false ? (
                                <>
                                  {Intl.NumberFormat().format(item.price)}{" "}
                                  <small className={`${locale}`}>
                                    {t("iqd")}{" "}
                                  </small>
                                </>
                              ) : (
                                <>
                                  {item?.offerType === 2 &&
                                  item?.isApplicableForOffers &&
                                  item?.isOfferActive ? (
                                    <>
                                      {Intl.NumberFormat().format(
                                        item.priceAfterDiscount
                                      )}{" "}
                                      <small className={`${locale}`}>
                                        {t("iqd")}{" "}
                                      </small>
                                      <span className="mx-2 text-sm font-light line-through">
                                        {Intl.NumberFormat().format(item.price)}{" "}
                                        <small className={`${locale} 	`}>
                                          {t("iqd")}{" "}
                                        </small>
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {Intl.NumberFormat().format(item.price)}{" "}
                                      <small className={`${locale}`}>
                                        {t("iqd")}{" "}
                                      </small>
                                    </>
                                  )}
                                </>
                              )}
                            </p>
                            {item.isOfferActive && (
                              <>
                                <del>
                                  <p
                                    className={`${
                                      locale === "en-US" ? "ml-2" : "mr-2"
                                    } text-sm text-gray-600 cursor-auto`}
                                  >
                                    {item.offerType === 3 ? (
                                      <> {item.percentageOf}%</>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </del>
                              </>
                            )}
                            <div
                              className={` ${
                                locale === "en-US" ? "ml-auto" : "mr-auto"
                              } `}
                            >
                              <button
                                onClick={(e) => handleSubmitOrder(item.id)}
                                disabled={item.qty < 1}
                                hidden={item.qty < 1}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-bag-plus"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"
                                  />
                                  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )
            )}
      </div>
      {checkLoginModal && (
        <CheckAuth open={checkLoginModal} setOpen={setcheckLoginModal} />
      )}
      {snackBar && <SnackBar />}
      {AddedToBasket && <AddedSnackBar />}
      <div className="flex justify-end w-full px-3 my-5">
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          color="success"
          className="text-teal-600 border-teal-400 "
          showFirstButton
          showLastButton
          dir={`${locale === "en-US" ? "ltr" : "rtl"}`}
        />
      </div>
    </div>
  );
};

export default Products;
