import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Index from "../web/pages/Index";
import Shop from "../web/pages/Shop";
import ErrorPage from "../web/componets/ErrorPage";
import Login from "../web/pages/Login";
import Register from "../web/pages/Register";
import Checkout from "../web/pages/Checkout";
import Cart from "../web/pages/Cart";
const router = createBrowserRouter([ 
    {
      path: "/",
      element: <Index />,
      errorElement: <ErrorPage />,

    },
    {
      path: "/shop",
      element: <Shop />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/cart",
      element: <Cart />,
    },
  ]);
  export default router