import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/Cards/BreadCrumb";
import Header from "../../components/Sidebar/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import orders from "../../icons/items.svg";
import addIcon from "../../icons/add.svg";
import InfoCard from "../../components/Cards/Carts";
import Image from "./Imgae";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
import Cookies from "js-cookie";
import Spiner from "../../components/Loader/Spiner";
import { Tooltip } from "@mui/material";

// Icons
import {
  CheckCircleIcon,
  EyeIcon,
  PlusCircleIcon,
  RectangleGroupIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";

// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../../features/Global/Dashboard/Items";
// Grid
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

const Index = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const [isHeadlessOpen, setIsHeadlessOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [view, setView] = useState(false);
  const [viewId, setViewId] = useState(false);
  const [upload, setUpload] = useState(false);
  const [uploadId, setUploadId] = useState();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.itemDashboard?.data);
  const loader = useSelector((state) => state.itemDashboard?.loading);
  const isSuccess = useSelector((state) => state.itemDashboard?.isSuccess);
  const [file, setFile] = useState();
  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
  };
  // Toast
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  // hook to fetch items
  useEffect(() => {
    dispatch(getItem());
  }, []);
  const [pageSize, setPageSize] = useState(10);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleCheckOfferTypes = (value) => {
    if (value === 0) {
      return "No Offer";
    }
    if (value === 1) {
      return "Buy one get one";
    }
    if (value === 2) {
      return "Percentage discount";
    }
    // else {
    //   return "Buy one get some percenteage of";
    // }
  };

  const columns = [
    { field: "id", headerName: "id", width: 70, hide: true },
    {
      field: "imageUrl",
      headerName: "Image",
      width: 70,
      renderCell: (cellvalues) => {
        return (
          <img
            onClick={() => {
              handleUload(cellvalues.row.id);
            }}
            className="w-10 h-10 rounded-full"
            src={cellvalues?.value}
          />
        );
      },
    },
    {
      field: "barcode",
      headerName: "Barcode",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} placement="top">
          <p>{params.value !== null ? params?.value : ""}</p>
        </Tooltip>
      ),
    },
    {
      field: "englishName",
      headerName: "name",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} placement="top">
          <p>{params.value}</p>
        </Tooltip>
      ),
    },
    {
      field: "price",
      headerName: "price",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <p className="mx-auto text-center">
          {Intl?.NumberFormat().format(params?.value)} IQD
        </p>
      ),
    },
    {
      field: "qty",
      headerName: "Quantity",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <p className="mx-auto text-center">{params?.value}</p>
      ),
      // renderCell: (params) => <p>{params.value?.english}</p>,
    },
    {
      field: "englishBrand",
      headerName: "Brand",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} placement="top">
          <p>{params.value}</p>
        </Tooltip>
      ),
    },
    {
      field: "englishCategory",
      headerName: "Category",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} placement="top">
          <p>{params.value}</p>
        </Tooltip>
      ),
    },
    {
      field: "englishSubcategory",
      headerName: "Sub Category",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value} placement="top">
          <p>{params.value}</p>
        </Tooltip>
      ),
    },
    {
      field: "index",
      headerName: "Index",
      minWidth: 75,
      flex: 1,
      renderCell: (params) => (
        <p className="mx-auto text-center">{params?.value}</p>
      ),
    },
    {
      field: "isActive",
      headerName: "Product Status",
      minWidth: 120,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleDelete(cellvalues?.id)}
          className={`text-center inline-flex items-center justify-center px-3 py-1 mx-auto text-sm font-medium shadow-md ${
            cellvalues.row.isActive === true
              ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400-600 focus:ring-emerald-500 focus:ring-offset-emerald-200"
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          } border-none rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          <Tooltip
            title={cellvalues.row.isActive === true ? "Active" : "In Active"}
            placement="top"
          >
            {cellvalues.row.isActive === true ? (
              <CheckCircleIcon className="w-5 h-5" />
            ) : (
              <XCircleIcon className="w-5 h-5" />
            )}
          </Tooltip>
        </button>
      ),
    },
    {
      field: "offerType",
      headerName: "Offer Type",
      minWidth: 220,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          className={`text-center inline-flex items-center justify-center px-3 py-1 mx-auto text-sm font-medium shadow-md ${
            cellvalues?.row?.offerType !== 0
              ? "text-blue-700 bg-blue-200 hover:bg-emerald-400-600 focus:ring-blue-500 focus:ring-offset-blue-200 f "
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {handleCheckOfferTypes(cellvalues.row.offerType)}
        </button>
      ),
    },
    {
      field: "isOfferActive",
      headerName: "Offer Status",
      minWidth: 150,
      flex: 1,
      renderCell: (cellvalues) => (
        <button
          className={`text-center inline-flex items-center justify-center px-2 py-1 mx-auto text-sm font-medium shadow-md ${
            cellvalues.row.isOfferActive === true
              ? "text-emerald-700 bg-emerald-200 hover:bg-emerald-400-600 focus:ring-emerald-500 focus:ring-offset-emerald-200 f "
              : "text-rose-700 bg-rose-200 hover:bg-rose-400-600 focus:ring-rose-500 focus:ring-offset-rose-200"
          }  border-none rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {cellvalues?.row?.isOfferActive === true ? (
            <>
              <CheckCircleIcon className="inline w-5 mx-1" /> Active
            </>
          ) : (
            <>
              <XCircleIcon className="inline w-5 mx-1" /> In Active
            </>
          )}
        </button>
      ),
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 100,
      renderCell: (cellvalues) => (
        <button
          onClick={() => handleEdit(cellvalues?.id)}
          type="button"
          className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in bg-blue-700 rounded-lg shadow-md hover:bg-blue-700 focus:ring-blue-700 focus:ring-offset-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <PencilIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          Edit
        </button>
      ),
    },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 130,
    //   renderCell: (cellvalues) => (
    //     <button
    //       onClick={(e) => handleDelete(cellvalues.id)}
    //       type="button"
    //       className="flex items-center justify-center float-right w-full py-2 text-sm text-center text-white transition duration-200 ease-in rounded-lg shadow-md bg-rose-500 hover:bg-rose-600 focus:ring-rose-700 focus:ring-offset-rose-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
    //     >
    //       <TrashIcon
    //         src={addIcon}
    //         alt="add icon"
    //         className="w-4 h-4 mr-2 text-white "
    //       />
    //       Delete
    //     </button>
    //   ),
    // },
    {
      field: "View",
      headerName: "View",
      width: 100,
      renderCell: (cellvalues) => (
        <button
          // onClick={() => setView(true)}
          onClick={(e) => handleView(cellvalues.id)}
          type="button"
          className="flex items-center justify-center float-right w-full px-3 py-2 text-center text-white transition duration-200 ease-in bg-orange-500 rounded-lg shadow-md hover:bg-orange-600 focus:ring-orange-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          <EyeIcon
            src={addIcon}
            alt="add icon"
            className="w-4 h-4 mr-2 text-white "
          />
          View
        </button>
      ),
    },
  ];
  const handleView = (id) => {
    setViewId(id);
    setView(true);
  };
  const handleUload = (id) => {
    setUploadId(id);
    setUpload(true);
    dispatch(getItem());
  };
  const handleEdit = (id) => {
    setEdit(true);
    setEditId(id);
  };
  const handleDelete = (id) => {
    axios
      .delete(`api/Products/${id}`, config)
      .then((r) => {
        // console.log(r);
        if (r.status === 200 || r.status === 204) {
          setMessage("Product status updated successfully");
          setToast(true);
          dispatch(getItem());
        }
        if (r.status === 204) {
          setToast(true);
          dispatch(getItem());
        }
      })
      .catch((e) => {
        // console.log(e.response.data);
        setMessage(e.response.data);
        setError(true);
      });
  };
  return (
    <main className="relative h-screen overflow-y-auto bg-gray-100 dark:bg-gray-800 ltr">
      <div className="flex items-start justify-between h-screen overflow-y-auto">
        <Sidebar />
        <div className="flex flex-col w-full h-screen overflow-y-auto md:space-y-4">
          <header className="z-10 flex items-center justify-between w-full h-16">
            {/* <div className="block ml-6 lg:hidden">
                <button className="flex items-center p-2 text-gray-500 bg-white rounded-full shadow text-md">
                  <svg
                    width="20"
                    height="20"
                    className="text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                  </svg>
                </button>
              </div> */}
            {/* Header */}
            <Header />
          </header>
          <div className="h-screen px-4 pb-24 overflow-auto md:px-6 dark:bg-gray-900">
            <div className="">
              <BreadCrumb
                page={"Items"}
                icon={<RectangleGroupIcon className="inline w-6 mx-2" />}
              />
            </div>
            <InfoCard />
            <div className="my-4  h-[44rem] rounded-md bg-gray-50 dark:bg-gray-800">
              <button
                onClick={() => setIsHeadlessOpen(true)}
                type="button"
                className="flex items-center justify-center float-right w-full px-4 py-2 text-center text-white transition duration-200 ease-in bg-teal-500 rounded-lg shadow-md w-fit hover:bg-teal-600 focus:ring-teal-500 focus:ring-offset-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
              >
                <PlusCircleIcon
                  src={addIcon}
                  alt="add icon"
                  className="w-6 h-6 mr-2 text-white "
                />
                Add
              </button>{" "}
              {isHeadlessOpen && (
                <Add
                  Title={"Add Product"}
                  open={isHeadlessOpen}
                  setOpen={setIsHeadlessOpen}
                />
              )}
              {edit && (
                <Edit
                  Title={"Edit Product"}
                  open={edit}
                  id={editId}
                  setOpen={setEdit}
                />
              )}{" "}
              {upload && (
                <Image
                  id={uploadId}
                  Title={"Upload Image"}
                  open={upload}
                  // id={editID}
                  setOpen={setUpload}
                />
              )}{" "}
              {view && (
                <View
                  Title={"View Product"}
                  open={view}
                  setOpen={setView}
                  id={viewId}
                />
              )}
              <br />
              <br />
              {/* {loader && <Spiner />} */}
              {loader && !isSuccess &&data?.length < 1 ? (
                <>
                  <Spiner />
                </>
              ) : (
                <div className="flex items-center w-auto h-full mt-2 dark:bg-gray-800">
                  <DataGrid
                    rows={data}
                    columns={columns}
                    className="dark:bg-gray-800 dark:text-gray-200"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {toast && <Toast Text={message} Type={"teal"} setOpen={setToast} />}
      {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}
    </main>
  );
};

export default Index;
