import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getBanner = createAsyncThunk(
  "getBanner",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("webAuth")}`,
        },
      };
      const { data } = await axios.get("api/Banners");
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);
export const getwebBanner = createAsyncThunk(
  "getwebBanner",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get("webAuth")}`,
        },
      };
      const { data } = await axios.get("api/Banners/GetActiveBanner");
      // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
    webData: {},
    webLoading: false,
    webIsSuccess: false
  },
  reducers: {},
  extraReducers: {
    [getBanner.pending]: (state, action) => {
      state.loading = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getBanner.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
    [getwebBanner.pending]: (state, action) => {
      state.webLoading = true;
    },
    [getwebBanner.fulfilled]: (state, { payload }) => {
      state.webLoading = false;
      state.webData = payload;
      state.webIsSuccess = true;
    },
    [getwebBanner.rejected]: (state, { payload }) => {
      state.webLoading = false;
      state.webIsSuccess = false;
      state.message = "failed";
    },
  },
});

export default loremSlice;
