import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import {
  BellAlertIcon,
  CheckCircleIcon,
  PlusIcon,
  ReceiptPercentIcon,
  RectangleStackIcon,
  SwatchIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getBrand } from "../../../features/Global/Dashboard/Brands";
import { getOffersPercentage } from "../../../features/Global/OffersPercentage";
import { TextareaAutosize } from "@mui/material";

export default function SlideOver({ id, open, setOpen }) {
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const product = useSelector((state) => state.BrandsDashboard.data);
  const [disable, setDisable] = useState(false);
  const [notifiactionStatus, setNotificationStatus] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();

  const handleFileSelect = (e) => {
    if (e.target.files[0].type.startsWith("image/")) {
      setFile(e.target.files[0]);
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(objectUrl);
    } else {
      setMessage("Please select an image!");
      setError(true);
    }
  };
  const onSubmit = (data) => {
    // console.log(data);
    setDisable(true);
    data.productId = id;
    axios
      .post("api/Offers/deletePercentageForBrands", data, config)
      .then((r) => {
        setDisable(false);
        if (r.status === 200) {
          setMessage("Discount Removed On Brand Successfully.");
          setToast(true);
          dispatch(getBrand());
          dispatch(getOffersPercentage());
          if (notifiactionStatus) {
            setDisable(true);
            const formData = new FormData();
            formData.append("EnglishTitle", data.englishTitle);
            formData.append("ArabicTitle", data.arabicTitle);
            formData.append("KurdishTitle", data.kurdishTitle);
            formData.append("EnglishBody", data.englishBody);
            formData.append("ArabicBody", data.arabicBody);
            formData.append("KurdishBody", data.kurdishBody);
            formData.append("File", file);

            axios
              .post("api/Notification", formData, config)
              .then((r) => {
                setDisable(false);
                if (r.status === 200) {
                  setMessage("Notification Sent Successfully.");
                  setToast(true);
                }
              })
              .catch((e) => {
                setDisable(false);
                // console.log(e.response.status);
                // console.log(e.response.data);
                setMessage(e.response.data);
                setError(true);
              });
          }
        }
      })
      .catch((e) => {
        setDisable(false);
        setMessage(e.response.data);
        setError(true);
      });
  };
  useEffect(() => {
    dispatch(getBrand());
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {/* <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <ReceiptPercentIcon className="inline w-6 bg-teal-500 animate-pulse" />{" "}
                  Add Or Update product Discount Offers
                </Dialog.Title> */}
                <div className="py-10 text-center text-white bg-gradient-to-br from-red-600 to-red-500">
                  <p className={` text-2xl font-semibold`}>
                    <XCircleIcon className="inline mx-2 w-7 " /> Remove Brands
                    Discount Offers
                  </p>
                </div>
                <div className="mx-3 mt-2">
                  <form className="px-1 py-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-5">
                      <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                        Brand:
                      </label>
                      <select
                        {...register("brandId", {
                          required: true,
                        })}
                        placeholder="Select sub brand"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                          errors.brandId && "border-rose-500"
                        } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                      >
                        {product?.map((item) => (
                          <option
                            value={item.id}
                            className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                              errors.brandId && "border-rose-500"
                            } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                          >
                            {item.name.english}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex">
                      <div className="flex items-center h-5">
                        <input
                          id="helper-checkbox"
                          aria-describedby="helper-checkbox-text"
                          type="checkbox"
                          value={notifiactionStatus}
                          onChange={(e) =>
                            setNotificationStatus(!notifiactionStatus)
                          }
                          className="w-4 h-4 bg-teal-600 active:bg-teal-500 border-secondary-300 rounded focus:ring-secondary-500 dark:focus:ring-secondary-600 dark:ring-offset-secondary-500 focus:ring-2 <dark:bg-teal-2></dark:bg-teal-2>00 dark:border-gray-600"
                        />
                      </div>
                      <div className="ml-2 text-sm">
                        <label
                          for="helper-checkbox"
                          className="font-medium text-gray-900 dark:text-gray-800"
                        >
                          Send Notification
                        </label>
                        <p
                          id="helper-checkbox-text"
                          className="text-xs font-normal text-gray-500 dark:text-gray-800"
                        >
                          Notifiy users about this discount.
                        </p>
                      </div>
                    </div>

                    {notifiactionStatus && (
                      <>
                        <motion.div
                          className="px-1 py-3"
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5 }}
                        >
                          <span className="flex p-2 m-2 mx-auto text-xl text-center text-white rounded-lg bg-gradient-to-br from-red-500 to-red-600">
                            <BellAlertIcon className="inline w-6 mx-1" />{" "}
                            Notification
                          </span>
                          <div className="mb-8">
                            <input
                              type="file"
                              name="file"
                              id="file"
                              className="sr-only"
                              onChange={(e) => handleFileSelect(e)}
                            />
                            <span>{errors.File}</span>
                            <label
                              htmlFor="file"
                              className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                            >
                              <div>
                                <span className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-300">
                                  {preview && (
                                    <img
                                      src={preview}
                                      alt="image"
                                      className="mx-auto rounded-lg "
                                    />
                                  )}
                                </span>
                                <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D] dark:text-gray-300">
                                  Browse
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              English Title:
                            </label>
                            <input
                              type="text"
                              placeholder="title english"
                              {...register("englishTitle", {
                                required: true,
                                pattern: /[A-Za-z]{1}/,
                              })}
                              defaultValue="New Discount"
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.englishTitle && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              Arabic Title:
                            </label>
                            <input
                              type="text"
                              placeholder="title arabic"
                              {...register("arabicTitle", {
                                required: true,
                                pattern: /[\u0600-\u06FF]{1}/,
                              })}
                              defaultValue="خصم جديد"
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.arabicTitle && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>{" "}
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              Kurdish Title:
                            </label>
                            <input
                              type="text"
                              {...register("kurdishTitle", {
                                required: true,
                                pattern: /[\u0600-\u06FF]{1}/,
                              })}
                              defaultValue="داشکاندنی نوێ"
                              placeholder="title kurdish"
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.kurdishTitle && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              English Message:
                            </label>
                            <TextareaAutosize
                              type="text"
                              placeholder="message english"
                              {...register("englishBody", {
                                required: true,
                                pattern: /[A-Za-z]{1}/,
                              })}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.englishBody && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              Arabic Message:
                            </label>
                            <TextareaAutosize
                              type="text"
                              placeholder="message arabic"
                              {...register("arabicBody", {
                                required: true,
                                pattern: /[\u0600-\u06FF]{1}/,
                              })}
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.arabicBody && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>{" "}
                          <div className="mb-5">
                            <label className="block mb-2 text-base font-medium text-gray-700 dark:text-gray-800">
                              Kurdish Message:
                            </label>
                            <TextareaAutosize
                              type="text"
                              {...register("kurdishBody", {
                                required: true,
                                pattern: /[\u0600-\u06FF]{1}/,
                              })}
                              placeholder="message kurdish"
                              className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white border ${
                                errors.kurdishBody && "border-rose-500"
                              } bg-white py-2  text-base font-medium text-gray-700 outline-none focus:border-secondary-600 focus:shadow-md dark:bg-gray-100 dark:text-gray-800`}
                            />
                          </div>
                        </motion.div>
                      </>
                    )}
                    <div className="mt-4">
                      <button
                        disabled={disable}
                        type="submit"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      >
                        <CheckCircleIcon className="inline w-6 mx-2 " /> Submit
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          {toast && (
            <Toast
              Text={message}
              Type={"teal"}
              setOpen={setToast}
              className="z-50"
            />
          )}
          {error && <Toast Text={message} Type={"rose"} setOpen={setError} />}{" "}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
