import { ShoppingBagIcon, SwatchIcon, TagIcon, UsersIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStats } from "../../../features/Global/Stats";
import CountUp from "react-countup";

const MainCard = () => {
  const data = useSelector((state) => state.stats.data);
  const dispatch = useDispatch();
  useMemo(() => {
    dispatch(getStats());
  }, []);

  return (
    <div className="m-3 grid gap-5 sm:grid-cols-4  mx-auto w-full ">
      <div className="px-4 py-6 shadow-lg dark:shadow-md shadow-blue-100  bg-gradient-to-br from-blue-50 to-blue-100 border border-gray-100 rounded-lg hover:scale-105 duration-500 hover:bg-gradient-to-bl">
        <SwatchIcon className="h-14 w-14 rounded-xl bg-blue-400 p-4 text-white" />
        <p className="mt-4 font-medium text-blue-600">Brands</p>
        <p className="mt-2 text-xl font-medium">
          <CountUp end={data?.brands} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </p>
        {/* <span className="text-xs text-gray-400">+4.9%</span> */}
      </div>
      <div className="px-4 py-6 shadow-lg dark:shadow-md shadow-blue-100 bg-gradient-to-br from-teal-50 to-teal-100 border border-gray-100 rounded-lg hover:scale-105 duration-500 hover:bg-gradient-to-bl">
        <ShoppingBagIcon className="h-14 w-14 rounded-xl bg-teal-400 p-4 text-white" />

        <p className="mt-4 font-medium text-teal-600">Products</p>
        <p className="mt-2 text-xl font-medium">
          <CountUp end={data?.products} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </p>
        {/* <span className="text-xs text-gray-400">+4.9%</span> */}
      </div>
      <div className="px-4 py-6 shadow-lg dark:shadow-md shadow-blue-100 bg-gradient-to-br from-rose-50 to-rose-100 border border-gray-100 rounded-lg hover:scale-105 duration-500 hover:bg-gradient-to-bl">
        <TagIcon className="h-14 w-14 rounded-xl bg-rose-400 p-4 text-white" />
        <p className="mt-4 font-medium text-rose-600">Categories</p>
        <p className="mt-2 text-xl font-medium">
          <CountUp end={data?.category} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </p>
        {/* <span className="text-xs text-gray-400">+4.9%</span> */}
      </div>
      <div className="px-4 py-6 shadow-lg dark:shadow-md shadow-blue-100 bg-gradient-to-br from-indigo-50 to-indigo-100 border border-gray-100 rounded-lg hover:scale-105 duration-500 hover:bg-gradient-to-bl">
        <UsersIcon className="h-14 w-14 rounded-xl bg-indigo-400 p-4 text-white" />
        <p className="mt-4 font-medium text-indigo-600">Users</p>
        <p c="mt-2 text-xl font-medium">
          <CountUp end={data?.users} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </p>
        {/* <span className="text-xs text-gray-400">+4.9%</span> */}
      </div>
    </div>
  );
};

export default MainCard;
