import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const getOrders = createAsyncThunk(
  "getOrders",
  async (object, { getState, rejectWithValue }) => {
    // console.log(getState());
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Cookies.get('dashboardToken')}`,
        },
      };
      const { data } = await axios.get(
        "api/Orders/GetControlPanelOrders", config
        );
        // console.log(data);
      return data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const loremSlice = createSlice({
  name: "lorem",
  initialState: {
    data: [],
    copyData: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
 reducers: {
  filteredItem: (state, action) => {
    const searchQuery = action.payload?.toLowerCase();

    state.copyData = state.data.filter((item) => {
      const { userName, subCategory, userPhoneNumber, orderAddress, totalPrice, status, qty } = item;

      return (
        (userName && userName.toLowerCase().includes(searchQuery)) ||
        (subCategory && subCategory.toLowerCase().includes(searchQuery)) ||
        (userPhoneNumber && userPhoneNumber.toLowerCase().includes(searchQuery)) ||
        (orderAddress && orderAddress.toLowerCase().includes(searchQuery)) ||
        totalPrice === searchQuery ||
        status === searchQuery ||
        qty === searchQuery
      );
    });
  },
  filterDate: (state, action) => {
    const [startDate, endDate] = action.payload;

    state.copyData = state.data.filter((item) => {
      const orderDate = item?.orderDate;

      return orderDate > startDate && orderDate < endDate;
    });
  },
  removeFilter: (state) => {
    state.copyData = state.data;
  },
},

  extraReducers: {
    [getOrders.pending]: (state, action) => {
      state.loading = true;
    },
    [getOrders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.copyData = payload;
      state.isSuccess = true;
    },
    [getOrders.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "failed";
    },
  },
});
export const { filteredItem, removeFilter, filterDate } = loremSlice.actions;

export default loremSlice;