import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  EyeIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import Toast from "../../components/Cards/Toast";
// redux mapping
import { useDispatch, useSelector } from "react-redux";
import { getSubCategory } from "../../../features/Global/SubCategory";
import { getBrand } from "../../../features/Global/Brands";
import { getItem } from "../../../features/Global/Items";
import Parser from "html-react-parser";
import { TextareaAutosize } from "@mui/material";
import Cookies from "js-cookie";

export default function SlideOver({ Title, open, setOpen, id }) {
  const [englishTags, setEnglishTags] = useState("");
  const [arabicTags, setArabicTags] = useState("");
  const [kurdishTags, setKurdishTags] = useState("");
  const [data, setData] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const config = {
    headers: {
      Authorization: `Bearer ${Cookies.get("dashboardToken")}`,
    },
  };
  useEffect(() => {
    axios.get(`api/Products/${id}`, config).then((r) => {
      setData(r.data);
      const myArray = r?.data?.tags?.split("|");
      setEnglishTags(myArray[0]);
      setArabicTags(myArray[1]);
      setKurdishTags(myArray[2]);
    });
  }, [id]);

  const handleCheckOfferTypes = (value) => {
    if (value === 0) {
      return "No Offer";
    }
    if (value === 1) {
      return "Buy one get one";
    }
    if (value === 2) {
      return "Percentage discount";
    }
    // else {
    //   return "Buy one get some percenteage of";
    // }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen max-w-md pointer-events-auto">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="w-6 h-6 my-3 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-800">
                    <div className="inline px-4 py-6 -mt-6 bg-orange-600 rounded-sm sm:px-6 dark:bg-orange-600">
                      <Dialog.Title className="pt-4 -mt-4 text-xl font-light text-white border border-transparent rounded-md ">
                        <EyeIcon className="inline w-6 h-6" /> {Title}
                      </Dialog.Title>
                    </div>
                    <div className="relative flex-1">
                      {/* Replace with your content */}
                      <div className="absolute inset-0">
                        {/* <div className="h-full border-2 border-gray-200 border-dashed" aria-hidden="true" > */}
                        {data.imageUrl && (
                          <img
                            src={data.imageUrl[0]}
                            className="h-56 mx-auto w-fit"
                          />
                        )}

                        <div className="p-3 m-3">
                          <div className="flex flex-row py-1">
                            <div className="flex-1">
                              <p className="text-xl font-bold dark:text-gray-50 ">
                                {data?.name?.english}
                              </p>
                              <p className="text-sm font-medium text-gray-500 dark:text-gray-200">
                                {data?.category?.english}{" "}
                                <ChevronRightIcon className="inline w-4 font-bold text-gray-400" />{" "}
                                {data?.subCategory?.english}
                              </p>
                            </div>
                            <div className="mt-auto">
                              <span className="dark:text-gray-200">
                                {" "}
                                {data?.offerType === 2 ? (
                                  <>{data?.priceAfterDiscount}</>
                                ) : (
                                  <>{Intl.NumberFormat().format(data?.price)}</>
                                )}{" "}
                                IQD{" "}
                              </span>
                            </div>
                          </div>
                          {/* <div className="flex flex-row my-3">
                            <button className="border border-indigo-600 bg-indigo-600 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-indigo-700 focus:outline-none focus:shadow-outline w-1/2">
                              Details
                            </button>
                            <button className="border border-gray-300 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline w-1/2">
                              Details
                            </button>
                          </div> */}

                          <div className="border-b-[1.3px] py-3 border-gray-200">
                            <div className="flex flex-row justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  className="inline w-8"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                                </svg>
                                Barcode
                              </p>
                              <p className="font-semibold text-gray-900 dark:text-gray-200">
                                {data?.barcode}
                              </p>
                            </div>
                          </div>
                          <div className="border-b-[1.3px] py-3 border-gray-200">
                            <div className="flex flex-row justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                Brand
                              </p>
                              <p className="font-semibold text-gray-900 dark:text-gray-200">
                                {data?.brand?.english}
                              </p>
                            </div>
                          </div>
                          <div className="border-b-[1.3px] py-3 border-gray-200">
                            <div className="flex flex-row justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-50">
                                Available quantity
                              </p>
                              <p className="font-semibold text-gray-900 dark:text-gray-200">
                                {data?.qty}
                              </p>
                            </div>
                          </div>
                          <div className="border-b-[1.3px] py-3 border-gray-200">
                            <div className="flex flex-row justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                Is applicable for offers
                              </p>
                              <p className="items-center px-3 py-1 text-gray-900 bg-blue-100 border border-blue-600 rounded-md ">
                                <span className="items-center mx-auto font-medium text-blue-600">
                                  {data?.isApplicableForOffers === true
                                    ? "Yes"
                                    : "No"}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="border-b-[1.3px] py-3 border-gray-200">
                            <div className="flex flex-row justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                Offer type
                              </p>
                              <p className="items-center px-3 py-1 text-gray-900 bg-teal-100 border border-teal-600 rounded-md ">
                                <span className="items-center mx-auto font-medium text-teal-600">
                                  {handleCheckOfferTypes(data?.offerType)}
                                </span>
                              </p>
                            </div>
                          </div>
                          {data?.offerType === 2 || data?.offerType === 3 ? (
                            <>
                              <div className="border-b-[1.3px] py-3 border-gray-200">
                                <div className="flex flex-row justify-between my-2">
                                  <p className="font-semibold text-gray-400 dark:text-gray-200">
                                    Discount
                                  </p>
                                  <p className="items-center px-3 py-1 text-teal-600 bg-teal-100 border border-teal-600 rounded-md">
                                    %{data?.percentageOf}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {data?.offerType === 1 ? (
                            <>
                              <div className="border-b-[1.3px] py-3 border-gray-200">
                                <div className="flex flex-row justify-between my-2">
                                  <p className="font-semibold text-gray-400 dark:text-gray-200">
                                    Free Items
                                  </p>
                                  {/* <p className="items-center px-3 py-1 text-gray-900 bg-teal-100 border border-teal-600 rounded-md "> */}
                                  {data?.buyOneGetThese?.map((item) => {
                                    <div className="flex-row">
                                      <span className="mx-1 dark:text-gray-200">
                                        {item?.getThisProductId}
                                      </span>
                                    </div>;
                                  })}
                                  {/* </p> */}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                Description
                              </p>
                              <p className="text-sm text-gray-900 dark:text-gray-200">
                                {data?.description?.english.length > 0 && (
                                  <>{Parser(data?.description?.english)}</>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                Usage
                              </p>
                              <p className="text-sm text-gray-900 dark:text-gray-200">
                                {data?.usage?.english.length > 0 && (
                                  <>{Parser(data?.usage?.english)}</>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                English tags
                              </p>
                              <p className="text-sm font-semibold text-gray-900 dark:text-gray-200">
                                {englishTags}
                              </p>
                            </div>
                          </div>
                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                Arabic tags
                              </p>
                              <p className="text-sm font-semibold text-gray-900 dark:text-gray-200">
                                {arabicTags}
                              </p>
                            </div>
                          </div>
                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                Kurdish tags
                              </p>
                              <p className="text-sm font-semibold text-gray-900 dark:text-gray-200">
                                {kurdishTags}
                              </p>
                            </div>
                          </div>
                          <div className="py-3 border-gray-200 ">
                            <div className="flex flex-col justify-between my-2">
                              <p className="font-semibold text-gray-400 dark:text-gray-200">
                                English voice
                              </p>
                              <audio
                                className="mt-2"
                                controls
                                src={data?.englishVoice}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
